// @mui
import { styled } from '@mui/material/styles';
import { Container, Box } from '@mui/material';
// hooks
// components
// sections
import ResetPassword from 'src/sections/auth/login/ResetPassword';
import ConfirmPassword from 'src/sections/auth/login/ConfirmPassword';
import sidebarlogo from '../../src/components/logo/sidebarlogo/imagesidebar.png'
import useResponsive from '../hooks/useResponsive';
import { LoginForm } from '../sections/auth/login';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Password() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Box>
              <img style={{ paddingInline: 0, width: "50%", height: "50%", margin: "auto", marginBottom: "50px" }} src={sidebarlogo} alt="" />
            </Box>
            <ConfirmPassword />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
