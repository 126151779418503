import React, { useCallback, useContext, useEffect } from 'react'
import { Box, Button, Stack, Typography, Container, Card, Tabs, Tab, Grid, Divider, Table, TableHead, TableCell, TableBody, TableRow, TableContainer, Paper, Backdrop, CircularProgress } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pdf from 'react-to-pdf';
import { API_ROUTES } from 'src/services/constant';
import { FetchContext } from 'src/context/FetchContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function createData(_id, number, name, date, manager, status) {
  return { _id, number, name, date, manager, status };
}
const rows = [
  createData('63778b4745b4a686c7e0443c', '001248', 'Test task - 1', '12/10/2020', 'Manager 1', 'Job Setup'),
  createData('63778b4745b4a686c7e0443d', '001249', 'Test task - 2', '15/10/2020', 'Manager 1', 'Job Start')
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const { PRIVATE_ROUTE } = API_ROUTES;
const ManagerJobView = () => {

  const ref = React.createRef();
  const navigate = useNavigate();
  const { authAxios } = useContext(FetchContext);

  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [jobData, setjobData] = React.useState();
  const [loading, setLoading] = React.useState(true);



  const jobviewdata = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_JOB_BY_ID}/${id}`
    );
    setjobData(data?.data[0]);
    setLoading(false);
  }, [authAxios, id])

  useEffect(() => {
    jobviewdata();
  }, [])

  const handleDelete = async () => {
    await authAxios.post(`${PRIVATE_ROUTE?.DELETE_CLIENT}${jobData._id}`);
    navigate('/manager/job/list')
  }

  const handleEdit = () => {
    navigate(`/manager/job/update/${jobData._id}`);
  }


  const handleChangeone = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" component="h2">
          Manager Job View
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <Grid container>
            <Grid md={6} xs={12} item>
              <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {/* <Grid xs={12} md={3} item mt={2} sx={{ maxWidth: 'auto' }}>
                  <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => handleEdit()}>
                    Edit
                  </Button>
                </Grid> */}
                <Grid xs={12} md={4} sx={{ maxWidth: 'auto' }} item>
                <a  href={`${process.env.REACT_APP_ASSET_URL}/job/export/job-${jobData?.job_number}.pdf`} download   target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>  <Button sx={{ width: 'auto', padding: '7px' }} variant="contained" color="primary" startIcon={<FileDownloadIcon />}>
                    Export To PDF
                  </Button> </a>
                </Grid>
                {/* <Grid xs={12} md={5} item mt={2} sx={{ maxWidth: 'auto', marginBlock: 'auto' }}>
                  <Button sx={{ maxWidth: 'auto', marginBlock: 'auto' }} variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={() => handleDelete()}>
                    Delete
                  </Button>
                </Grid> */}
                {/* <Button sx={{ width: "10vw", marginRight: "20px" }} variant="contained" color="primary" startIcon={<FileCopyIcon />}>
            Clone
          </Button> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={'30px'}>
          <Grid container>
            <Grid sx={{ marginTop: "10px" }} xs={5} md={2} sm={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Job No:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={7} md={10} sm={8} item>
              <Typography variant="body1" color="initial">
                {jobData?.job_number}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={5} md={2} sm={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Client:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={7} md={10} sm={8} item>
              <Typography variant="body1" color="initial">
                {jobData?.clientdata.company_name}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={5} md={2} sm={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Client Address:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={7} md={10} sm={8} item>
              <Typography variant="body1" color="initial">
                {jobData?.client_address}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={5} md={2} sm={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Project Manager:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={7} md={10} sm={8} item>
              <Typography variant="body1" color="initial">
                {jobData?.manager_firstname}  {jobData?.manager_lastname}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={5} md={2} sm={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Project Name:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={7} md={10} sm={8} item>
              <Typography variant="body1" color="initial">
                {jobData?.project_name}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={5} md={2} sm={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Status:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={7} md={10} sm={8} item>
              <Typography variant="body1" color="initial">
                {jobData?.status_id?.name}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={5} md={2} sm={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                PO:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={7} md={10} sm={8} item>
              <Typography variant="body1" color="initial">
                {jobData?.po}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={5} md={2} sm={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                PO Amount:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={7} md={10} sm={8} item>
              <Typography variant="body1" color="initial">
                $ {jobData?.po_amount}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={5} md={2} sm={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Invoice Type:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={7} md={10} sm={8} item>
              <Typography variant="body1" color="initial">
                {jobData?.taxes_name}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={5} md={2} sm={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Invoice Line Item Type:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={7} md={10} sm={8} item>
              <Typography variant="body1" color="initial">
                {jobData?.invoice_line_item_type}
              </Typography>
            </Grid>
          </Grid>
          <Typography mt={'30px'} variant="h6" color="initial">
            Additional Details
          </Typography>
          <Card style={{ marginTop: '30px' }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChangeone} aria-label="basic tabs example">
                  <Tab label="Taxes" {...a11yProps(0)} />
                  <Tab label="Fees" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                  Taxes
                </Typography>
                {
                  jobData?.tax?.map((item,i) => (
                    <>
                      <Grid sx={{ marginLeft: 6 }} container>
                        <Grid sx={{ marginTop: "10px" }} md={1} xs={6} item sm={6}>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial" sx={{ minWidth: 'max-content' }}>
                            Tax Name:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px", paddingLeft: '10px' }} md={2} xs={6} item sm={6}>
                          <Typography variant="body1" color="initial">
                            {item.taxesName}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} md={2} xs={6.5} sm={6.2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial" sx={{ minWidth: 'max-content' }}>
                            Tax (Percentage):
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} md={0} xs={0} sm={0} item>{''}</Grid>
                        <Grid sx={{ marginTop: "10px" }} md={7} xs={5.5} sm={5.8} item>
                          <Typography variant="body1" color="initial">
                            {item.tax_percentage}
                          </Typography>
                        </Grid>
                      </Grid>
                      {jobData?.tax?.length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                    </>
                  ))
                }
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                  Fees
                </Typography>
                {
                 jobData?.fee?.map((item) => (
                    <>
                      <Grid sx={{ marginLeft: 6 }} container>
                        <Grid sx={{ marginTop: "10px" }} md={1} xs={6} item sm={6}>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial" sx={{ minWidth: 'max-content' }}>
                            Fee Name:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px", paddingLeft: '10px' }} md={2} xs={6} item sm={6}>
                          <Typography variant="body1" color="initial">
                            {item.FeesName}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} md={2} xs={6.5} sm={6.2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial" sx={{ minWidth: 'max-content' }}>
                            Fee (Percentage):
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} md={0} xs={0} sm={0} item>{''}</Grid>
                        <Grid sx={{ marginTop: "10px" }} md={7} xs={5.5} sm={5.8} item>
                          <Typography variant="body1" color="initial">
                            {item.fees_percentage}
                          </Typography>
                        </Grid>
                      </Grid>
                      {[1, 2].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                    </>
                  ))
                }
              </TabPanel>
            </Box>
          </Card>
          <Typography my={'30px'} variant="h6" color="initial">
            Task History
          </Typography>
          <TableContainer sx={{ border: "1px solid #cccccc" }} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Task</TableCell>
                  <TableCell align='center'>Name</TableCell>
                  <TableCell align='center'>Date</TableCell>
                  <TableCell align='center'>User</TableCell>
                  <TableCell align='center'>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobData?.task?.map((taskData) => (
                  <TableRow
                    key={taskData.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row"><Link to={`/task/view/${taskData.number}`}><b>{taskData.number_str}</b></Link></TableCell>
                    <TableCell align='center'>{taskData.name}</TableCell>
                    <TableCell align='center'>{taskData.createdAt}</TableCell>
                    <TableCell align='center'>{taskData.project_manager[0]}</TableCell>
                    <TableCell align='center'>{taskData.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {loading && (
                  <div>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                   
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
                )}
            </Table>
          </TableContainer>
          <div style={{ marginTop: "30px", display: 'flex' }}>
            {true ? <CheckBoxOutlinedIcon sx={{ marginInline: 2 }} /> : <CheckBoxOutlineBlankOutlinedIcon sx={{ marginInline: 2 }} />}
            <Typography mb={'5px'} variant="h6" color="initial">
              Active
            </Typography>
          </div>
        </Box>
      </Container>
    </>
  )
}

export default ManagerJobView