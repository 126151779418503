import * as React from 'react';
import { ScheduleComponent, Week, Month, Inject, ViewsDirective, ViewDirective } from '@syncfusion/ej2-react-schedule';
import '@syncfusion/ej2-react-schedule/src/schedule'
import { Link } from 'react-router-dom';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';

const Shedule = () => {
    function header(props) {
        return (
            <div>
                <div className="e-cell-header">
                    <div className="e-header-icon-wrapper">
                        <h1 style={{fontWeight:500}}><Link  style={{color:"white",textDecoration:"none"}} to={`/task/view/4535445`}>{props.Subject}</Link></h1>
                        <button className="e-close" title="Close" />
                    </div>
                </div>
            </div>
        );
    }
    const [data] = React.useState([
        {
            "Id": 1,
            "Subject": "Explosion of Betelgeuse Star",
            "Location": "Space Center USA",
            "StartTime": "2022-12-10T04:00:00.000Z",
            "EndTime": "2022-12-10T05:30:00.000Z",
            "CategoryColor": "#65C8D0"
        },
        {
            "Id": 2,
            "Subject": "Thule Air Crash Report",
            "Location": "Newyork City",
            "StartTime": "2022-12-11T06:30:00.000Z",
            "EndTime": "2022-12-11T08:30:00.000Z",
            "CategoryColor": "#65C8D0"
        },
        {
            "Id": 3,
            "Subject": "Blue Moon Eclipse",
            "Location": "Space Center USA",
            "StartTime": "2022-12-12T04:00:00.000Z",
            "EndTime": "2022-12-12T05:30:00.000Z",
            "CategoryColor": "#65C8D0"
        },
        {
            "Id": 4,
            "Subject": "Meteor Showers in 2021",
            "Location": "Space Center USA",
            "StartTime": "2022-12-13T07:30:00.000Z",
            "EndTime": "2022-12-13T09:00:00.000Z",
            "CategoryColor": "#65C8D0"
        },
        {
            "Id": 5,
            "Subject": "Milky Way as Melting pot",
            "Location": "Space Center USA",
            "StartTime": "2022-12-14T06:30:00.000Z",
            "EndTime": "2022-12-14T08:30:00.000Z",
            "CategoryColor": "#65C8D0"
        }
    ])
    let scheduleObj;
    function onEventRendered(args) {
        let categoryColor = args.data.CategoryColor;
        if (!args.element || !categoryColor) {
            return;
        }
        if (scheduleObj.currentView === 'Agenda') {
            args.element.firstChild.style.borderLeftColor = categoryColor;
        }
        else {
            args.element.style.backgroundColor = categoryColor;
        }
    }
    function onEventClick(args) {
    }
    return (
        <>
        <Grid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
          Daily Work Report
          </Typography>
          
        </Stack>
        </Grid>
        <Grid>
        <ScheduleComponent quickInfoTemplates={{ header: header.bind(this) }} startHour='10:00' endHour='20:00' readonly={true} width='100%' height='85.5vh' selectedDate={new Date()} popupOpen={onEventClick.bind(this)} eventSettings={{ dataSource: data }} ref={t => scheduleObj = t} eventRendered={onEventRendered.bind(this)} >
            <ViewsDirective>
                <ViewDirective option='Month' />
                <ViewDirective option='Week' dateFormat='dd-MMM-yyyy' />
            </ViewsDirective>
            <Inject services={[Month, Week]} />
        </ScheduleComponent >
        </Grid>
        </>
    );
};

export default Shedule;
