import { TextField, Autocomplete, Chip, Box } from '@mui/material';
import { array } from 'prop-types';
import React, { useState } from 'react'

const Dropdown = ({ data, label, id, state, setValue, getValues, register, validation, errors }) => {
    const [personName, setPersonName] = React.useState([]);
   
    return (

        <>
            <Autocomplete
                 options={data}
                 getOptionLabel={(option) => option.label}
                value={personName}
                onChange={(e, value) =>  {  
                    setValue(state, value); 
                    setPersonName(value)
                }
            }

                renderInput={(params) => {
                    return (
                        <>
                           
                                    <TextField
                                        {...params}
                                        fullWidth
                                        // {...register(state)}
                                        type="text"
                                        label={label}
                                        InputLabelProps={{ shrink: true }}
                                    />
                        </>
                    )
                }
                }
                renderValue={(selected) => {
                    return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
                            <Chip onDelete={(e) => { handleDeletechip(e, option) }} label={selected.label} sx={{ zIndex: 9999, backgroundColor: '#65C8D0', color: 'white' }}
                            />
                        </Box>
                    )
                }}
            />
            
        </>
    )
}
export default Dropdown;