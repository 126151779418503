import { Button, FormLabel, Grid, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Controller } from 'react-hook-form';
import React from 'react';
// ----------------------------------------------------------------------
export default function TaxCreateForm({
  register,
  errors,
  isEdit,
  setValue,
  getValues,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control
}) {
  const navigate = useNavigate();
 const [textAreaCount , settextAreaCount ] = React.useState(0)
  return (
    <form autoComplete="off" noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3} padding={3}>
        {
          params ? <> <Typography variant="h4">
          Edit Tax
        </Typography> </> : 
        <> <Typography variant="h4">
        Create Tax
      </Typography> 
      </>
        }
        <Grid>
        <Grid container rowSpacing={2} columnSpacing={5}>
          <Grid xs={12} md={6} item>
          <Grid> 
            <FormLabel><>Name <span style={{ color: "red" }} >*</span></></FormLabel>  
            </Grid>
            <TextField
              fullWidth
              type="text"
              {...register('name', {
                required: '**Field is required and needs to be a text',
              })}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength:50 }}
            />
          </Grid>
          <Grid xs={12} md={6} item>{''}</Grid>
          <Grid xs={12} md={6} item >
          <Grid> 
            <FormLabel><>Percentage <span style={{ color: "red" }} >*</span></></FormLabel>  
            </Grid>
            <TextField
              fullWidth  
              type="number"
              onInput={(e) => {
                 e.target.value = e.target.value <= 100 ? e.target.value.toString()?.slice(0, 3) : ''
              }}
              min={0}             
              {...register('percentage', {
                required: '**Field is required and needs to be a Number',
                pattern: {
                  value: /^[0-9]/,
                  message: 'invalid input type'
                }
              })}
              error={Boolean(errors.percentage)}
              helperText={errors.percentage?.message}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid xs={12} md={6} item>{''}</Grid>
              </Grid>
              </Grid>
              <Grid sx={{display:"flex"}} >
        <LoadingButton  size="large" type="submit" variant="contained" loading={loading} style={{ width: 300 }}>
          Submit
        </LoadingButton>
        {params && (
          <Button
          style={{ width: 300,marginLeft:"20px" }}
            variant="contained"
            size="large"
            color="error"
            fullWidth
            onClick={() => {
              navigate('/master/tax/list');
            }}
          >
            Cancel
          </Button>
        )}
        </Grid>
      </Stack>
    </form>
  );
}

 TaxCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func
};
