import { Button, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Controller } from 'react-hook-form';
import React from 'react';
// ----------------------------------------------------------------------
export default function VehicleCreateForm({
  register,
  errors,
  isEdit,
  handleSubmit,
  onSubmit,
  params,
  loading,
  getValues,
  setValue,
  editData,
  control
}) {

  const navigate = useNavigate();
  const [textAreaCount, settextAreaCount] = React.useState(0);
  const [checkbox, setcheckbox] =  React.useState();
  return (
    <form autoComplete="off" noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3} padding={3}>
        {
          params ? <> 
          <Typography variant="h4" component="h2" >
          Edit Vehicle
        </Typography>
          </> : 
          <>
          <Typography variant="h4" component="h2" >
          Create Vehicle
        </Typography>
           </>
        }
        <Grid>
          <Grid container rowSpacing={2} columnSpacing={5}>
            <Grid xs={12} md={6} item>
              <Grid>
                <FormLabel><>Unit Number<span style={{ color: "red" }} >*</span></></FormLabel>
              </Grid>
              <TextField
                fullWidth
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.toString()?.slice(0, 50)
                }}
                min={0}
                {...register('unit_number', {
                  required: '**Field is required and needs to be a number',
                  pattern: {
                    value: /^[0-9]/,
                    message: 'invalid input type'
                  }
                })}
                error={Boolean(errors.unit_number)}
                helperText={errors.unit_number?.message}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength:50 }}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <Grid>
                <FormLabel>Make</FormLabel>
              </Grid>
              <TextField
                fullWidth
                type="text"
                {...register('make')}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength:50 }}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <Grid>
                <FormLabel>Model</FormLabel>
              </Grid>
              <TextField
                fullWidth
                type="text"
                {...register('model')}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength:50 }}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <Grid>
                <FormLabel>Year</FormLabel>
              </Grid>
              <TextField
                fullWidth
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.toString()?.slice(0, 4)
                }}
                min={0}
                {...register('year',{
                  pattern: {
                    value: /^[0-9]/,
                    message: 'invalid input type'
                  }
                })}
                error={Boolean(errors.year)}
                InputLabelProps={{ shrink: true }}
                helperText={errors.year?.message}
              />
            </Grid>
            <Grid xs={12} md={6} item >
              <Grid>
                <FormLabel>Remarks</FormLabel>
              </Grid>
              <TextField
                multiline
                fullWidth
                rows={3}
                {...register('remark')}
                onChange={(e, val) => {
                  settextAreaCount(e.target.value.length)
                  setValue("remark", e.target.value)
                }}
                inputProps={{ maxLength: 300 }}
                InputLabelProps={{ shrink: true }}
              />
              <Grid sx={{ fontSize: "12px", textAlign: "end" }} xs={12}> {textAreaCount} / 300</Grid>
            </Grid>
            <Grid xs={12} md={6} item >{''}</Grid> 
            <Grid xs={1} md={1} item > 
            <FormGroup>
          <FormControlLabel control={
            <Checkbox
              defaultChecked={params && editData?.data?.active}
              checked={getValues('active')? true : false}
              onChange={(e,val) => {
                  setValue('active',val);
                  setcheckbox(val);
                }}
                />
          } 
          label="Active"  
            />
        </FormGroup>
          </Grid> 
          </Grid>
        </Grid>

        <Grid sx={{ display: "flex" }} >
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} style={{ width: 300 }}>
            Submit
          </LoadingButton>
          {params && (
            <Button
              style={{ width: 300, marginLeft: '20px' }}
              variant="contained"
              size="large"
              color="error"
              fullWidth
              onClick={() => {
                navigate('/master/vehicle/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form>
  );
}

VehicleCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func
};
