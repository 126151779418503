import { Divider, Grid, TextField, Button, Typography } from "@mui/material";
import React from "react";
import { useFieldArray } from "react-hook-form";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Dropdown from "src/components/customecomponent/Dropdown";
import Iconify from 'src/components/iconify';
import AddIcon from '@mui/icons-material/Add';

function NestedArray({ nestIndex, control, register, setValue, getValues }) {
    const { fields: legalAddress, remove, insert: insertLegalAddress, } = useFieldArray({
        control,
        name: `locationItem.${nestIndex}.legalAddress`
    });
    const handleLegalAddressButton = () => {
        insertLegalAddress(legalAddress.length, {
            "lot": "",
            "block": "",
            "plan": "",
            "section": "",
            "township": "",
            "range": '',
            "meridian": '',
        })
    }
    const Lsd = [{
        label: 'LSD Type 1',
        value: 'LSD Type 1',
    }, {
        label: 'LSD Type 2',
        value: 'LSD Type 2',
    }]
    return (
        <>
            <Grid>
                <Grid mt={2}>
                    <Typography variant="h6" component="h6">
                        Legal Address
                    </Typography>
                </Grid>
                <Grid mt={2} >
                    {
                        legalAddress.map((ele, index) => (
                            <>

                                <Grid sx={{ display: 'flex', marginTop: '10px' }} key={index}>
                                    <Grid sx={{ marginRight: '15px' }}>
                                        <HighlightOffIcon variant="contained" onClick={() => remove(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                            {""}
                                        </HighlightOffIcon>
                                    </Grid>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Grid sx={{ marginLeft: '15px' }}>
                                        <Grid container direction={"row"} spacing={5} item>
                                            <Grid md={6} item mt={2} >
                                                <TextField
                                                    fullWidth
                                                    sx={{ width: '30vw' }}
                                                    type="text"
                                                    label="Lot"
                                                    name='companyName'
                                                    {...register(`locationItem.${nestIndex}.legalAddress.${index}.lot`)}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            <Grid md={6} item mt={2}>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    sx={{ width: '30vw' }}
                                                    label="Block"
                                                    name='block'
                                                    {...register(`locationItem.${nestIndex}.legalAddress.${index}.block`)}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={"row"} spacing={5} item>
                                            <Grid md={6} item mt={2}>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    label="Plan"
                                                    name='plan'
                                                    {...register(`locationItem.${nestIndex}.legalAddress.${index}.plan`)}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid> <Grid md={6} item mt={2}>
                                                <Dropdown state={`locationItem.${nestIndex}.legalAddress.${index}.LSD`} setState={setValue} getValues={getValues} data={Lsd} label='LSD'
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={"row"} spacing={5} item>
                                            <Grid md={6} item mt={2}>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    label="Section"
                                                    name='section'
                                                    {...register(`locationItem.${nestIndex}.legalAddress.${index}.section`)}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            <Grid md={6} item mt={2}>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    key={`${index}${ele}`}
                                                    label="Township"
                                                    name='township'
                                                    {...register(`locationItem.${nestIndex}.legalAddress.${index}.township`)}

                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={"row"} spacing={5} item>
                                            <Grid md={6} item mt={2}>
                                                <TextField
                                                    fullWidth
                                                    key={`${index}${ele}`}
                                                    type="text"
                                                    label="Range"
                                                    name='range'
                                                    {...register(`locationItem.${nestIndex}.legalAddress.${index}.range`)}

                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid> <Grid md={6} item mt={2}>
                                                <TextField
                                                    fullWidth
                                                    key={`${index}${ele}`}
                                                    type="text"
                                                    label="Meridian"
                                                    name='meridian'
                                                    {...register(`locationItem.${nestIndex}.legalAddress.${index}.meridian`)}

                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Divider sx={{ marginTop: '15px' }} />
                            </>
                        ))
                    }
                </Grid>

                <Grid mt={2}>
                    <Button variant="contained" sx={{ width: '15vw', marginTop: '20px' }} onClick={() => handleLegalAddressButton()} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Legal Address</Button>
                </Grid>
            </Grid>
        </>
    )
}
export default NestedArray;