import { Button, FormLabel, Grid, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Controller } from 'react-hook-form';
import React from 'react';
// ----------------------------------------------------------------------
export default function JobCategoryCreateForm({
  register,
  errors,
  isEdit,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control
}) {
  const navigate = useNavigate();
const [textAreaCount, settextAreaCount] = React.useState(0);
  return (
    <form autoComplete="off" noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3} padding={3}>
      {
          params ? 
          <>
        <Typography variant="h4" gutterBottom>
        Edit Job Category
        </Typography> 
        </> : 
        <> 
        <Typography variant="h4" gutterBottom>
        Create  Job Category
        </Typography>
        </>
        }
        <Grid>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} item>
          <Grid> 
            <FormLabel><>Name <span style={{ color: "red" }} >*</span></></FormLabel>  
            </Grid>
            <TextField
              required
              fullWidth
              type="text"
              {...register('name', {
                required: '**Field is required and needs to be a text',
              })}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength:50 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>{''}</Grid>
              <Grid item xs={12} md={6}>
              <Grid> 
            <FormLabel><>Remarks </></FormLabel>  
            </Grid>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    {...register('remark')}
                    onChange={(e, val) => {
                      settextAreaCount(e.target.value.length)
                      setValue("remark", e.target.value)
                    }}
                    inputProps={{ maxLength: 300 }}
                    InputLabelProps={{ shrink: true }}
                  />
                <Grid item sx={{ fontSize: "12px", textAlign: "end" }} xs={12}> {textAreaCount} / 300</Grid>
              </Grid>
              </Grid>
              </Grid>
              <Grid sx={{display:"flex"}} >
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} style={{ width: 300 }}>
          Submit
        </LoadingButton>
        {params && (
          <Button
          style={{ width: 300,marginLeft:"20px" }}
            variant="contained"
            size="large"
            color="error"
            fullWidth
            onClick={() => {
              navigate('/master/jobcategory/list');
            }}
          >
            Cancel
          </Button>
        )}
        </Grid>
      </Stack>
    </form>
  );
}

 JobCategoryCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func
};
