import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Box, Button, Container, FormLabel, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { FetchContext } from 'src/context/FetchContext';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { API_ROUTES } from 'src/services/constant';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { AuthContext } from 'src/context/AuthContext';
import sidebarlogo from '../../../../src/components/logo/sidebarlogo/imagesidebar.png'


const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));
  const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));

export default function InitialChangePassword() {
  const [newPassword, setnewPassword] = React.useState('');
  const [confirmPassword, setconfirmPassword] = React.useState('');
  const [shownewPassword, setShowNewPassword] = useState(false);
  const [showconPassword, setShowConPassword] = useState(false);

  const { authAxios } = useContext(FetchContext);
  const { state, dispatch } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    defaultValues: {
      password: '',
      confirm_password: '',
    },
  });

  const navigate = useNavigate();

  const { PRIVATE_ROUTE } = API_ROUTES;
 
  const onSubmit = async (data) => {
    if(data.password !== data.confirm_password) {
      toast.error('Your New Password And Confirm Password did Not Match');
    }
    else if(data) {
      const  {data :{massage,statusCode,token,loginadmin}}  = await authAxios.post(`${PRIVATE_ROUTE.INITIAL_CHANGE_PASSWORD}${state.user._id}`, data);
      if (statusCode !== 200) {
        toast.error(massage)
      }
      else {
        dispatch({
          type: 'LOGIN',
          payload: {
            token,
            massage,
            statusCode,
            loginadmin
          }
        });
        console.log("61", token);
        toast.success('Change Settings successfully');
        navigate('/', { replace: true });
        setValue('password', '');
        setValue('confirm_password', '');
      }
    }
   
    }

  const handleClicknewPassword = () => setShowNewPassword(!shownewPassword);
  const handleClickconfirmPassword = () => setShowConPassword(!showconPassword);

  return (
    <StyledRoot>
    <Container maxWidth="sm">
    <StyledContent>
    <Box>
      <img style={{ paddingInline: 0, width: "50%", height: "50%", margin: "auto", marginBottom: "50px" }} src={sidebarlogo} alt="" />
    </Box>
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}  >
        <Typography variant="h4" gutterBottom>
        Initial Change Password
        </Typography>
        <Grid>
          <Grid container  spacing={2}>
            <Grid item xs={12} md={12}>
                <FormLabel>
                  <>
                    New Password<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
      
              <TextField
                fullWidth
                onChange={(e) => setnewPassword(e.target.value)}
                {...register('password', {
                  required: '**Field is required and needs to be a Valid Password',
                })}
                type={shownewPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClicknewPassword}
                      >
                        {shownewPassword ? <Visibility className='password_visibale_icon' /> : <VisibilityOff className='password_visibale_icon' />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid>
                <FormLabel>
                  <>
                    Confirm Password<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
              </Grid>
              <TextField
                fullWidth
                onChange={(e, val) => setconfirmPassword(e.target.value)}
                {...register('confirm_password', {
                  required: '**Field is required and needs to be a Valid Password',
                })}
                type={showconPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickconfirmPassword}
                      >
                        {showconPassword ? <Visibility className='password_visibale_icon' /> : <VisibilityOff className='password_visibale_icon' />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(errors.confirm_password)}
                helperText={errors.confirm_password?.message}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: 'flex',marginTop:"30px !important"}}>
          <LoadingButton
            loading={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            style={{ width: 500 }}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Stack>
    </form>
  </StyledContent>
  </Container>
      </StyledRoot>
  );
}
