import { Toaster } from 'react-hot-toast';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import './App.css';

import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { FetchProvider } from './context/FetchContext';
// import { useContext } from 'react';
// import '@progress/kendo-theme-default/dist/all.css';
import '@progress/kendo-theme-default/dist/all.css'

// ----------------------------------------------------------------------

export default function App() {

  return (
    <AuthProvider>
      <FetchProvider>
        <Toaster toastOptions={{ duration: 2000 }} />
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </FetchProvider >
    </AuthProvider>
  );
}
