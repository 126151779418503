import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider,
  createTheme,
  Box,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from 'mui-datatables';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Link, useNavigate } from 'react-router-dom';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import clsx from 'clsx';
import Scrollbar from 'src/components/scrollbar';
import Iconify from '../../../iconify';
import Icon from 'src/components/color-utils/Icon';
import { AuthContext } from 'src/context/AuthContext';
// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function StaffDwrlist() {
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const [disablededit, setDisabledEdit] = useState([]);
  const { authAxios } = useContext(FetchContext);
  const [totalEstimatedHours, setTotalEstimatedHours] = useState(0);
  const [totaltask_hour, setTotalTaskHour] = useState(0);
  const {state} = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (state.user.role === "employee") {
        const postData = {
          employee_id: state.user._id
        };
        try {
          const response = await authAxios.post(PRIVATE_ROUTE.GET_EMPLOYEES_REPORT, postData);
          response?.data?.data?.forEach(item => {
            setTotalEstimatedHours(prevTotal => prevTotal + parseInt(item.taskdata.estimate_hour, 10));
            setTotalTaskHour(prevTotal => prevTotal + parseInt(item.task_hour, 10));
          });
        } catch (error) {
          console.error("Error fetching employee report:", error);
        }
      }
    };
    fetchData();
  }, []);

  function roundToTwo(num) {
    return +(Math.round(num * 100 + "e+2") + "e-2");
  }
  const totalAccuracy = roundToTwo(totalEstimatedHours / totaltask_hour)
  console.log("totalAccuracy", totalAccuracy);

  const handleEditRedirect = (e, id) => {
    navigate(`/staff/dwr/update/${id}`);

  };
  const handleRowDelete = async (e, id) => {
    if (confirm('Are you sure want to delete?') === true) {
      setLoading(false);
      await authAxios.post(`${PRIVATE_ROUTE?.DELETE_DWR}${id}`);
      getUserdata('');
      setOpen(null);
      setSelectedRow(null);
      setLoading(true);
    }
  };

  

  const handlecreateform = () => {
    navigate('/staff/dwr/create');
  };
  const getUserdata = (query) => {
    let isMounted = true;
    const getUsers = async () => {
      const { data } = await authAxios.get(
        `${PRIVATE_ROUTE?.GET_DWR}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}`
      );
      console.log(12345, data.data);
      if (isMounted) {
        setUsers(
          data?.data?.map((item, index) => {
            const manager_name = item.project_managers.map(
              (project_manager) => project_manager.first_name + ' ' + project_manager.last_name
            );
            let stausorignal = 'Pending';
            if (item.status === 1) {
              stausorignal = 'Approved';
            } else if (item.status === 2) {
              stausorignal = 'Rejected';
            }
            return {
              _id: item._id,
              dwr_number: (
                <Link to={`/staff/dwr/view/${item._id}`}>
                  <b>{item?.dwr_number}</b>
                </Link>
              ),
              client_representative: <b>{item?.client_representative}</b>,
              task_name: item.task_name,
              job_number: item.job_number,
              project_manager: manager_name.join(', '),
              submitted: <Icon color={item?.submit_status === true ? 'green' : 'red'} name="people" size={30} />,
              task_hour: item?.task_hour,
              end_date: item?.end_date,
              remark: item?.remark,
              status: stausorignal,
            };
          })
        );
        setCount(data.total);
        setLoading(false);
      }
    };

    getUsers().catch((err) => {
      if (!isMounted) return;
      console.error('failed to fetch data', err);
      setLoading(false);
    });
    return () => {
      isMounted = false;
    };
  };
  console.log('zala', disablededit);
  useEffect(() => {
    getUserdata('');
  }, [rowsPerPage, page]);
  console.log(94, users);
  const columns = [
    {
      name: '_id',
      label: 'Name',
      options: {
        draggable: true,
        sort: true,
        filter: false,
        display: false,
      },
    },
    {
      name: 'dwr_number',
      label: 'DWR Number',
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'client_representative',
      label: 'Client',
      options: {
        draggable: false,
        sort: true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'task_name',
      label: 'Task Name',
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true,
      },
    },
    {
      name: `job_number`,
      label: 'Job Number',
      options: {
        draggable: false,
        sort: true,
        filter: true,
        display: true,
      },
    },   
    {
      name: 'project_manager',
      label: 'Project Manager',
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'submitted',
      label: 'Submitted',
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'task_hour',
      label: 'Task Hour',
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'remark',
      label: 'Remark',
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: false,
      },
    },
    {
      name: '',
      lable: 'Action',
      options: {
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log('tableMeta', tableMeta);
          return (
              <div style={{ display: 'flex', gap: '5px' }}>
                <EditIcon
                color={tableMeta.rowData[8] === 'Pending' ? "inherit" : "disabled"}
                onClick={tableMeta.rowData[8] === 'Pending' ? (e) => handleEditRedirect(e, tableMeta.rowData[0]) : undefined}
                />
                <DeleteIcon 
                color={tableMeta.rowData[8] === 'Pending' ? "inherit" : "disabled"}
                onClick={tableMeta.rowData[8] === 'Pending' ? (e) => handleEditRedirect(e, tableMeta.rowData[0]) : undefined}
                />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectToolbarPlacement: 'none',
    tableId: 'HelloWorld',
    rowHover: false,
    jumpToPage: true,
    responsive: 'standerd',
    print: true,
    fixedHeader: true,
    filter: false,
    count: count,
    selectableRows: false,
    customToolbar: () => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '10px' }}>
          <Typography variant="h6" gutterBottom>
            Total Accuracy : {totalEstimatedHours && totalAccuracy + "%"}
          </Typography>
        </div>
      );
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',
        },
      };
    },
    setTableProps: () => {
      return {
        border: '1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)',
        size: 'small',
      };
    },
    onSearchChange: (searchQuery) => {
      if (searchQuery !== null && searchQuery.length > 2) {
        getUserdata(searchQuery);
        setLoading(true);
      }
      if (searchQuery === null) {
        getUserdata('');
      }
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setPage(tableState.page);
      }
    },
  };

  return (
    <>
      <Grid sx={{ p: 4, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Daily Work Report
          </Typography>
          {/* <IconButton variant="contained" Icon={<CalendarMonth  />} /> */}
          <Button variant="contained" onClick={handlecreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Daily Work Report
          </Button>
        </Stack>

        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {loading && (
                  <div>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                )}
                <MUIDataTable options={options} columns={columns} data={users} />
                {!users && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </>
  );
}
