import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider,
  createTheme,
  Box,
  CircularProgress,
  Backdrop,
  FormControl,
  Select,
  FormLabel,
  TextField,

} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Scrollbar from 'src/components/scrollbar';
import Iconify from '../../../iconify';
import download from 'downloadjs';
import DateFnsUtils from '@date-io/date-fns';
import { LoadingButton } from '@mui/lab';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'


import { format } from 'date-fns';
import toast from 'react-hot-toast';


// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function InvoiceList() {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);
  const [showloading, setshowLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedstartDate, setselectstartDate] = React.useState(new Date());
  const [selectendDate, setselectendDate] = React.useState(new Date());
  const [emplydata, setEmplydata] = useState([]);
  const tomorrow = dayjs().add(1, 'day');
  const [totalEstimatedHours, settotalEstimatedHours] = useState(0);
  const [totaltask_hour, settotaltask_hour] = useState(0);
  const [isValidDate, setIsValidDate] = useState(false);

  const userdata = async (query) => {
    try {
      const response = await authAxios.get(
        `${PRIVATE_ROUTE?.GET_USER}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}`
      );

      console.log('Full response:', response);

      const filterEmployRole = response?.data?.data?.filter((value) => value.role === 'employee');
      const emplydata = filterEmployRole?.map(({ _id, first_name, last_name, role }) => ({
        id: _id,
        label: `${first_name} ${last_name}`,
      }));
      console.log('emplydata--->', emplydata);

      setEmplydata(emplydata);
      console.log('Transformed Data:', emplydata);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  useEffect(() => {
    // getInvoicedata('');
    const result = userdata('');
    console.log('First Names:', result);
  }, [rowsPerPage, page]);

  const columns = [
    {
      name: 'user_firstname',
      label: 'Employ Name',
    },
    {
      name: 'task_name',
      label: 'Task name',
    },
    {
      name: 'dwr_number',
      label: 'Dwr',
    },
    {
      name: 'task_hour',
      label: 'Estimate Task Hours/Dwr Hours',
    },
    {
      name: 'accuracy',
      label: 'Accuracy',
    },
  ];

  const handlestartdatechenge = (date) => {
    
    setselectstartDate(date);
    
  };
  const handleEndDateChange = (date) => {
    // if (date.getTime() >= selectedstartDate.getTime()) {
      setselectendDate(date);
    // }
   
  };

  const options = {
    selectToolbarPlacement: 'none',
    tableId: 'HelloWorld',
    rowHover: false,
    jumpToPage: true,
    responsive: 'standerd',
    filters: true,
    print: true,
    fixedHeader: true,
    count: count,
    selectableRows: false,
    customToolbar: () => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '10px' }}>
          <Typography variant="h6" gutterBottom>
            Total Accuracy : {totalEstimatedHours && totalAccuracy + "%"}
          </Typography>
        </div>
      );
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',
        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: '1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)',
        size: 'small',
      };
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setPage(tableState.page);
      }
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      employee: '',
      employee_id: '',
    },
  });

  // const onSubmit = async (data) => {
  //   console.log('Submitted Data:', data);
  // };

  function roundToTwo(num) {
    return +(Math.round(num  * 100 + "e+2") + "e-2");
  }

  const onSubmit = async (data) => {
    const postData = {
      employee_id: data.employee_id,
      start_date: selectedstartDate,
      end_date: selectendDate
    };
    console.log('Submitted Data:', postData);
    setLoading(true);
    try {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_DWR_REPORT, postData);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(false);
        setInvoices(
          response?.data?.data?.map((item, i, array) => {
            let accuracy = item.taskdata.estimate_hour / item.task_hour;
            accuracy = roundToTwo(accuracy) ;
            console.log("task_hour", accuracy);
            settotalEstimatedHours(prevTotal => prevTotal + parseInt(item.taskdata.estimate_hour));
            settotaltask_hour(prevTotal => prevTotal + parseInt(item.task_hour));
            return {
              task_id: item.accuracy,
              task_name: (
                <Link to={`/task/view/${item.task_id}`}>
                  <b>{item?.task_name}</b>
                </Link>
              ),
              dwr_number: (
                <Link to={`/dwr/view/${item._id}`}>
                  <b>{item?.dwr_number}</b>
                </Link>
              ),
              task_hour: item.task_hour === null ? 0 : ` ${item.taskdata.estimate_hour} / ${item.task_hour}`,
              accuracy: accuracy + '%',
              user_firstname: `${item.user_firstname} ${item.user_lastname}`,
            };
          })
        );
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      toast.error('An error occurred while submitting data.');
      setLoading(false);
    }
  };

  const totalAccuracy = roundToTwo(totalEstimatedHours / totaltask_hour);

  return (
    <>
      <Grid sx={{ maxWidth: 1500, margin: 'auto' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Reports
          </Typography>
        </Stack>
        <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className="main" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              className="main1"
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                margin: '0 10px',
              }}
            >
              <FormLabel> 
                <>Select Employee</>
              </FormLabel>
              <div style={{ marginTop: '17px' }}>
                <ChipDropdown
                  state={'employee'}
                  secondstate={'employee_id'}
                  setValue={setValue}
                  getValues={getValues}
                  checked={'checked'}
                  data={emplydata}
                  register={register}
                  errors={errors}
                  // value={employeeData}
                  validation={true}

                />
              </div>
            </div>
            <div
              className="main2"
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                margin: '0 10px',
              }}
             >
              <FormLabel> Start Date</FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="YYYY-MM-DD"
                  value={selectedstartDate}
                  onChange={(value) => handlestartdatechenge(value)}
                  renderInput={(props) => <TextField {...props} />}
                // minDate={selectedstartDate}
                />
              </LocalizationProvider>

              {/* <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MuiDatePicker
                    label="Basic date picker"
                    value={selectedstartDate}
                    onChange={handlestartdatechenge}
                    renderInput={(params) => <input {...params} />}
                  />
                </LocalizationProvider>
              </FormControl> */}
            </div>
            <div
              className="main3"
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                margin: '0 10px',
              }}
             >
              <FormLabel> End Date</FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={selectendDate}
                onChange={(value) => handleEndDateChange(value)}
                renderInput={(props) => <TextField {...props} />}
                minDate={selectedstartDate}
                onError={(newError) => setIsValidDate(newError)}
              />
            </LocalizationProvider>
            </div>
            <div
              className="main4"
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                margin: '0 10px',
                marginTop: '25px',
              }}
            >
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                // loading={loading}
                // style={{ width: 300 }}
                disabled={isValidDate}
              >
                Submit
              </LoadingButton>
            </div>
          </div>
        </form>

        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MUIDataTable 
                  options={options} 
                  columns={columns} 
                  data={invoices} 
                />
                {showloading ? (
                  <div>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showloading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                ) : (
                  ''
                )}
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {!invoices && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </>
  );
}
