import { LoadingButton } from '@mui/lab';
import { Button, FormLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { FetchContext } from 'src/context/FetchContext';
import { useForm } from 'react-hook-form';
import { API_ROUTES } from 'src/services/constant';
import { AuthContext } from 'src/context/AuthContext';

export default function ProfilePage() {
  const [id, setid] = useState("")
  const { authAxios } = useContext(FetchContext);
  const { state, dispatch } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
    },
  });
const { PRIVATE_ROUTE } = API_ROUTES;
const hanleprofiledata = () => {
  if (state) {
    setid(state.user._id);
   setValue('first_name',state.user.first_name);
   setValue('last_name',state.user.last_name);
   setValue('email',state.user.email);
   setValue('role',state.user.role);
  }
}
useEffect(() => {
  hanleprofiledata();
}, []);
  const onSubmit = async (data) => {
 
    if (data) {
      const {data:{user,massage,statusCode}} = await authAxios.post(`${PRIVATE_ROUTE?.EDIT_PROFILE}${id}`, data);
      const token = state.token
      dispatch({
        type: 'LOGIN',
        payload: {
          token,
          massage,
          statusCode,
          user
        }
      });
      toast.success('Edit Profile successfully');
    }else {
      toast.error('Edit Profile Faild');
    }
  }
  return (
    <form autoComplete="off" noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3} padding={3}>
        <Typography variant="h4" gutterBottom>
         Edit Profile
        </Typography>
        <Grid>
        <Grid container spacing={2}>
            <Grid xs={12} md={6} item>
            <Grid> 
            <FormLabel><>First Name<span style={{ color: "red" }} >*</span></></FormLabel>  
            </Grid>
            <TextField
            fullWidth
            type="text"
            {...register('first_name', {
              required: '**Field is required and needs to be a text',
            })}
            error={Boolean(errors.first_name)}
            helperText={errors.first_name?.message}
            inputProps={{ maxLength:50 }}
          />
            </Grid>
           <Grid item xs={12} md={6}> 
           <Grid> 
            <FormLabel><>Last Name<span style={{ color: "red" }} >*</span></></FormLabel>  
            </Grid>
            <TextField
            fullWidth
            type="text"
            {...register('last_name', {
              required: '**Field is required and needs to be a text',
            })}
            error={Boolean(errors.last_name)}
            helperText={errors.last_name?.message}
            inputProps={{ maxLength:50 }}
          />
          </Grid> 
        <Grid item xs={12} md={6}>
        <Grid> 
            <FormLabel><>Email</></FormLabel>  
            </Grid>
            <TextField
            fullWidth
            type="text"
            disabled
            {...register('email')}
          />
           </Grid>

        <Grid item xs={12} md={6}>
        <Grid> 
            <FormLabel><>Role</></FormLabel>  
            </Grid>
            <TextField
            fullWidth
            type="text"
            disabled
            {...register('role')}
          />
          </Grid>
        </Grid>
        </Grid>
        <Grid sx={{ display: "flex" }} >
            <LoadingButton loading={isSubmitting}  fullWidth size="large" type="submit" variant="contained"  style={{ width: 300 }}>
            Submit
          </LoadingButton> 
        </Grid>
      </Stack>
    </form>
  )
}
