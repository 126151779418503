
import React, { useCallback, useContext, useEffect } from 'react';
import { useParams, useNavigate, json } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import ManagerDailyWorkReportCreateForm from './ManagerDailyWorkReportCreateForm';

const { PRIVATE_ROUTE } = API_ROUTES;

function ManagerDailyWorkReportForm() {

  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
 
 
  const [alignment1, setAlignment1] = React.useState('');
  const [alignment2, setAlignment2] = React.useState('');
  const [alignment3, setAlignment3] = React.useState('');
  const [orderDate, setOrderDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,

    getValues,
    control
  } = useForm({
});
  const getJobFormById = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_JOB_BY_ID}${id}`
    );
    setValue('hours', data?.data.hours);
    setValue('description', data?.data.description);
    setValue('name', data?.data.name);
    setValue('unit', data?.data.unit);
    setValue('quantity', data?.data.quantity);
    setValue('discription', data?.data.discription);
    setValue('include_discription', data?.data.include_discription);
    setValue('representativeSign', data?.data.representativeSign);
    setValue('representativeSignTwo', data?.data.representativeSignTwo);
    setValue('gl_code', data?.data.gl_code);
    setValue('job_categories', data?.data.job_categories);
    setValue('job_scope', data?.data.job_scope);
    setValue('job_scope_details', data?.data.job_scope_details);
    setValue('order_date', data?.data.order_date);
    setValue('Due_date', data?.data.Due_date);
    setValue('tax_percentage', data?.data.tax_percentage);
    setValue('fees_percentage', data?.data.fees_percentage);
    setValue('field_name', data?.data.field_name);
    setValue('field_value', data?.data.field_value);
    setValue('image', data?.data.image);
    setValue('active', data?.data.active);
    setValue('PM_approved ', data?.data.PM_approved );
    setValue('submitted ', data?.data.submitted );
    setValue('remark', data?.data.remark);
    
  }, [authAxios, id, setValue]);
  useEffect(() => {
    if (id) {
      getJobFormById();
    }
  }, [getJobFormById, id]);

  const onSubmit = async (data) => {
    
    
    if (id) {
      const response = await authAxios.post(
        `${PRIVATE_ROUTE?.EDIT_JOB}${id}`,
        data
        );
        toast.success(response.data.massage);
        navigate('/Managerdwr/list');

      } else {
        const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_JOB, data);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/Managerdwr/list');
      }
      else {
        toast.error(response.data.massage);
      }
    }
    
    setValue('client_number', '');
    setValue('company_name', '');
    setValue('representativeSign', '');
    setValue('representativeSignTwo', '');
    setValue('company_address', '');
    setValue('website', '');
    setValue('location_name', '');
    setValue('munciple_address', '');
    setValue('DWR_invoice', '');
    setValue('client_project', '');
    setValue('PO', '');
    setValue('po_amount', '');
    setValue('subcontract', '');
    setValue('DWR_billing', '');
    setValue('invoice_type', '');
    setValue('invoice_line', '');
    setValue('gl_code', '');
    setValue('job_categories', '');
    setValue('job_scope', '');
    setValue('job_scope_details', '');
    setValue('order_date', '');
    setValue('Due_date', '');
    setValue('tax_percentage', '');
    setValue('fees_percentage', ''); 
    setValue('field_name', '');
    setValue('field_value', '');
    setValue('client_select', '');
    setValue('image', '');
    setValue('active', '');
    setValue('PM_approved ', '');
    setValue('submitted ', '');
    setValue('remark', '');
  };
  return (
    <ManagerDailyWorkReportCreateForm
      orderDate = {orderDate}
      getValues = {getValues}
      setOrderDate = {setOrderDate}
      dueDate = {dueDate}
      setDueDate = {setDueDate}
      alignment1={alignment1}
      setAlignment1={setAlignment1}
      alignment2 = {alignment2}
      setAlignment2 = {setAlignment2}
      alignment3 = {alignment3}
      setAlignment3 = {setAlignment3}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      onSubmit={onSubmit}
      params={id}
      loading={isSubmitting}
      isEdit={id ? "true" : "false"}
      control={control}
      setValue={setValue}
    />
  );
}

export default ManagerDailyWorkReportForm;
