import React from 'react'
import { Grid, Container, Typography, Box, TextField, Stack, Autocomplete, Button, Divider, FormGroup, FormControlLabel, Checkbox, ToggleButtonGroup, ToggleButton, TextareaAutosize, Tabs, Tab, Card, FormLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add'
import PropTypes, { element } from 'prop-types';
import CancelIcon from '@mui/icons-material/Cancel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
// components
import Dropdown from 'src/components/customecomponent/Dropdown';
import { useNavigate } from 'react-router-dom/dist';
import { Controller, useFieldArray } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import ConditionalDropdown from 'src/components/customecomponent/ConditionalDropdown';
import NestedArray from './NestedArray';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';

export default function TaskCreateForm({
  register,
  errors,
  getValues,
  setValue,
  isEdit,
  alignment1,
  setAlignment1,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control,

}) {
  const navigate = useNavigate();
  const [condition, setCondition] = React.useState('');
  const CostItem = [{
    label: 'CostItem Type 1',
    value: 'CostItem Type 1',
  }, {
    label: 'CostItem Type 2',
    value: 'CostItem Type 2',
  }]
  const [textAreaCount, settextAreaCount] = React.useState(0);
  const { fields: labour, remove: removeLabour, insert: insertLabour } = useFieldArray({
    control,
    name: "labourItem",
  });
  const { fields: material, remove: removeMaterial, insert: insertMaterial } = useFieldArray({
    control,
    name: "materialItem",
  });
  const { fields: fix, remove: removeFix, insert: insertFix } = useFieldArray({
    control,
    name: "fixItem",
  });
  const { fields: location, remove: removeLocation, insert: insertLocation } = useFieldArray({
    control,
    name: "locationItem",
  });
  const { fields: contact, remove: removeContact, insert: insertContact } = useFieldArray({
    control,
    name: "contactItem",
  });
  const { fields: additional, remove: removeAdditional, insert: insertAdditional } = useFieldArray({
    control,
    name: "additionalItem",
  });
  const { fields: additionalLabour, remove: removeAdditionalLabour, insert: insertAdditionalLabour } = useFieldArray({
    control,
    name: "additionalLabourItem",
  });
  const { fields: additionalEquipment, remove: removeAdditionalEquipment, insert: insertAdditionalEquipment } = useFieldArray({
    control,
    name: "additionalEquipmentItem",
  });
  const handleLabourDelete = (i) => {
    removeLabour(i, 1)
  }
  const handleMaterialDelete = (i) => {
    removeMaterial(i, 1)
  }
  const handleFixDelete = (i) => {
    removeFix(i, 1)
  }
  const handleAddLabour = () => {
    insertLabour(labour.length, {
      "costItem": "",
      "name": "",
      "unit": "",
      "unitCost": "",
      "hourlyCost": "",
      "budget_quantity": '',
      "text_exemptLabour": false
    })
  }
  const handleAddMaterial = () => {
    insertMaterial(material.length, {
      "costItem": "",
      "name": "",
      "unit": "",
      "unitCost": "",
      "budget_quantity": "",
      "text_exempt": false
    })
  }
  const handleAddFix = () => {
    insertFix(fix.length, {
      "costItem": "",
      "name": "",
      "unit": "",
      "unitCost": "",
      "budget_quantity": "",
      "text_exempt_Fix": false
    })
  }
  const jobScope = [{
    label: '3D Scanning',
    id: '3D Scanning',
  }, {
    label: 'Abandonment',
    id: 'Abandonment',
  }, {
    label: 'Access Map',
    id: 'Access Map',
  }, {
    label: 'Access Road',
    id: 'Access Road',
  }, {
    label: 'AGM Mapping',
    id: 'AGM Mapping',
  }, {
    label: 'Air Photo',
    id: 'Air Photo',
  }, {
    label: 'All Space Plan',
    id: 'All Space Plan',
  }, {
    label: 'Alignment',
    id: 'Alignment',
  }, {
    label: 'ALR/SME Sketch Plan',
    id: 'ALR/SME Sketch Plan',
  }, {
    label: 'Anchor Site',
    id: 'Anchor Site',
  }, {
    label: 'As-Built Survey',
    id: 'As-Built Survey',
  }, {
    label: 'Bathymetric Survey',
    id: 'Bathymetric Survey',
  }, {
    label: 'Battery Site',
    id: 'Battery Site',
  }]
  const status = [{
    label: 'Job Setup',
    id: 'Job Setup',
  }, {
    label: 'Cancelled',
    id: 'Cancelled',
  }, {
    label: 'On Hold',
    id: 'On Hold',
  }, {
    label: 'Ready to Schedule',
    id: 'Ready to Schedule',
  }, {
    label: 'Schedule',
    id: 'Schedule',
  }, {
    label: 'Processing',
    id: 'Processing',
  }, {
    label: 'Ready to Draft',
    id: 'Ready to Draft',
  }, {
    label: 'Drafting',
    id: 'Drafting',
  }, {
    label: 'Ready to Check',
    id: 'Ready to Check',
  }, {
    label: 'Checking',
    id: 'Checking',
  }]
  const jobCategory = [{
    label: 'Government',
    id: 'Government',
  }, {
    label: 'Industrial',
    id: 'Industrial',
  }, {
    label: 'Infrastructure',
    id: 'Infrastructure',
  }, {
    label: 'Legal',
    id: 'Legal',
  }, {
    label: 'Mining',
    id: 'Mining',
  }, {
    label: 'Muncipal',
    id: 'Muncipal',
  }, {
    label: 'Oil & Gas',
    id: 'Oil & Gas',
  }, {
    label: 'Pipeline',
    id: 'Pipeline',
  }, {
    label: 'Residential',
    id: 'Residential',
  }]
  const city = [{
    label: 'city Type 1',
    id: 'city Type 1',
  }, {
    label: 'city Type 2',
    id: 'city Type 2',
  }]
  const projectManager = [{
    label: 'projectManager Type 1',
    id: 'projectManager Type 1',
  }, {
    label: 'projectManager Type 2',
    id: 'projectManager Type 2',
  }]
  const clienLocation = [{
    label: 'clienLocation Type 1',
    id: 'clienLocation Type 1',
  }, {
    label: 'clienLocation Type 2',
    id: 'clienLocation Type 2',
  }]
  const salesPerson = [{
    label: 'salesPerson Type 1',
    id: 'salesPerson Type 1',
  }, {
    label: 'salesPerson Type 2',
    id: 'salesPerson Type 2',
  }]
  const office = [{
    label: 'Default',
    id: 'Default',
  }, {
    label: 'Vancouver',
    id: 'Vancouver',
  }, {
    label: 'Architectural',
    id: 'Architectural',
  }, {
    label: 'Commercial',
    id: 'Commercial',
  }, {
    label: 'Construction',
    id: 'Construction',
  }, {
    label: 'Energy',
    id: 'Energy',
  }, {
    label: 'Engineering',
    id: 'Engineering',
  }, {
    label: 'GIS',
    id: 'GIS',
  }]

  const contactData = [{
    label: 'contact Type 1',
    id: 'contact Type 1',
  }, {
    label: 'contact Type 2',
    id: 'contact Type 2',
  }]
  const Province = [{
    label: 'Province Type 1',
    id: 'Province Type 1',
  }, {
    label: 'Province Type 2',
    id: 'Province Type 2',
  }]

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleLocationDelete = (index) => {
    removeLocation(index);
  }
  const handleAdditionalDelete = (index) => {
    removeAdditional(index);
  }
  const handleAdditionalLabourDelete = (index) => {
    removeAdditionalLabour(index);
  }
  const handleAdditionalEquipmentDelete = (index) => {
    removeAdditionalEquipment(index);
  }
  const handleContactDelete = (index) => {
    removeContact(index);
  }
  const handleAdditionalButton = () => {
    insertAdditional(additional.length, {
      "field_name": "",
      "field_value": '',
    })
  }
  const handleAdditionalLabourButton = () => {
    insertAdditionalLabour(additionalLabour.length, {
      "field_name_labour": "",
      "field_value_labour": '',
    })
  }
  const handleAdditionalEquipmentButton = () => {
    insertAdditionalEquipment(additionalEquipment.length, {
      "field_name_equipment": "",
      "field_value_equipment": '',
    })
  }
  const handleContactButton = () => {
    insertContact(contact.length, {
      "DWR_invoice": false,
    })
  }
  const taskData = [{
    label: 'Job 1',
    value: 'Job 1',
  }, {
    label: 'Job 2',
    value: 'Job 2',
  }]
  const handlelocationButton = () => {
    insertLocation(location.length, {
      "location_name": "",
      "munciple_address": "",
      "latitude": "",
      "longitude": "",
      "map_display": false,

    })
  }
  const [valueBiling, setValueBiling] = React.useState(0);
  const [value, setValueTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleChangeBilling = (event, newValue) => {
    setValueBiling(newValue);
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)} >
      <Stack spacing={3} padding={3}>
        <Typography variant="h4" component="h2">
          Create Task
        </Typography>
        <Grid>
          <Grid container spacing={5}>
            <Grid md={6} xs={12} item>
            <FormLabel><>Select Job<span style={{ color: "red" }} >*</span></></FormLabel>
              <ConditionalDropdown condition={condition} setCondition={setCondition} state={'selectJob'} setState={setValue} getValues={getValues} data={taskData} register={register} validation={true} errors={errors}
              />
            </Grid>
            
          </Grid>
          <Grid container>
            <Grid md={6} item >
              <Button onClick={() => navigate('/job/create')} variant="contained" sx={{ marginTop: '20px' }}>Create Job</Button>
            </Grid>
          </Grid>
          {
            condition === '' ? '' : <> 
            <Grid container direction={"row"} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
              <Grid md={6} item xs={12}>
              <FormLabel><>Name<span style={{ color: "red" }} >*</span></></FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  name='name'
                  {...register('name', {
                    required: '**Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid md={6} item xs={12}>
              <FormLabel><>Client Location</></FormLabel>
                <Dropdown state={'clientLocation'} setValue={setValue} getValues={getValues} data={clienLocation} 
                />
              </Grid>
                <Grid md={6} item xs={12}>
              <FormLabel><>Salesperson / Representative</></FormLabel>
                  <Dropdown state={'SalesPerson'} setValue={setValue} getValues={getValues} data={salesPerson} 
                  />
                </Grid>
                <Grid md={6} item xs={12}>
                <FormLabel><>Office<span style={{ color: "red" }} >*</span></></FormLabel>
                <Dropdown state={'Office'} setValue={setValue} getValues={getValues} data={office} 
                  />
                </Grid>
                <Grid md={6} item xs={12}>
                  <FormLabel><>Estimate Hours</></FormLabel>
                  <TextField
                    fullWidth
                    type="text"
                    name='estimate_hour'
                    {...register(`estimate_hour`)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid md={6} item xs={12}>{''}</Grid>
                </Grid>
              <Grid >
                <Card sx={{ marginBlock: 2 }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Location" {...a11yProps(0)} />
                      <Tab label="Contact" {...a11yProps(1)} />
                      <Tab label="Additional Fields" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <Grid mt={0}>
                      <Grid>
                        <Typography variant="h5" component="h2">
                          Location
                        </Typography>
                      </Grid>
                      <Grid mt={0}>
                        {
                          location.map((ele, i) => (
                            <>
                              <Grid sx={{ display: 'flex' }} key={i}>
                                <Grid>
                                  <CancelIcon variant="contained" onClick={() => handleLocationDelete(i)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px', marginRight: '10px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                    {""}
                                  </CancelIcon>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Grid sx={{ marginLeft: '20px' }} container direction={"row"} spacing={5}>
                                <Grid container direction={"row"}  rowSpacing={2} mt={5} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                    <Grid xs={12} md={6} item>
                                    <FormLabel><>Location Name</></FormLabel>
                                      <TextField
                                        fullWidth
                                        type="text"
                                        name='location_name'
                                        {...register(`locationItem.${i}.location_name`)}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid xs={12} md={6} item >
                                        <FormLabel><>Munciple Address</></FormLabel>
                                      <TextField
                                        fullWidth
                                        type="text"
                                        name='munciple_address'
                                        {...register(`locationItem.${i}.munciple_address`)}
                                        InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={6} item >
                                        <FormLabel><>State / Province</></FormLabel>
                                      <Dropdown getValues={getValues} key={i} state={`locationItem.${i}.province`} setValue={setValue} data={Province} 
                                      />
                                    </Grid>
                                    <Grid xs={12} md={6} item >
                                      <FormLabel><>City</></FormLabel>
                                      <Dropdown getValues={getValues} key={i} state={`locationItem.${i}.City`} setValue={setValue} data={city} 
                                      />
                                    </Grid>
                                    <Grid xs={12} md={12} item >
                                    <NestedArray nestIndex={i} {...{ control, register, setValue, getValues }} />
                                    </Grid> 
                                      <Grid md={12} xs={12} mt={2} item>
                                      <Typography variant='subtitle1'>GPS Cordinates </Typography>
                                    </Grid>
                                      <Grid xs={12} md={6} item >
                                      <FormLabel><>Latitude</></FormLabel>
                                        <TextField
                                          fullWidth
                                          type="text"
                                          name='latitude'
                                          {...register(`locationItem.${i}.latitude`)}
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      </Grid> 
                                      <Grid xs={12} md={6} item >
                                      <FormLabel><>Longitude</></FormLabel>
                                        <TextField
                                          fullWidth
                                          type="text"
                                          name='longitude'
                                          {...register(`locationItem.${i}.longitude`)}
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      </Grid>
                                      </Grid>
                                    <Grid mt={2}>
                                      <FormGroup>
                                        <FormControlLabel control={
                                          <Checkbox
                                            {...register(`locationItem.${i}.map_display`)}
                                          />} label="Map Display" />
                                      </FormGroup>
                                    </Grid>
                                  </Grid>
                              </Grid>
                              <Divider sx={{ marginTop: '15px' }} />
                            </>
                          ))
                        }
                        <Button variant="contained" sx={{ marginTop: '20px' }} onClick={handlelocationButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Location</Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Grid mt={0}>
                      <Grid>
                        <Typography variant="h5" component="h2">
                          Contact
                        </Typography>
                      </Grid>
                      {
                        contact.map((element, index) => (
                          <>
                            <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                              <Grid sx={{ marginRight: '15px' }}>
                                <HighlightOffIcon variant="contained" onClick={() => handleContactDelete(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                  {""}
                                </HighlightOffIcon>
                              </Grid>
                              <Divider orientation="vertical" variant="middle" flexItem />
                              <Grid sx={{ marginLeft: '20px' }} container direction={"row"} spacing={5}>
                              <Grid container direction={"row"} mt={5} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                <Grid xs={12} item  md={6}>
                                <FormLabel><>Contact</></FormLabel>
                                  <Dropdown getValues={getValues} state={'contact'} setValue={setValue} data={contactData}  startIcon={<FmdGoodIcon />}
                                  />
                                </Grid>
                                <Grid xs={12} item  md={6}>{''}</Grid>
                                <Grid xs={12} md={6} item>
                                  <FormGroup>
                                    <FormControlLabel control={
                                      <Checkbox
                                        {...register(`contactItem.${index}.DWR_invoice`)}
                                      />} label="Add to DWR/Invoice" />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                              </Grid>
                            </Grid>
                            <Divider sx={{ marginTop: '15px' }} />

                          </>
                        ))
                      }
                      <Grid>
                        <Button variant="contained" sx={{ marginTop: '20px' }} onClick={handleContactButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Contact</Button>
                      </Grid>

                    </Grid>
                  </TabPanel>

                  <TabPanel value={value} index={2}>
                    <Grid>
                      <Grid mt={0}>
                        <Typography variant='h5'> Additional Fields</Typography>
                      </Grid>
                      {
                        additional.map((element, index) => (
                          <>
                            <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                              <Grid sx={{ marginRight: '15px' }}>
                                <HighlightOffIcon variant="contained" onClick={() => handleAdditionalDelete(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                  {""}
                                </HighlightOffIcon>
                              </Grid>
                              <Divider orientation="vertical" variant="middle" flexItem />
                              <Grid sx={{ marginLeft: '20px' }} container direction={"row"} spacing={5}>
                              <Grid container direction={"row"} mt={5} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                <Grid xs={12} md={6} item >
                                <FormLabel><>Field Name</></FormLabel>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    name='field_name_additional'
                                    {...register(`additionalItem.${index}.field_name_additional`)}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                                {/* <Grid xs={12} md={6} item  >{''}</Grid> */}
                                <Grid xs={12} md={6} item >
                                <FormLabel><>Field Value</></FormLabel>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    name='field_value_additional'
                                    {...register(`additionalItem.${index}.field_value_additional`)}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                              </Grid>
                              </Grid>
                            </Grid>
                            <Divider sx={{ marginTop: '20px' }} />
                          </>
                        ))
                      }
                      <Grid>
                        <Button variant="contained" sx={{ marginTop: '20px' }} onClick={handleAdditionalButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Additional Fields</Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Card>
              </Grid>
              <Grid container direction={"row"} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                <Grid item xs={12} md={6} >
                <FormLabel><>ProjectManager <span style={{ color: "red" }} >*</span></></FormLabel>
                  <Dropdown state={'ProjectManager'} setValue={setValue} getValues={getValues} data={projectManager} register={register} validation={true} errors={errors} 
                  />

                </Grid>
                <Grid item xs={12} md={6}>
                <FormLabel><>Task Category</></FormLabel>
                  <Dropdown state={'TaskCategory'} setValue={setValue} getValues={getValues} data={jobCategory} 
                  />
                </Grid>
            
            
                <Grid item xs={12} md={6}>
                <FormLabel><>Task Scope</></FormLabel>
                  <Dropdown state={'TaskScope'} setValue={setValue} getValues={getValues} data={jobScope}
                  />
                </Grid>
                <Grid  item xs={12} md={6}>
                <FormLabel><>GL Code Prifix <span style={{ color: "red" }} >*</span></></FormLabel>
                  <TextField
                    fullWidth
                    type="text"
                    name='gl_code'
                    {...register('gl_code', {
                      required: '*Field is required and needs to be a text',
                    })}
                    error={Boolean(errors.gl_code)}
                    helperText={errors.gl_code?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                </Grid>
              
              <Grid mt={3}>
                <Typography variant='h5' component='h6'>DWR Billing Type<span style={{ color: "red" }} >*</span></Typography>
                <Grid sx={{ display: 'flex' }} mt={2}>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment1}
                    exclusive
                    onChange={(e, val) => setAlignment1(val)}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Non-Chargeble">Non-Chargeble</ToggleButton>
                    <ToggleButton value="Fixed Price">Fixed Price</ToggleButton>
                    <ToggleButton value="Time & Materials">Time & Materials</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid mt={3}>
                <Grid>
                  <Typography variant="h5" component="h2">
                    Billing Line Items
                  </Typography>
                </Grid>
                <Grid mt={2}>
                  <Card>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={valueBiling} onChange={handleChangeBilling} aria-label="basic tabs example">
                        <Tab label="Labour Costs" {...a11yProps(0)} />
                        <Tab label="Equipment and Materials" {...a11yProps(1)} />
                        <Tab label="Fixed Price" {...a11yProps(2)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={valueBiling} index={0}>
                      <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
                        Labour Costs
                      </Typography>
                      {
                        labour.map((item, i) => (
                          <>
                            <Grid key={i}>
                              <Grid sx={{ display: "flex" }}>
                                <Grid sx={{ marginRight: '10px' }} >
                                  <CancelIcon key={i} onClick={() => handleLabourDelete(i)} sx={{ fontSize: '42px', color: 'red' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                    {""}
                                  </CancelIcon>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Grid sx={{ marginLeft: '20px' }} direction={"row"} container mt={4} spacing={5}>
                                <Grid container direction={"row"} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                    <Grid  item xs={12} md={6}>
                                    <FormLabel><>Cost Item<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <Dropdown state={'CostItem'} setValue={setValue} getValues={getValues} data={CostItem} startIcon={<FmdGoodIcon />}
                                        register={register} validation={true} errors={errors}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <FormLabel><>Name<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`labourItem.${i}.name`, {
                                          required: '*Field is required and needs to be a text',
                                        })}
                                        error={Boolean(errors.labourItem ? errors.labourItem[i].name : null)}
                                        helperText={Boolean(errors.labourItem ? errors.labourItem[i].name : false) ? '*Field is required and needs to be a text' : null}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid  item xs={12} md={6}>
                                    <FormLabel><>Unit<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`labourItem.${i}.unit`, {
                                          required: '*Field is required and needs to be a text',
                                        })}
                                        error={Boolean(errors.labourItem ? errors.labourItem[i].unit : null)}
                                        helperText={Boolean(errors.labourItem ? errors.labourItem[i].unit : false) ? '*Field is required and needs to be a text' : null}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid  item xs={12} md={6}>
                                    <FormLabel><>Unit Cost<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`labourItem.${i}.unitCost`, {
                                          required: '*Field is required and needs to be a text',
                                        })}
                                        error={Boolean(errors.labourItem ? errors.labourItem[i].unitCost : null)}
                                        helperText={Boolean(errors.labourItem ? errors.labourItem[i].unitCost : false) ? '*Field is required and needs to be a text' : null}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid  item xs={12} md={6} >
                                    <FormLabel><>Hourly Cost</></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`labourItem.${i}.hourlyCost`)}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid  item xs={12} md={6} >
                                    <FormLabel><>Budget Quantity</></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`labourItem.${i}.budget_quantity`)}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    </Grid>
                                  <Grid mt={2} container>
                                    <Grid item>
                                      <FormGroup>
                                        <FormControlLabel control={
                                          <Checkbox
                                            {...register(`labourItem.${i}.text_exemptLabour`)}
                                          />} label="Text Exempt" />
                                      </FormGroup>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
                          </>
                        ))
                      }
                      <Grid>
                        <Button variant="contained" sx={{ marginTop: '0px' }} onClick={handleAddLabour} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Labour Cost Item</Button>
                      </Grid>
                      <Grid mt={3}>
                        <Grid>
                          <Typography variant='h6'> Additional Fields</Typography>
                        </Grid>
                        {
                          additionalLabour.map((element, index) => (
                            <>
                              <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                                <Grid sx={{ marginRight: '15px' }}>
                                  <HighlightOffIcon variant="contained" onClick={() => handleAdditionalLabourDelete(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                    {""}
                                  </HighlightOffIcon>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Grid sx={{ marginLeft: '20px' }} direction={"row"} container mt={4} spacing={5}>
                                <Grid container direction={"row"}  rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                  <Grid xs={12} item md={6} >
                                  <FormLabel><>Field Name</></FormLabel>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      name='field_name_labour'
                                      {...register(`additionalLabourItem.${index}.field_name_labour`)}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid xs={12} item md={6}>
                                  <FormLabel><>Field Value</></FormLabel>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      label=""
                                      name='field_value_labour'
                                      {...register(`additionalLabourItem.${index}.field_value_labour`)}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Divider sx={{ marginTop: '15px' }} />
                                </Grid>
                                </Grid>
                              </Grid>
                            </>
                          ))
                        }
                        <Grid>
                          <Button variant="contained" sx={{  marginTop: '25px' }} onClick={handleAdditionalLabourButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Additional Fields</Button>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={valueBiling} index={1}>
                      <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
                        Equipment and Materials
                      </Typography>
                      {
                        material.map((item, i) => (
                          <>
                            <Grid key={i}>
                              <Grid sx={{ display: "flex" }}>
                                <Grid sx={{ marginRight: '10px' }} >
                                  <CancelIcon key={i} onClick={() => handleMaterialDelete(i)} sx={{ fontSize: '42px', color: 'red' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                    {""}
                                  </CancelIcon>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Grid sx={{ marginLeft: '20px' }} direction={"row"} container mt={4} spacing={5}>
                                <Grid container direction={"row"}  rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                    <Grid xs={12} md={6} item>
                                    <FormLabel><>Cost Item<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <Dropdown state={'CostItem'} setValue={setValue} getValues={getValues} data={CostItem} startIcon={<FmdGoodIcon />}
                                        register={register} validation={true} errors={errors} />
                                    </Grid>
                                    <Grid xs={12} md={6} item>
                                    <FormLabel><>Name<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`materialItem.${i}.name`, {
                                          required: '*Field is required and needs to be a text',
                                        })}
                                        error={Boolean(errors.materialItem ? errors.materialItem[i].name : null)}
                                        helperText={Boolean(errors.materialItem ? errors.materialItem[i].name : false) ? '*Field is required and needs to be a text' : null}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid xs={12} md={6} item>
                                    <FormLabel><>Unit<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`materialItem.${i}.unit`, {
                                          required: '*Field is required and needs to be a text',
                                        })}
                                        error={Boolean(errors.materialItem ? errors.materialItem[i].unit : null)}
                                        helperText={Boolean(errors.materialItem ? errors.materialItem[i].unit : false) ? '*Field is required and needs to be a text' : null}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid xs={12} md={6} item>
                                    <FormLabel><>Unit Cost<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`materialItem.${i}.unitCost`, {
                                          required: '*Field is required and needs to be a text',
                                        })}
                                        error={Boolean(errors.materialItem ? errors.materialItem[i].unitCost : null)}
                                        helperText={Boolean(errors.materialItem ? errors.materialItem[i].unitCost : false) ? '*Field is required and needs to be a text' : null}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid xs={12} md={6} item>
                                    <FormLabel><>Budget Quantity</></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`materialItem.${i}.budget_quantity`)}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    
                                  </Grid>
                                  <Grid mt={2} container>
                                    <Grid item>
                                      <FormGroup>
                                        <FormControlLabel control={
                                          <Checkbox
                                            {...register(`materialItem.${i}.text_exemptLabour`)}
                                          />} label="Text Exempt" />
                                      </FormGroup>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              </Grid>
                            </Grid>
                            <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
                          </>
                        ))
                      }
                      <Grid mt={2}>
                        <Button variant="contained"  onClick={handleAddMaterial} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Equipment and Materials Item</Button>
                      </Grid>
                      <Grid mt={3}>
                        <Grid>
                          <Typography variant='h6'> Additional Fields</Typography>
                        </Grid>
                        {
                          additionalEquipment.map((element, index) => (
                            <>
                              <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                                <Grid sx={{ marginRight: '15px' }}>
                                  <HighlightOffIcon variant="contained" onClick={() => handleAdditionalEquipmentDelete(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                    {""}
                                  </HighlightOffIcon>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Grid sx={{ marginLeft: '20px' }} direction={"row"} container mt={4} spacing={5}>
                                <Grid container direction={"row"}  rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                  <Grid xs={12} item md={6} >
                                  <FormLabel><>Field Name</></FormLabel>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      name='field_name_labour'
                                      {...register(`additionalEquipmentItem.${index}.field_name_equipment`)}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Grid xs={12} item md={6}>
                                  <FormLabel><>Field Value</></FormLabel>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      name='field_value_labour'
                                      {...register(`additionalEquipmentItem.${index}.field_value_equipment`)}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  <Divider sx={{ marginTop: '15px' }} />
                                </Grid>
                              </Grid>
                              </Grid>
                            </>
                          ))
                        }
                        <Grid>
                          <Button variant="contained" sx={{  marginTop: '25px' }} onClick={handleAdditionalEquipmentButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Additional Fields</Button>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={valueBiling} index={2}>
                      <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
                        Fixed Price
                      </Typography>
                      {
                        fix.map((item, i) => (
                          <>
                            <Grid key={i}>
                              <Grid sx={{ display: "flex" }}>
                                <Grid sx={{ marginRight: '10px' }} >
                                  <CancelIcon key={i} onClick={() => handleFixDelete(i)} sx={{ fontSize: '42px', color: 'red' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                    {""}
                                  </CancelIcon>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Grid sx={{ marginLeft: '20px' }} direction={"row"} container mt={4} spacing={5}>
                                <Grid container direction={"row"}  rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                    <Grid xs={12} item md={6}>
                                    <FormLabel><>Cost Item<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <Dropdown state={'CostItem'} setValue={setValue} getValues={getValues} data={CostItem} startIcon={<FmdGoodIcon />}
                                        register={register} validation={true} errors={errors}/>
                                    </Grid>
                                    <Grid xs={12} item md={6}>
                                    <FormLabel><>Name<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`fixItem.${i}.name`, {
                                          required: '*Field is required and needs to be a text',
                                        })}
                                        error={Boolean(errors.fixItem ? errors.fixItem[i].name : null)}
                                        helperText={Boolean(errors.fixItem ? errors.fixItem[i].name : false) ? '*Field is required and needs to be a text' : null}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid xs={12} item md={6}>
                                    <FormLabel><>Unit<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`fixItem.${i}.unit`, {
                                          required: '*Field is required and needs to be a text',
                                        })}
                                        error={Boolean(errors.fixItem ? errors.fixItem[i].unit : null)}
                                        helperText={Boolean(errors.fixItem ? errors.fixItem[i].unit : false) ? '*Field is required and needs to be a text' : null}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid xs={12} item md={6}>
                                    <FormLabel><>Unit Cost<span style={{ color: "red" }} >*</span></></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`fixItem.${i}.unitCost`, {
                                          required: '*Field is required and needs to be a text',
                                        })}
                                        error={Boolean(errors.fixItem ? errors.fixItem[i].unitCost : null)}
                                        helperText={Boolean(errors.fixItem ? errors.fixItem[i].unitCost : false) ? '*Field is required and needs to be a text' : null}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid xs={12} item md={6} >
                                    <FormLabel><>Budget Quantity</></FormLabel>
                                      <TextField
                                        sx={{ width: "100%" }}
                                        type="text"
                                        {...register(`fixItem.${i}.budget_quantity`)}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                  <Grid mt={2} container>
                                    <Grid item>
                                      <FormGroup>
                                        <FormControlLabel control={
                                          <Checkbox
                                            {...register(`fixItem.${i}.text_exemptLabour`)}
                                          />} label="Text Exempt" />
                                      </FormGroup>
                                    </Grid>
                                  </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
                          </>
                        ))
                      }
                      <Grid mt={2}>
                        <Button variant="contained"  onClick={handleAddFix} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Fixed Price Item</Button>
                      </Grid>
                    </TabPanel>
                  </Card>
                </Grid>
              </Grid>
             
              <Grid container direction={"row"} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                <Grid item xs={12} md={6}>
                <FormLabel><>Status</></FormLabel>
                  <Dropdown state={'Status'} setValue={setValue} getValues={getValues} data={status} 
                  />
                </Grid>
                <Grid item xs={12} md={6} >
                <FormLabel><>Remarks</></FormLabel>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    onChange={(e, val) => {
                      settextAreaCount(e.target.value.length)
                      setValue("remark", e.target.value)
                    }}
                    inputProps={{ maxLength: 300 }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Grid sx={{ fontSize: "12px", textAlign: "end" }} > {textAreaCount} / 300</Grid>
                </Grid>
               </Grid>
              </>
          }
        </Grid>
        <Grid sx={{ display: "flex" }} >
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} style={{ width: 300 }}>
            Submit
          </LoadingButton>
          {params && (
            <Button
              variant="contained"
              size="large"
              color="error"
              style={{ width: 300, marginLeft: "20px" }}

              onClick={() => {
                navigate('/task/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form >
  );
}

TaskCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func
};
