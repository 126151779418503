import React, { useEffect,useCallback,useState,useContext } from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  Stack,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  Chip,
  FormHelperText,
  Backdrop, CircularProgress,
  TableCell, TableRow, TableFooter,
} from '@mui/material';

import { FetchContext } from 'src/context/FetchContext';
import { LoadingButton } from '@mui/lab';
import PropTypes, { element } from 'prop-types';
import { createTheme } from '@mui/material';
import { API_ROUTES } from 'src/services/constant';
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";

const { PRIVATE_ROUTE } = API_ROUTES;

export default function InvoiceCreateForm({
  initialData,
  register,
  errors,
  getValues,
  setValue,
  handleSubmit,
  onSubmit,
  params,
  loading,
}) {
  const [condition, setCondition] = React.useState(params ? getValues('selectClient') : '');
  const [personName, setPersonName] = React.useState('');
  const [showloading, setshowLoading] = useState(false);
  const [taskData, settaskData] = React.useState([]);
  const { authAxios } = useContext(FetchContext);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [hours, setHours] = useState(0);
  const [cost, setCost] = useState(0);
  
  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: 'red' },
        },
      },
    },
  });

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  let clientState = 'selectClient';
  const stateData = clientState.split('.');
  let checked = "checked";
  let errorMassage = false;
  if (!checked) {
      if (errors !== null && errors[`${stateData[0]}`] && errors[`${stateData[0]}`][`${stateData[1]}`] && errors[`${stateData[0]}`][`${stateData[1]}`][`${stateData[2]}`]) {
      errorMassage = true;
      }
  } else if (errors !== null && errors[`${stateData[0]}`]) {
      errorMassage = true;
  }

  const handleChangeField = async (event) => {
    setshowLoading(true);
    const {target: { value },} = event;
    setPersonName(typeof value === 'string' ? value?.split(',')[0] : value);
    setValue('selectClient', value?.split(',')[0]);
    setValue('selectClient_id', value?.split(',')[1].trim());
    setPersonName(getValues('selectClient'));

    console.log("client id -->", getValues('selectClient_id'));

    const clientId = getValues('selectClient_id');
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_TASK_DATA}/${clientId}`);
    
    setCondition(value?.split(',')[0]);
    settaskData(data?.data?.map((item) => {
      setValue("selectClient_referenceno", item?.client_id?.qb_customer_id);
      const getlbHoursArr = item?.billing_line_items?.labour_item?.labour_cost_items?.map((i) => parseFloat(i.estimated_hour));
      const getHours = getlbHoursArr?.reduce((partialSum, a) => partialSum + a, 0);
      
      const getlbCost = item?.billing_line_items?.labour_item?.labour_cost_items?.map((i) => i.estimated_hour * i.unitCost);
      const getTotallbCost = getlbCost?.reduce((partialSum, a) => partialSum + a, 0);

      const getEqupCost = item?.billing_line_items?.equipment_item?.equipment_cost_items?.map((i) => parseFloat(i.unitCost));
      const getTotalEqupCost = getEqupCost?.reduce((partialSum, a) => partialSum + a, 0);

      const getfixCost = item?.billing_line_items?.fixed_item?.map((i) => parseFloat(i.unitCost));
      const getTotalfixCost = getfixCost?.reduce((partialSum, a) => partialSum + a, 0);

      const getTotalCost = parseFloat(getTotallbCost) + parseFloat(getTotalEqupCost) + parseFloat(getTotalfixCost);
      return (
        {
          _id: item._id,
          name: item.name,
          task_number: <Link to={`/task/view/${item._id}`}><b>{item.number_str}</b></Link>,
          hours : getHours,
          cost : getTotalCost
        })
    }));
    setCount(data.total);
    setshowLoading(false);
    
  };

  const onRowSelectionChange = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
    ) => {
      /* console.log("taskData riddhi --->", taskData);
      console.log("allRowsSelected", allRowsSelected);
      console.log("rowsSelected", rowsSelected); */
      const selectedTasks = allRowsSelected.map((item) => taskData[item.dataIndex]);
      console.log("selectedTasks", selectedTasks);
      if (!selectedTasks || !selectedTasks.length) {
        setHours(0);
        setCost(0);
        setValue('totalCost', 0);
        setValue('totalHours', 0);
        setValue('taskIds', []);
      } else {
        const getHours = selectedTasks?.map((i) => parseFloat(i.hours))?.reduce((partialSum, a) => partialSum + a, 0);
        const getCost = selectedTasks?.map((i) => parseFloat(i.cost))?.reduce((partialSum, a) => partialSum + a, 0);

        setHours(getHours);
        setCost(getCost);
        setValue('totalCost', getCost);
        setValue('totalHours', getHours);
        setValue('taskIds', selectedTasks?.map((i) => i._id));
      }
  };

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        draggable: true,
        sort: true,
        filter: false,
        display: false
      }
    },
    {
      name: "task_number",
      label: "Task",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: "name",
      label: "Name",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      }
    },
    {
      name: "hours",
      label: "Hours",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      }
    },
    {
      name: "cost",
      label: "Cost",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      }
    }
  ];

  const options = {
    selectToolbarPlacement: 'none',
    tableId: "HelloWorld",
    rowHover: false,
    jumpToPage: true,
    responsive: "standard",
    print: true,
    filter: false,
    fixedHeader: true,
    search : false,
    count: count,
    selectableRows: true,
    onRowSelectionChange : onRowSelectionChange,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',

        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: "1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)",
        size: 'small',
      };
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setPage(tableState.page);
      }
    },
    customTableBodyFooterRender: (opts) => {
      return (
        <>
          {taskData.length > 0 && (
            <TableFooter>
              <TableRow>
                {opts.columns.map((col, index) => {
                  //  console.log("col ----->", col);
                    if (col.name === "_id") {
                      return (
                        <TableCell key={index}>
                          {}
                        </TableCell>
                      );
                    }
                    
                    if (col.name === "task_number") {
                      return (
                        <TableCell key={index}>
                          Total
                        </TableCell>
                      );
                    }
                    if (col.name === "name") {
                      return (
                        <TableCell key={index}>
                          {}
                        </TableCell>
                      );
                    } 
                    if (col.name === "hours") {
                      return (
                        <TableCell key={index}>
                          {hours}
                        </TableCell>
                      );
                    }
                    if (col.name === "cost") {
                      return (
                        <TableCell key={index}>
                          {cost}
                        </TableCell>
                      );
                    }
                  
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    }
  };

  return (
    <>
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} padding={3}>
        <Typography variant="h4" component="h2">
          Generate Invoice
        </Typography>
        <Grid>
          <Grid container spacing={5}>
            <Grid md={6} xs={12} item>
              <FormLabel>
                Select Client<span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <FormControl fullWidth>
                  <Select
                  fullWidth
                  className={errorMassage ? 'error-input-border' : ''}
                  onChange={(e) => {
                      handleChangeField(e);
                  }}
                  value={getValues('selectClient') || ''}
                  defaultValue={getValues('selectClient') || ''}
                  rules={{ required: true }}
                  inputProps={
                      <>
                      <TextField
                      {...register('selectClient', {
                          required: '**Field is required',
                      })}
                      error={Boolean(errorMassage)}
                      helperText={errorMassage ? '*Field is required' : null}
                      />
                      </>
                  }
                  renderValue={(selected) => {
                      return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
                          <Chip
          
                          label={selected?.split(',')[0]}
                          sx={{ backgroundColor: '#65C8D0', color: 'white' }}
                          />
                      </Box>
                      );
                  }}
                  >
                  {initialData?.client?.map((name) => {
                      return (
                      <MenuItem
                          key={name.id}
                          selected={personName === name.label}
                          value={`${name.label},${name.id}`}
                          style={getStyles(name.label, personName, theme)}
                      >
                          {name.label === '' ? 'no data found' : name.label}
                      </MenuItem>
                      );
                  })}
                  </Select>
                  <FormHelperText className="error_msg">
                    {errorMassage ? '**Field is required ' : ''}
                  </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          {showloading ? (
            <div>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showloading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          ) : (
            ''
          )}
          {condition && taskData &&
          <>
            <Typography my={'30px'} variant="h6" color="initial">
              Tasks:
            </Typography>
            <MUIDataTable
              options={options}
              columns={columns}
              data={taskData}
            />
          </>
          }
        </Grid>

        <Grid sx={{ display: 'flex' }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            style={{ width: 300 }}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Stack>
    </form>
    </>
  );
}

InvoiceCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func,
};