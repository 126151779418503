

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import CostItemCreateForm from './CostItemCreateForm';
import { Backdrop, CircularProgress } from '@mui/material';

const { PRIVATE_ROUTE } = API_ROUTES;

function CostItemForm() {

  const [categorydata, setcategorydata] = useState([]);
  const [loading, setloading] = useState(true);

  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    control
  } = useForm(!id ? {
    defaultValues: {
      name: '',
      unit: '',
      unit_cost: '',
      hourly_cost: '',
      gl_code: '',
      category: '',
      sub_category: '',
      remark: "",
      tax_exempt: false,
      active: false,
    }
  }
    : {
      defaultValues: {
        name: '',
        unit: '',
        unit_cost: '',
        hourly_cost: '',
        gl_code: '',
        category: '',
        sub_category: '',
        remark: "",
        tax_exempt: false,
        active: false,
      }
    }
  );


  const getCostiteamFormById = useCallback(async () => {
    setloading(true)
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_COSTITEAM_BY_ID}/${id}`
    );
    setloading(false)
    setValue('name', data?.data.name);
    setValue('unit', data?.data.unit);
    setValue('unit_cost', data?.data.unit_cost);
    setValue('hourly_cost', data?.data.hourly_cost);
    setValue('gl_code', data?.data.gl_code);
    setValue('category', data?.data.category);
    setValue('sub_category', data?.data.sub_category);
    setValue('tax_exempt', data?.data.tax_exempt);
    setValue('active', data?.data.active);
    setValue('remark', data?.data.remark);
  }, [authAxios, id, setValue]);
  const getcategorydata = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_CATEGORY}`
    );
    setcategorydata(data.data)
  },[authAxios,id])
  useEffect(() => {
    getcategorydata()
    if (id) {
      getCostiteamFormById();
    }
  }, [getCostiteamFormById, id, getcategorydata]);
  const onSubmit = async (data) => {
    if (id) {
      const response = await authAxios.post(
        `${PRIVATE_ROUTE?.EDIT_COSTITEAM}${id}`,
        data
      );
      toast.success(response.data.massage);
      navigate('/master/costitem/list');
    } else {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_COSTITEAM, data);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/master/costitem/list');
      }
      else {
        toast.error(response.data.massage);
      }
    }
    setValue('name', '');
    setValue('unit', '');
    setValue('unit_cost', '');
    setValue('hourly_cost', '');
    setValue('gl_code', '');
    setValue('category', '');
    setValue('sub_category', '');
    setValue('tax_exempt', false);
    setValue('active', false);
    setValue('remark', '');
  };
  return (
    <>
    { id ? 
      loading && (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}

        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  
:
""
}
  <CostItemCreateForm
    getValues={getValues}
    setValue={setValue}
    register={register}
    categorydata={categorydata}
    handleSubmit={handleSubmit}
    errors={errors}
    onSubmit={onSubmit}
    params={id}
    loading={isSubmitting}
    isEdit={id ? "true" : "false"}
    control={control}
  />

</>
  );
}

export default CostItemForm;
