
import React, { useCallback, useContext, useEffect } from 'react';
import { useParams, useNavigate, json } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import ManagerJobCreateForm from './ManagerJobCreateForm';

const { PRIVATE_ROUTE } = API_ROUTES;

function ManagerJobForm() {

  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
 
 
  const [alignment1, setAlignment1] = React.useState('');
  const [alignment2, setAlignment2] = React.useState('');
  const [alignment3, setAlignment3] = React.useState('');
  const [orderDate, setOrderDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,

    getValues,
    control
  } = useForm({
  //   "client_number": "",
  //   "company_name": '',
  //   "client_select": "",
  //   "client_location": '',
  //   'company_address': "",
  //   "website": '',
  //   "status": '',
  //   'location': [
  //     {
  //       "location_name": '',
  //       "munciple_address": '',
  //       "state_province": "",
  //       "city": '',
  //       'legal_address': [{
  //         'lot': '',
  //         "block": '',
  //         "plan": '',
  //         "LSD": "",
  //         "section": '',
  //         "township": '',
  //         "range": '',
  //         "meridian": "",

  //       }],
  //       'latitude': '',
  //       'longitude': '',
  //       'map_display': '',

  //     },
  //   ],
  //   'contact': [
  //     {
  //       "contact": "",
  //       "DWR_invoice": '',
  //     },
  //   ],
  //   "project_manager": '',
  //   "sales_person": '',
  //   "office": "",
  //   "client_project": '',
  //   "PO": '',
  //   "po_amount": '',
  //   "subcontract": "",
  //   'DWR_billing': '',
  //   "invoice_type": "",
  //   'invoice_line': '',
  //   "gl_code": '',
  //   "job_categories": '',
  //   "job_scope": "",
  //   "job_scope_details": '',
  //   "order_date": '',
  //   "Due_date": '',
  //   'taxes': [
  //     {
  //       "taxes_name": "",
  //       "tax_percentage": '',
  //     },
  //   ],
  //   'fees': [
  //     {
  //       "fees_name": "",
  //       "fees_percentage": '',
  //     },
  //   ],
  //   'additional_fields': [
  //     {
  //       "field_name": "",
  //       "field_value": '',
  //     },
  //   ],
  //   "note": '',

});

  const getJobFormById = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_JOB_BY_ID}${id}`
    );
    setValue('client_number', data?.data.client_number);
    setValue('company_name', data?.data.company_name);
    setValue('company_address', data?.data.company_address);
    setValue('website', data?.data.website);
    setValue('location_name', data?.data.location_name);
    setValue('munciple_address', data?.data.munciple_address);
    setValue('lot', data?.data.lot);
    setValue('block', data?.data.block);
    setValue('plan', data?.data.plan);
    setValue('section', data?.data.section);
    setValue('township', data?.data.township);
    setValue('range', data?.data.range);
    setValue('meridian', data?.data.meridian);
    setValue('latitude', data?.data.latitude);
    setValue('longitude', data?.data.longitude);
    setValue('map_display', data?.data.map_display);
    setValue('DWR_invoice', data?.data.DWR_invoice);
    setValue('client_project', data?.data.client_project);
    setValue('PO', data?.data.PO);
    setValue('po_amount', data?.data.po_amount);
    setValue('subcontract', data?.data.subcontract);
    setValue('DWR_billing', data?.data.DWR_billing);
    setValue('invoice_type', data?.data.invoice_type);
    setValue('invoice_line', data?.data.invoice_line);
    setValue('gl_code', data?.data.gl_code);
    setValue('job_categories', data?.data.job_categories);
    setValue('job_scope', data?.data.job_scope);
    setValue('job_scope_details', data?.data.job_scope_details);
    setValue('order_date', data?.data.order_date);
    setValue('Due_date', data?.data.Due_date);
    setValue('tax_percentage', data?.data.tax_percentage);
    setValue('fees_percentage', data?.data.fees_percentage);
    setValue('field_name', data?.data.field_name);
    setValue('field_value', data?.data.field_value);
    setValue('active', data?.data.active);
    setValue('note', data?.data.note);
    
  }, [authAxios, id, setValue]);
  useEffect(() => {
    if (id) {
      getJobFormById();
    }
  }, [getJobFormById, id]);

  const onSubmit = async (data) => {
    
    
    if (id) {
      const response = await authAxios.post(
        `${PRIVATE_ROUTE?.EDIT_JOB}${id}`,
        data
        );
        toast.success(response.data.massage);
        navigate('/manager/job/list');

      } else {
        const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_JOB, data);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/manager/job/list');
      }
      else {
        toast.error(response.data.massage);
      }
    }
    
    setValue('client_number', '');
    setValue('company_name', '');
    setValue('company_address', '');
    setValue('website', '');
    setValue('location_name', '');
    setValue('munciple_address', '');
    setValue('lot', '');
    setValue('block', '');
    setValue('plan', '');
    setValue('section', '');
    setValue('township', '');
    setValue('range', '');
    setValue('meridian', '');
    setValue('latitude', '');
    setValue('longitude', '');
    setValue('map_display', '');
    setValue('DWR_invoice', '');
    setValue('client_project', '');
    setValue('PO', '');
    setValue('po_amount', '');
    setValue('subcontract', '');
    setValue('DWR_billing', '');
    setValue('invoice_type', '');
    setValue('invoice_line', '');
    setValue('gl_code', '');
    setValue('job_categories', '');
    setValue('job_scope', '');
    setValue('job_scope_details', '');
    setValue('order_date', '');
    setValue('Due_date', '');
    setValue('tax_percentage', '');
    setValue('fees_percentage', ''); 
    setValue('field_name', '');
    setValue('field_value', '');
    setValue('client_select', '');
    setValue('active', '');
    setValue('note', '');
  };
  return (
    <ManagerJobCreateForm
    orderDate = {orderDate}
      getValues = {getValues}
      setOrderDate = {setOrderDate}
      dueDate = {dueDate}
      setDueDate = {setDueDate}
      alignment1={alignment1}
      setAlignment1={setAlignment1}
      alignment2 = {alignment2}
      setAlignment2 = {setAlignment2}
      alignment3 = {alignment3}
      setAlignment3 = {setAlignment3}
      register={register}
      
      handleSubmit={handleSubmit}
      errors={errors}
      onSubmit={onSubmit}
      params={id}
      loading={isSubmitting}
      isEdit={id ? "true" : "false"}
      control={control}
      setValue={setValue}
    />
  );
}

export default ManagerJobForm;
