import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider,
  createTheme,
  Tab,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from 'mui-datatables';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Iconify from '../../../../iconify';
// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function FeeList() {
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);

  const navigate = useNavigate();
  // const handleOpenMenu = (event, id) => {
  //   setOpen(event.currentTarget);
  //   setSelectedRow(id);
  // };

  const getUserdata = (query) => {
    let isMounted = true;
    const getUsers = async () => {
      const { data } = await authAxios.get(
        `${PRIVATE_ROUTE?.GET_FEE}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}`
      );
      if (isMounted) {
        setUsers(
          data.data?.map((item, index) => {
            return {
              _id: item._id,
              name: item.name,
              percentage: item.percentage,
            };
          })
        );
        setCount(data.total);
        setLoading(false);
      }
    };

    getUsers().catch((err) => {
      if (!isMounted) return;
      console.error('failed to fetch data', err);
      setLoading(false);
    });
    return () => {
      isMounted = false;
    };
  };

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'percentage',
      label: 'Percentage',
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true,
      },
    },
    {
      name: '_id',
      label: '_id',
      options: {
        draggable: false,
        // sort:true,
        filter: false,
        display: false,
      },
    },
    {
      name: '',
      lable: 'Action',
      options: {
        filter: true,
        // sort:true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // <IconButton color="inherit" onClick={(e)=>handleOpenMenu(e,tableMeta.rowData[2])}>
            //   <Iconify width={15} icon={'eva:more-vertical-fill'} />
            // </IconButton>
            <div style={{ display: 'flex', gap: '5px' }}>
              <EditIcon onClick={(e) => handleEditRedirect(e, tableMeta.rowData[2])} />
              <DeleteIcon onClick={(e) => handleRowDelete(e, tableMeta.rowData[2])} />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectToolbarPlacement: 'none',
    tableId: 'HelloWorld',
    rowHover: false,
    jumpToPage: true,
    responsive: 'standerd',
    print: true,
    filter: false,
    fixedHeader: true,
    count: count,
    searchOpen: true,
    selectableRows: false,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15)' : 'white',
        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: '1px solid rgba(101, 200, 208,0.15) 200, 208,0.15)',
        size: 'small',
      };
    },
    onSearchChange: (searchQuery) => {
      if (searchQuery !== null && searchQuery.length > 2) {
        getUserdata(searchQuery);
        setLoading(true);
      }
      if (searchQuery === null) {
        getUserdata('');
      }
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setPage(tableState.page);
      }
    },
  };
  const handlejobcreateform = () => {
    navigate('/master/fee/create');
  };
  const handleEditRedirect = (e, id) => {
    navigate(`/master/fee/update/${id}`);
  };
  const handleRowDelete = async (e, id) => {
    if (confirm('Are you sure want to delete?') === true) {
      setLoading(false);
      await authAxios.post(`${PRIVATE_ROUTE?.DELETE_FEE}${id}`);
      setOpen(null);
      setSelectedRow(null);
      setLoading(true);
      getUserdata('');
    }
  };
  // const handleCloseMenu = () => {
  //   setOpen(null);
  //   setSelectedRow(null);
  // };
  useEffect(() => {
    getUserdata('');
  }, [rowsPerPage, page]);

  return (
    <>
      <Grid sx={{ p: 4, pb: 0 }} direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Fee
          </Typography>
          <Button variant="contained" onClick={handlejobcreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Fee
          </Button>
        </Stack>
        <Table>
          <MUIDataTable options={options} columns={columns} data={users} />
          {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleEditRedirect}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={handleRowDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}

          {loading && (
            <div>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}
        </Table>
      </Grid>
    </>
  );
}
