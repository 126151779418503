import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { AuthReducer, initialState } from './reducers/AuthReducer';

const AuthContext = createContext();
const { Provider } = AuthContext;
function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

export { AuthProvider, AuthContext };

AuthProvider.propTypes = {
  children: PropTypes.any
};
