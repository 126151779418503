import publicFetch from '../utils/publicFetch';

class AuthService {
  loginApi = async (loginDto) => {
    try {
      const { data } = await publicFetch.post('/login', loginDto);
      return data;
    } catch (error) {
      if (error) {
        const { response } = this.error;
        const { data } = response;
        return data;
      }
    }
  };

  registerApi = async (registerDto) => {
    try {
      const { data } = await publicFetch.post('auth/signup', registerDto);
      return data;
    } catch (error) {
      if (error) {
        const { response } = this.error;
        const { data } = response;
        return data;
      }
    }
  };
}

export const authService = new AuthService();
