import React from 'react'
import { Helmet } from 'react-helmet-async';

import dayjs from 'dayjs';
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTheme } from '@mui/material/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Grid, Container, Typography, Box, TextField, Stack, Autocomplete, Button, Divider, FormGroup, FormControlLabel, Checkbox, ToggleButtonGroup, ToggleButton, TextareaAutosize, Tabs, Tab, Card } from '@mui/material';
import { DatePicker, LoadingButton, TabPanel } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add'
import PropTypes, { element } from 'prop-types';
import CancelIcon from '@mui/icons-material/Cancel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import dayjs from 'dayjs';
// import TextField from '@mui/material/TextField';
// import Stack from '@mui/material/Stack';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
// components
import Dropdown from 'src/components/customecomponent/Dropdown';
import { useNavigate } from 'react-router-dom/dist';
import { Controller, useFieldArray } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import { useState } from 'react';

export default function ManagerJobCreateForm({
  register,
  errors,
  getValues,
  setValue,
  isEdit,
  alignment2,
  setAlignment2,
  alignment3,
  setAlignment3,
  handleSubmit,

  onSubmit,
  params,
  loading,
  control,

}) {
  const navigate = useNavigate()
  const [dueDate, setDueDate] = useState();
  const [orderDate, setOrderDate] = useState();

  const { fields: labour, remove: removeLabour, insert: insertLabour } = useFieldArray({
    control,
    name: "labourItem",
  });
  const { fields: material, remove: removeMaterial, insert: insertMaterial } = useFieldArray({
    control,
    name: "materialItem",
  });
  const { fields: fix, remove: removeFix, insert: insertFix } = useFieldArray({
    control,
    name: "fixItem",
  });
  const { fields: location, remove: removeLocation, insert: insertLocation } = useFieldArray({
    control,
    name: "locationItem",
  });
  // const { fields: legalAddress, remove: removeLegalAddress, insert: insertLegalAddress } = useFieldArray({
  //   control,
  //   name: `locationItem.${i}.legalAddresstItem`,
  // });
  const { fields: contact, remove: removeContact, insert: insertContact } = useFieldArray({
    control,
    name: "contactItem",
  });
  const { fields: tax, remove: removeTax, insert: insertTax } = useFieldArray({
    control,
    name: "taxItem",
  });
  const { fields: fees, remove: removeFees, insert: insertFees } = useFieldArray({
    control,
    name: "feesItem",
  });
  const { fields: additional, remove: removeAdditional, insert: insertAdditional } = useFieldArray({
    control,
    name: "additionalItem",
  });
  const { fields: additionalLabour, remove: removeAdditionalLabour, insert: insertAdditionalLabour } = useFieldArray({
    control,
    name: "additionalLabourItem",
  });
  const { fields: additionalEquipment, remove: removeAdditionalEquipment, insert: insertAdditionalEquipment } = useFieldArray({
    control,
    name: "additionalEquipmentItem",
  });

  const handleLabourDelete = (i) => {
    removeLabour(i, 1)
  }
  const handleMaterialDelete = (i) => {
    removeMaterial(i, 1)
  }
  const handleFixDelete = (i) => {
    removeFix(i, 1)
  }
  const handleAddLabour = () => {
    insertLabour(labour.length, {
      "costItem": "",
      "name": "",
      "unit": "",
      "unitCost": "",
      "hourlyCost": "",
      "taxExempt": false
    })
  }
  const handleAddMaterial = () => {
    insertMaterial(material.length, {
      "costItem": "",
      "name": "",
      "unit": "",
      "unitCost": "",
      "hourlyCost": "",
      "taxExempt": false
    })
  }
  const handleAddFix = () => {
    insertFix(fix.length, {
      "costItem": "",
      "name": "",
      "unit": "",
      "unitCost": "",
      "hourlyCost": "",
      "taxExempt": false
    })
  }
  const jobData = [{
    label: 'Client Type 1',
    value: 'Client Type 1',
  }, {
    label: 'Client Type 2',
    value: 'Client Type 2',
  }]
  const statusDropDown = [{
    label: 'statusDropDown Type 1',
    value: 'statusDropDown Type 1',
  }, {
    label: 'statusDropDown Type 2',
    value: 'statusDropDown Type 2',
  }]
  const clientLocationDropDown = [{
    label: 'clientLocationDropDown Type 1',
    value: 'clientLocationDropDown Type 1',
  }, {
    label: 'clientLocationDropDown Type 2',
    value: 'clientLocationDropDown Type 2',
  }]
  const city = [{
    label: 'city Type 1',
    value: 'city Type 1',
  }, {
    label: 'city Type 2',
    value: 'city Type 2',
  }]
  const Lsd = [{
    label: 'LSD Type 1',
    value: 'LSD Type 1',
  }, {
    label: 'LSD Type 2',
    value: 'LSD Type 2',
  }]
  const projectManager = [{
    label: 'projectManager Type 1',
    value: 'projectManager Type 1',
  }, {
    label: 'projectManager Type 2',
    value: 'projectManager Type 2',
  }]
  const salesPerson = [{
    label: 'salesPerson Type 1',
    value: 'salesPerson Type 1',
  }, {
    label: 'salesPerson Type 2',
    value: 'salesPerson Type 2',
  }]
  const office = [{
    label: 'office Type 1',
    value: 'office Type 1',
  }, {
    label: 'office Type 2',
    value: 'office Type 2',
  }]
  const taxesName = [{
    label: 'taxesName Type 1',
    value: 'taxesName Type 1',
  }, {
    label: 'taxesName Type 2',
    value: 'taxesName Type 2',
  }]
  const feesName = [{
    label: 'feesName Type 1',
    value: 'feesName Type 1',
  }, {
    label: 'feesName Type 2',
    value: 'feesName Type 2',
  }]
  const contactData = [{
    label: 'contact Type 1',
    value: 'contact Type 1',
  }, {
    label: 'contact Type 2',
    value: 'contact Type 2',
  }]
  const Province = [{
    label: 'Province Type 1',
    value: 'Province Type 1',
  }, {
    label: 'Province Type 2',
    value: 'Province Type 2',
  }]

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleLocationDelete = (index) => {
    removeLocation(index);
  }
  const handleLegalAddressDelete = (index) => {
    removeLegalAddress(index);
  }
  const handleFeesDelete = (index) => {
    removeFees(index);
  }
  const handleAdditionalDelete = (index) => {
    removeAdditional(index);
  }
  const handleAdditionalLabourDelete = (index) => {
    removeAdditionalLabour(index);
  }
  const handleAdditionalEquipmentDelete = (index) => {
    removeAdditionalEquipment(index);
  }
  const handleContactDelete = (index) => {
    removeContact(index);
  }
  const handleTaxesDelete = (index) => {
    removeTax(index);
  }
  const handleTaxesButton = () => {
    insertTax(tax.length, {
      "tax_percentage": "",
    })
  }
  const handleAdditionalButton = () => {
    insertAdditional(additional.length, {
      "field_name": "",
      "field_value": '',
    })
  }
  const handleAdditionalLabourButton = () => {
    insertAdditionalLabour(additionalLabour.length, {
      "field_name": "",
      "field_value": '',
    })
  }
  const handleAdditionalEquipmentButton = () => {
    insertAdditionalEquipment(additionalEquipment.length, {
      "field_name": "",
      "field_value": '',
    })
  }
  const handleFeesButton = () => {
    insertFees(fees.length, {
      "fees_percentage": "",
    })
  }
  const handleContactButton = () => {
    insertContact(contact.length, {
      "DWR_invoice": false,
    })
  }
  const handlelocationButton = () => {
    insertLocation(location.length, {
      "location_name": "",
      "munciple_address": "",
      "latitude": "",
      "longitude": "",
      "map_display": false,
      "legalAddress": []
    })
  }
  const handleLegalAddressButton = (i) => {
    setValue(`locationItem.${i}.legalAddress`, [...getValues(`locationItem.${i}.legalAddress`), {
      "lot": "",
      "block": "",
      "plan": "",
      "section": "",
      "township": "",
      "range": '',
      "meridian": '',
    }])
  }
  const [valueBiling, setValueBiling] = React.useState(0);
  const [value, setValueTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleChangeBilling = (event, newValue) => {
    setValueBiling(newValue);
  };
  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)} >

      <Stack spacing={3} padding={3}>

        <Typography variant="h6" component="h2">
          Information of job
        </Typography>
        <Grid >
          <Grid container direction={"row"} spacing={5} item>
            <Grid md={6} item mt={2}>
              <TextField
                fullWidth
                type="number"
                label="Job Number"
                name='client_number'
                {...register('client_number', {
                  required: '**Field is required and needs to be a text',
                })}
                error={Boolean(errors.client_number)}
                helperText={errors.client_number?.message}
                InputLabelProps={{ shrink: true }}

              />
            </Grid>
            <Grid md={6} item mt={2}>
              <Dropdown state={'clientLocationDropDown'} setState={setValue} getValues={getValues} data={clientLocationDropDown} label='Client Location'
              />
            </Grid>
          </Grid>
          <Grid container direction={"row"} spacing={5} item>
            <Grid md={6} item mt={2}>
              <Dropdown state={'JobData'} setState={setValue} getValues={getValues} data={jobData} label='Client(Selecting a client will allow entry of job details)'
              />
            </Grid>
            <Grid md={6} item mt={2}>
              <TextField
                fullWidth
                type="text"
                label="Client Address"
                name='company_address'
                {...register('company_address', {
                  required: '*Field is required and needs to be a text',
                })}
                error={Boolean(errors.company_address)}
                helperText={errors.company_address?.message}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>
          <Grid mt={1} item>
            <Button variant="contained" sx={{ width: '15vw', marginTop: '20px' }}>Create New Client</Button>
          </Grid>
          <Grid >
            <Card sx={{ marginBlock: 2 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Taxes" {...a11yProps(0)} />
                  <Tab label="Fees" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid mt={0}>
                  <Grid>
                    <Typography variant='h5'> Taxes</Typography>
                  </Grid>
                  {
                    tax.map((element, index) => (
                      <>
                        <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                          <Grid sx={{ marginRight: '15px' }}>
                            <HighlightOffIcon variant="contained" onClick={() => handleTaxesDelete(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                              {""}
                            </HighlightOffIcon>
                          </Grid>
                          <Divider orientation="vertical" variant="middle" flexItem />
                          <Grid sx={{ marginLeft: '15px' }}>

                            <Grid md={6} item mt={2} sx={{ width: '60vw' }}>
                              <Dropdown state={'TaxName'} setState={setValue} getValues={getValues} data={taxesName} label='Taxes Name' startIcon={<FmdGoodIcon />}
                              />
                            </Grid>
                            <Grid md={6} item mt={2}>
                              <TextField
                                fullWidth
                                type="text"
                                label="Tax (Percentage)"
                                name='tax_percentage'
                                {...register(`taxItem.${index}.tax_percentage`)}
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Divider sx={{ marginTop: '15px' }} />
                          </Grid>
                        </Grid>

                      </>
                    ))
                  }
                  <Grid>
                    <Button variant="contained" sx={{ width: '10vw', marginTop: '20px' }} onClick={handleTaxesButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Tax</Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid>
                  <Grid mt={0}>
                    <Typography variant='h5'> Fees</Typography>
                  </Grid>
                  {
                    fees.map((element, index) => (
                      <>
                        <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                          <Grid sx={{ marginRight: '15px' }}>
                            <HighlightOffIcon variant="contained" onClick={() => handleFeesDelete(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                              {""}
                            </HighlightOffIcon>
                          </Grid>
                          <Divider orientation="vertical" variant="middle" flexItem />
                          <Grid sx={{ marginLeft: '15px' }}>

                            <Grid md={6} item mt={2} sx={{ width: '60vw' }}>
                              <Dropdown state={'FeesName'} setState={setValue} getValues={getValues} data={feesName} label='Fees Name' startIcon={<FmdGoodIcon />}
                              />
                            </Grid>
                            <Grid md={6} item mt={2}>
                              <TextField
                                fullWidth
                                type="text"
                                label="Fees (Percentage)"
                                name='fees_percentage'
                                {...register(`feesItem${index}.fees_percentage`)}
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                            <Divider sx={{ marginTop: '15px' }} />
                          </Grid>

                        </Grid>

                      </>
                    ))
                  }
                  <Grid>
                    <Button variant="contained" sx={{ width: '10vw', marginTop: '20px' }} onClick={handleFeesButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Fees</Button>
                  </Grid>
                </Grid>
              </TabPanel>
            </Card>
            <Grid container direction={"row"} spacing={5} item>

              <Grid md={6} item mt={2}>
                <TextField
                  fullWidth
                  type="text"
                  label="Client Project "
                  name='client_project'
                  {...register('client_project', {
                    required: '*Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.client_project)}
                  helperText={errors.client_project?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid md={6} item mt={2}>
                <TextField
                  fullWidth
                  type="text"
                  label="PO"
                  name='PO'
                  {...register('PO', {
                    required: '*Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.PO)}
                  helperText={errors.PO?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Grid container direction={"row"} spacing={5} item>
              <Grid md={6} item mt={2}>
              <TextField
                fullWidth
                type="text"
                label="PO Amount"
                name='po_amount'
                {...register('po_amount', {
                  required: '*Field is required and needs to be a text',
                })}
                error={Boolean(errors.po_amount)}
                helperText={errors.po_amount?.message}
                InputLabelProps={{ shrink: true }}
              />
              </Grid> <Grid md={6} item mt={2}>
                <Dropdown state={'StatusDropDown'} setState={setValue} getValues={getValues} data={statusDropDown} label='Status'
                />
              </Grid>
            </Grid>

          </Grid>
          <Grid container direction={'row'} item spacing={5}>
            <Grid item md={6} mt={2}>
            <TextField
                fullWidth
                type="text"
                label="Sub Contract"
                name='subcontract'
                {...register('subcontract', {
                  required: '*Field is required and needs to be a text',
                })}
                error={Boolean(errors.subcontract)}
                helperText={errors.subcontract?.message}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid md={6} item mt={2}>
              {''}
            </Grid>
          </Grid>
          <Grid container direction={'row'} item spacing={5}>
            <Grid item md={6} mt={5}>
              <Typography variant='h5' component='h6'>Invoice Line Item Type</Typography>
              <Grid sx={{ display: 'flex' }}>

                <ToggleButtonGroup
                  color="primary"
                  value={alignment3}

                  exclusive
                  onChange={(e, val) => setAlignment3(val)}
                  aria-label="Platform"
                // {...register('invoice_line')}
                >
                  <ToggleButton value="Detailed">Detailed</ToggleButton>
                  <ToggleButton value="Semmerized">Semmerized</ToggleButton>

                </ToggleButtonGroup>

              </Grid>
            </Grid>
            <Grid md={6} item mt={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Order Date"
                  onChange={(newValue) => {
                    setValue('orderDate', newValuenewValue["$D"] + '/' + newValue["$M"] + '/' + newValue["$M"]);
                    setOrderDate(newValue);
                  }}
                  value={orderDate}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container direction={'row'} item spacing={5}>
            <Grid item md={6} mt={2}>
              <Typography variant='h5' component='h6'>Invoice Type</Typography>
              <Grid sx={{ display: 'flex' }}>

                <ToggleButtonGroup
                  color="primary"
                  value={alignment2}
                  exclusive
                  onChange={(e, val) => setAlignment2(val)}
                  aria-label="Platform"
                // {...register('invoice_type')}
                >
                  <ToggleButton value="TicketInvoice">Ticket / Invoice</ToggleButton>
                  <ToggleButton value="Invoice">Invoice</ToggleButton>

                </ToggleButtonGroup>

              </Grid>
            </Grid>

            <Grid md={6} item mt={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Due Date"
                  // minDate={dayjs('2017-01-01')}
                  onChange={(newValue) => {
                    setValue('dueDate', newValue["$D"] + '/' + newValue["$M"] + '/' + newValue["$M"]);
                    setDueDate(newValue);
                  }}
                  value={dueDate}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
              {/* <TextField
                fullWidth
                type="text"
                label="Job Scope"
                name='job_scope'
                {...register('job_scope', {
                  required: '*Field is required and needs to be a text',
                })}
                error={Boolean(errors.job_scope)}
                helperText={errors.job_scope?.message}
                InputLabelProps={{ shrink: true }}
              /> */}
            </Grid>
          </Grid>
          <Grid container direction={'row'} item spacing={5}>
            <Grid item md={6} mt={2} >
              {''}
            </Grid>
            <Grid item md={6} mt={4}>

              {/* <TextField
                fullWidth
                type="text"
                label="Job Scope Details"
                name='job_scope_details'
                {...register('job_scope_details', {
                  required: '*Field is required and needs to be a text',
                })}
                error={Boolean(errors.job_scope_details)}
                helperText={errors.job_scope_details?.message}
                InputLabelProps={{ shrink: true }}
              /> */}
            </Grid>

          </Grid>
          {/* <Grid container direction={'row'} item spacing={5}>

            <Grid item md={6} mt={2}>
              {''}
            </Grid>
            <Grid item md={6} mt={2}>
              {''}
            </Grid>
          </Grid> */}
          {/* <Grid mt={5}>
            <Grid>
              <Typography variant="h5" component="h2">
                Billing Line Items
              </Typography>
            </Grid>
            <Grid mt={2}>
              <Card>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={valueBiling} onChange={handleChangeBilling} aria-label="basic tabs example">
                    <Tab label="Labour Costs" {...a11yProps(0)} />
                    <Tab label="Equipment and Materials" {...a11yProps(1)} />
                    <Tab label="Fixed Price" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={valueBiling} index={0}>
                  <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
                    Labour Costs
                  </Typography>
                  {
                    labour.map((item, i) => (
                      <>
                        <Grid container>
                          <Grid item md={1} sx={{ marginRight: '-60px' }}>
                            <CancelIcon key={i} onClick={() => handleLabourDelete(i)} sx={{ fontSize: '42px', color: 'red' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                              {""}
                            </CancelIcon>
                          </Grid>
                          <Divider orientation="vertical" variant="middle" flexItem />
                          <Grid item md={10}>
                            <Grid container>
                              <Grid md={6} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Cost Item"
                                  {...register(`labourItem.${i}.costItem`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={6} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Name"
                                  {...register(`labourItem.${i}.name`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid md={4} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Unit"
                                  {...register(`labourItem.${i}.unit`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={4} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Unit Cost"
                                  {...register(`labourItem.${i}.unitCost`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={4} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Hourly Cost"
                                  {...register(`labourItem.${i}.hourlyCost`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            </Grid>
                            <Grid sx={{ m: 2 }} container>
                              <Grid item>
                                <FormGroup>
                                  <FormControlLabel control={
                                    <Checkbox
                                      {...register(`labourItem.${i}.taxExempt`)}
                                      checked={getValues(`labourItem.${i}.taxExempt`)} />} label="Tax Exempt" />
                                </FormGroup>
                              </Grid>
                            </Grid>
                            <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
                          </Grid>

                        </Grid>
                      </>
                    ))
                  }

                  <Grid>
                    <Button variant="contained" sx={{ width: '20vw', marginTop: '0px' }} onClick={handleAddLabour} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Labour Cost Item</Button>
                  </Grid>
                  <Grid mt={3}>
                    <Grid>
                      <Typography variant='h5'> Additional Fields</Typography>
                    </Grid>
                    {
                      additionalLabour.map((element, index) => (
                        <>
                          <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                            <Grid sx={{ marginRight: '15px' }}>
                              <HighlightOffIcon variant="contained" onClick={() => handleAdditionalLabourDelete(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                {""}
                              </HighlightOffIcon>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid sx={{ marginLeft: '15px' }}>

                              <Grid md={6} item mt={2} sx={{ width: '60vw' }}>
                                <TextField
                                  fullWidth
                                  type="text"
                                  label="Field Name"
                                  name='field_name'
                                  {...register(`additionaLabourItem.${index}.field_name`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={6} item mt={2}>
                                <TextField
                                  fullWidth
                                  type="text"
                                  label="Field Value"
                                  name='field_value'
                                  {...register(`additionalLabourItem.${index}.field_value`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Divider sx={{ marginTop: '15px' }} />
                            </Grid>

                          </Grid>

                        </>
                      ))
                    }
                    <Grid>
                      <Button variant="contained" sx={{ width: '15vw', marginTop: '25px' }} onClick={handleAdditionalLabourButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Additional Fields</Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={valueBiling} index={1}>
                  <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
                    Equipment and Materials
                  </Typography>
                  {
                    material.map((item, i) => (
                      <>
                        <Grid container>
                          <Grid item md={1} sx={{ marginRight: '-60px' }}>
                            <CancelIcon key={i} onClick={() => handleMaterialDelete(i)} sx={{ fontSize: '42px', color: 'red' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                              {""}
                            </CancelIcon>
                          </Grid>
                          <Divider orientation="vertical" variant="middle" flexItem />
                          <Grid item md={10}>
                            <Grid container>
                              <Grid md={6} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Cost Item"
                                  {...register(`materialItem.${i}.costItem`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={6} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Name"
                                  {...register(`materialItem.${i}.name`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid md={4} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Unit"
                                  {...register(`materialItem.${i}.unit`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={4} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Unit Cost"
                                  {...register(`materialItem.${i}.unitCost`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={4} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Hourly Cost"
                                  {...register(`materialItem.${i}.hourlyCost`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            </Grid>
                            <Grid sx={{ m: 2 }} container>
                              <Grid item>
                                <FormGroup>
                                  <FormControlLabel control={
                                    <Checkbox
                                      {...register(`materialItem.${i}.taxExempt`)}
                                      checked={getValues(`materialItem.${i}.taxExempt`)} />} label="Tax Exempt" />
                                </FormGroup>
                              </Grid>
                            </Grid>
                            <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
                          </Grid>
                        </Grid>
                      </>
                    ))
                  }
                  <Grid mt={2}>
                    <Button variant="contained" sx={{ width: '20vw' }} onClick={handleAddMaterial} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Equipment and Materials Item</Button>
                  </Grid>
                  <Grid mt={3}>
                    <Grid>
                      <Typography variant='h5'> Additional Fields</Typography>
                    </Grid>
                    {
                      additionalEquipment.map((element, index) => (
                        <>
                          <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                            <Grid sx={{ marginRight: '15px' }}>
                              <HighlightOffIcon variant="contained" onClick={() => handleAdditionalEquipmentDelete(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                {""}
                              </HighlightOffIcon>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid sx={{ marginLeft: '15px' }}>

                              <Grid md={6} item mt={2} sx={{ width: '60vw' }}>
                                <TextField
                                  fullWidth
                                  type="text"
                                  label="Field Name"
                                  name='field_name'
                                  {...register(`additionalEquipmentItem.${index}.field_name`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={6} item mt={2}>
                                <TextField
                                  fullWidth
                                  type="text"
                                  label="Field Value"
                                  name='field_value'
                                  {...register(`additionalEquipmentItem.${index}.field_value`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Divider sx={{ marginTop: '15px' }} />
                            </Grid>

                          </Grid>

                        </>
                      ))
                    }
                    <Grid>
                      <Button variant="contained" sx={{ width: '15vw', marginTop: '25px' }} onClick={handleAdditionalEquipmentButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Additional Fields</Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={valueBiling} index={2}>
                  <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
                    Fixed Price
                  </Typography>
                  {
                    fix.map((item, i) => (
                      <>
                        <Grid container>
                          <Grid item md={1} sx={{ marginRight: '-60px' }}>
                            <CancelIcon key={i} onClick={() => handleFixDelete(i)} sx={{ fontSize: '42px', color: 'red' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                              {""}
                            </CancelIcon>
                          </Grid>
                          <Divider orientation="vertical" variant="middle" flexItem />
                          <Grid item md={10}>
                            <Grid container>
                              <Grid md={6} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Cost Item"
                                  {...register(`fixItem.${i}.costItem`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={6} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Name"
                                  {...register(`fixItem.${i}.name`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid md={4} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Unit"
                                  {...register(`fixItem.${i}.unit`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={4} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Unit Cost"
                                  {...register(`fixItem.${i}.unitCost`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={4} item mt={2}>
                                <TextField
                                  sx={{ marginLeft: 2, width: "98%" }}
                                  type="text"
                                  label="Hourly Cost"
                                  {...register(`fixItem.${i}.hourlyCost`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            </Grid>
                            <Grid sx={{ m: 2 }} container>
                              <Grid item>
                                <FormGroup>
                                  <FormControlLabel control={
                                    <Checkbox
                                      {...register(`fixItem.${i}.taxExempt`)}
                                      checked={getValues(`fixItem.${i}.taxExempt`)} />} label="Tax Exempt" />
                                </FormGroup>
                              </Grid>
                            </Grid>
                            <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
                          </Grid>
                        </Grid>
                      </>
                    ))
                  }
                  <Grid mt={2}>
                    <Button variant="contained" sx={{ width: '20vw' }} onClick={handleAddFix} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Fixed Price Item</Button>
                  </Grid>
                </TabPanel>
              </Card>
            </Grid>
          </Grid> */}
          <Grid mt={1}>
            <FormGroup>
              <FormControlLabel control={
                <Checkbox
                  {...register(`active`)}
                />} label="Active" />
            </FormGroup>
          </Grid>
        </Grid>
        <Typography variant="h6" component="h2">
          Remarks
        </Typography>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          style={{ width: 600, backgroundColor: 'transparent' }}
          label="Remarks"
          {...register('remark')}
          InputLabelProps={{ shrink: true }}
        />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} style={{ width: 300 }}>
          Submit
        </LoadingButton>
        {params && (
          <Button
            variant="contained"
            size="large"
            color="error"
            fullWidth
            onClick={() => {
              navigate('manager/job/list');
            }}
          >
            Cancel
          </Button>
        )}
      </Stack>
    </form >

  );
}

ManagerJobCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func
};
