import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Grid } from '@mui/material';
// mock

// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import sidebarlogo from '../../../components/logo/sidebarlogo/imagesidebar.png'
import EastIcon from '@mui/icons-material/East';
//
import {navConfig} from './config'
import { AuthContext } from 'src/context/AuthContext';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const {state} = useContext(AuthContext);
  const [rolechecked, setrolechecked] = useState('');
  useEffect(() => {
    setrolechecked(state?.user?.role)
  }, [AuthContext])

console.log(state?.user?.password_changed)
  const { pathname } = useLocation();
  const [openmenu, setOpenmenu] = React.useState(false);


  const NAV_WIDTH = !openmenu ? 240 : 80;

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
   
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Grid  sx={{ px: 2.5, py: 3, textAlign:"end" }}  onClick={() => setOpenmenu(!openmenu)}>
      {!openmenu ?
      <KeyboardBackspaceIcon /> :
      <EastIcon/>
}
      </Grid>
  
      <Box sx={{ pb:!openmenu ? 5 :8, mx: 2.5 }}>
        <Link underline="none">
      <Box sx={{ width: "100%", height: "100%"}}>
        <img style={{paddingInline:30,display:openmenu ? "none" : ""}} src={ sidebarlogo} alt="" />
      </Box>
    
        </Link>
      </Box>


{
  {admin:<NavSection data={navConfig()?.admin} openmenu={openmenu} />,
  manager:<NavSection data={navConfig()?.manager} openmenu={openmenu} />,
  employee:<NavSection data={navConfig()?.employee} openmenu={openmenu} />
}[rolechecked]}



      <Box sx={{ flexGrow: 1 }} />


    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop  ? (
        <Drawer
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
        
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
