// Title: Extent of metering of water connections

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import VehicleCreateForm from './VehicleCreateForm';

const { PRIVATE_ROUTE } = API_ROUTES;

function VehicleForm() {
  const { id } = useParams();
  const [editData, setEditData] = useState([])

  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    control
  } = useForm(!id ? 
    {defaultValues: {}} :
    {defaultValues: {
        active: false,
    }})
  const getVehicleFormById = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_VEHICLE_BY_ID}/${id}`
    );
    setEditData(data)
    setValue('unit_number', data?.data.unit_number);
    setValue('make', data?.data.make);
    setValue('model', data?.data.model);
    setValue('year', data?.data.year);
    setValue('active', data?.data?.active);
    setValue('remark', data?.data.remark);
  }, [authAxios, id, setValue]);

  useEffect(() => {
    if (id) {
      getVehicleFormById();
    }
  }, [getVehicleFormById, id]);

  const onSubmit = async (data) => {
    if (id) {
      const response = await authAxios.post(
        `${PRIVATE_ROUTE?.EDIT_VEHICLE}${id}`,
        data
      );
      toast.success(response.data.massage);
      navigate('/master/vehicle/list');
    } else {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_VEHICLE, data);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/master/vehicle/list');
      }
      else {
        toast.error(response.data.massage);
      }
    }
    setValue('unit_number', '');
    setValue('make', '');
    setValue('model', '');
    setValue('year', '');
    setValue('active', '');
    setValue('remark', '');
  };
  return (
    <VehicleCreateForm
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      setValue={setValue}
      onSubmit={onSubmit}
      params={id}
      loading={isSubmitting}
      isEdit={id ? "true" : "false"}
      getValues={getValues}
      control={control}
      editData={editData}
    />
  );
}
export default VehicleForm;
