import { TextField, Autocomplete, MenuItem, createTheme, Box, Chip } from '@mui/material';
import React, { useState } from 'react'
// import Autocomplete from 'src/theme/overrides/Autocomplete';
export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "red" },
      },
    },
  },
});
const ConditionalDropdown = ({ data, condition, setCondition,secondstate, label, id, state, setState, getValues, register, validation, errors }) => {

  const [personName, setPersonName] = React.useState([]);
  function getStyles(name, personName, theme) {
    return {
      fontWeight: personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular :
        theme.typography.fontWeightMedium,

    };
  } 
  return (
    <>
      <Autocomplete
        disablePortal
        autoHighlight
        id={id}
        options={data}
        getOptionLabel={(option) => option.label}
        defaultValue={getValues(state)}
        onChange={(e, val) => {
            setState(state, val.value)
                     setCondition(val.label)
                     secondstate && setState(secondstate, val.id)
                       secondstate && setPersonName(getValues(state)) 
                    }}
      renderInput={(params) => {
        return (
          <>{
            validation ?
              <TextField
                {...params}
                fullWidth
                name={state}
                type="text"
                label={label}
                {...register(state, {
                  required: '*Field is required and needs to be a text',
                })}
                helperText={Boolean(errors[`${state}`]) ? errors[`${state}`]?.message : ""}
                error={Boolean(errors[`${state}`])}
                InputLabelProps={{ shrink: true }}


              />
              :
              <TextField
                {...params}
                fullWidth
                type="text"
                {...register(state)}
                label={label}
                InputLabelProps={{ shrink: true }}
              />}
          </>
        )
      }
      }

                // getOptionLabel={(option) => {
        //   return (
        //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
        //       <Chip onDelete={(e)=>{handleDeletechip(e,option)}} label={option} sx={{zIndex:9999, backgroundColor: '#65C8D0', color: 'white' }}
        //       />
        //     </Box>
        //   )
        // }}
        />
            
              
  
             

        </>
  )
}
export default ConditionalDropdown;