// Title: Extent of metering of water connections

import React, { useCallback, useEffect, createContext, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import RateSheetCreateForm from './RateSheetCreateForm';
import { Backdrop, CircularProgress } from '@mui/material';

const { PRIVATE_ROUTE } = API_ROUTES;
function RateSheetForm() {
  const [state, setstate] = useState();
  const [loading, setLoading] = useState(true);
  const [categoryData, setcategoryData] = useState([]);
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const [costItemData, setcostItemData] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    control
  } = useForm(!id ?{ 
    defaultValues: {
      active: false,
      text_exempt:false,
    } }
    : {
      defaultValues: {
        active: false,
        text_exempt:false
      }
    });
  const getRatesheetFormById = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_RATESHEET_BY_ID}/${id}`
    );
   
    setstate(data?.data);
    setValue('name', data?.data?.name);
    setValue('active', data?.data?.active);
    setValue('remark', data?.data?.remark);
    data?.data?.billable_line_items?.labourItem?.map((element, i) => {
      setValue(`labourItem.${i}.costItem`, element?.costItem)
      setValue(`labourItem.${i}.name`, element?.name)
      setValue(`labourItem.${i}.unit`, element?.unit)
      setValue(`labourItem.${i}.unitCost`, element?.unitCost)
      setValue(`labourItem.${i}.hourlyCost`, element?.hourlyCost)
      setValue(`labourItem.${i}.text_exempt`, element?.text_exempt)
    })
    data?.data?.billable_line_items?.materialItem?.map((element, i) => {
      setValue(`materialItem.${i}.costItem`, element?.costItem)
      setValue(`materialItem.${i}.name`, element?.name)
      setValue(`materialItem.${i}.unit`, element?.unit)
      setValue(`materialItem.${i}.unitCost`, element?.unitCost)
      setValue(`materialItem.${i}.text_exempt`, element?.text_exempt)
    })
    data?.data.billable_line_items?.fixItem?.map((element, i) => {
      setValue(`fixItem.${i}.costItem`, element?.costItem)
      setValue(`fixItem.${i}.name`, element?.name)
      setValue(`fixItem.${i}.unit`, element?.unit)
      setValue(`fixItem.${i}.unitCost`, element?.unitCost)
      setValue(`fixItem.${i}.text_exempt`, element?.text_exempt)
    })
    setLoading(false);
  }, [authAxios, id, setValue]);

  const getcategorydata = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_CATEGORY}`
    );
    setcategoryData(data.data)
  }, [authAxios, id])

  const getcostItemdata = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_COSTITEAMALL}`
    );
    
    setcostItemData(data.data);
  }, [authAxios, id])

  useEffect(() => {
    getcostItemdata();
    getcategorydata();
    if (id) {
      getRatesheetFormById();
    }
  }, [getRatesheetFormById, id]);

  const onSubmit = async (data) => {
   
    if (id) {
      const response = await authAxios.post(
        `${PRIVATE_ROUTE?.EDIT_RATESHEET}${id}`,
        data
      );
     if (response.data.statusCode === 200) {
      toast.success(response.data.massage);
      navigate('/rate-sheet/list');
    }
    else {
      toast.error(response.data.massage);
      setLoading(false);
    }
  }
     else {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_RATESHEET, data);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/rate-sheet/list');
        setLoading(false);
      }
      else {
        toast.error(response.data.massage);
        setLoading(false);
      }
    }
    state?.billable_line_items?.labourItem?.map((element, i) => {
      setValue(`labourItem.${i}.costItem`, '')
      setValue(`labourItem.${i}.name`, '')
      setValue(`labourItem.${i}.unit`, '')
      setValue(`labourItem.${i}.unitCost`, '')
      setValue(`labourItem.${i}.hourlyCost`, '')
      setValue(`labourItem.${i}.text_exempt`, '')
    })
    state?.billable_line_items?.materialItem?.map((element, i) => {
      setValue(`materialItem.${i}.costItem`, '')
      setValue(`materialItem.${i}.name`, '')
      setValue(`materialItem.${i}.unit`, '')
      setValue(`materialItem.${i}.unitCost`, '')
      setValue(`materialItem.${i}.text_exempt`, '')
    })
    state?.billable_line_items?.fixItem?.map((element, i) => {
      setValue(`fixItem.${i}.costItem`, '')
      setValue(`fixItem.${i}.name`, '')
      setValue(`fixItem.${i}.unit`, '')
      setValue(`fixItem.${i}.unitCost`, '')
      setValue(`fixItem.${i}.text_exempt`, '')
    })
    setValue('name', '');
    setValue('active', '');
    setValue('remark', '');
  };
  return (
    <>
    {id ?
        loading && (
          <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}

            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )

        :
        ""
      }

    <RateSheetCreateForm
      register={register}
      editData={state}
      getValues={getValues}
      categoryData={categoryData}
      costItemData={costItemData}
      handleSubmit={handleSubmit}
      setValue={setValue}
      errors={errors}
      onSubmit={onSubmit}
      params={id}
      loading={isSubmitting}
      isEdit={id ? "true" : "false"}
      control={control}
    />
        </>
  );
}

export default RateSheetForm;
