import produce from 'immer';


import { getStorage, isLoginStorage, logoutStorage, setStorage } from '../../utils/isAuthenticated';
import { LOGIN, LOGOUT } from '../constatnts';


let app = getStorage(process.env.REACT_APP_PROJECT_NAME);
app = JSON.parse(app);


export const initialState = {
  token: app?.token ?? '',
  message: '',
  success: app?.statusCode,
  user: app?.user,
  isAuthenticated: isLoginStorage(),
  loginTime:''
};


  let saved = app?.loginTime ;
  console.log("23",saved);
  const now = new Date().getTime();
    if (saved && (now-saved > 604800000)) {
      localStorage.clear()
      logoutStorage()
  
    };



export const AuthReducer =  (state = initialState, action = {}) => 
produce(state, (draft) => {
    switch (action.type) {
      case LOGIN: {
        const { message, token, statusCode, user,loginTime } = action.payload;
        setStorage(process.env.REACT_APP_PROJECT_NAME, JSON.stringify({ token, user,loginTime }));
        draft.token = token;
        draft.message = message;
        draft.success = statusCode;
        draft.user = user;
        draft.loginTime = loginTime;
        draft.isAuthenticated = isLoginStorage();
        break;
      }
      case LOGOUT: {
      
        draft.token = '';
        draft.message = '';
        draft.success = '';
        draft.user = '';
        draft.loginTime = '';
        break;
      }
      default:
        return state;
    }
 
  });
