import React, { useRef } from 'react'

// @mui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Grid, Container, Typography, Box, TextField, Stack, Autocomplete, Button, Divider, FormGroup, FormControlLabel, Checkbox, ToggleButtonGroup, ToggleButton, TextareaAutosize, Tabs, Tab, Card, FormLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add'
import PropTypes, { element } from 'prop-types';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Popup from 'reactjs-popup';
import SignaturePad from 'react-signature-canvas';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Dropdown from 'src/components/customecomponent/Dropdown';
import { useNavigate } from 'react-router-dom/dist';
import { Controller, useFieldArray } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import { useState } from 'react';
import ConditionalDropdown from 'src/components/customecomponent/ConditionalDropdown';

export default function ManagerDailyWorkReportCreateForm({
  register,
  errors,
  getValues,
  setValue,
  isEdit,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control,
}) {
  const navigate = useNavigate()
  const [selectFile, setselectFile] = useState('');
  const [orderDate, setOrderDate] = useState();
  const [starttime, setStartTime] = useState();
  const [endtime, setEndTime] = useState();
  const [starttimeValue, setStartTimeValue] = useState({});
  const [endtimeValue, setEndTimeValue] = useState({});
  const [signOne, setSignOne] = useState("");
  const [signTwo, setSignTwo] = useState("");
  const [condition, setCondition] = useState("");
  let [textAreaCount, settextAreaCount] = React.useState(0);
  const sigCanvas = useRef({})

  const clear = () => sigCanvas.current.clear();

  const save = (close) => {
    setSignOne(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    setValue("representativeSign", sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    close()
  };
  const saveTwo = (close) => {
    setSignTwo(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    setValue("representativeSignTwo", sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    close()
  };
  const { fields: labourCosts, remove: removeLabourCosts, insert: insertLabourCosts } = useFieldArray({
    control,
    name: "labourCostsItem",
  });
  const { fields: equipment, remove: removeEquipment, insert: insertEquipment } = useFieldArray({
    control,
    name: "equipmentItem",
  });

  const CostItemName = [{
    label: 'CostItemName Type 1',
    value: 'CostItemName Type 1',
  }, {
    label: 'CostItemName Type 2',
    value: 'CostItemName Type 2',
  }]
  const Representative = [{
    label: 'Representative Type 1',
    value: 'Representative Type 1',
  }, {
    label: 'Representative Type 2',
    value: 'Representative Type 2',
  }]
  const ClientRepresentative = [{
    label: 'ClientRepresentative Type 1',
    value: 'ClientRepresentative Type 1',
  }, {
    label: 'ClientRepresentative Type 2',
    value: 'ClientRepresentative Type 2',
  }]
  const labourCostName = [{
    label: 'labourCostName Type 1',
    value: 'labourCostName Type 1',
  }, {
    label: 'labourCostName Type 2',
    value: 'labourCostName Type 2',
  }]
  const Task = [{
    label: 'Task Type 1',
    value: 'Task Type 1',
  }, {
    label: 'Task Type 2',
    value: 'Task Type 2',
  }]
  const Employee = [{
    label: 'Employee Type 1',
    value: 'Employee Type 1',
  }, {
    label: 'Employee Type 2',
    value: 'Employee Type 2',
  }]

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handlelabourCostsButton = () => {
    setEndTime({ ...endtimeValue, [labourCosts.length]: "" })
    setStartTime({ ...starttimeValue, [labourCosts.length]: "" })
    insertLabourCosts(labourCosts.length, {
      "labourCostName": '',
      "employee": '',
      "start_time": '',
      "end_time": '',
      "hours": '',
      "description": '',
    })
  };
  const handleEquipmentButton = () => {
    insertEquipment(equipment.length, {
      "CostItem": "",
      "name": "",
      "unit": "",
      "quantity": "",
      "discription": "",
    })
  }
  const [value, setValueTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleDeleteImage = (i) => {
    setSignOne('');
  }
  const handleDeleteImageTwo = (i) => {
    setSignTwo('');
  }
  const handleFile = (e) => {
    setValue("image", URL.createObjectURL(e.target.files[0]));
    setselectFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)} >
      <Stack spacing={3} padding={3}>
        <Typography variant="h6" component="h2">
          Create DailyWorkReport
        </Typography>
        <Grid>
          <Grid container direction={"row"} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
            <Grid md={6} item xs={12}>
              <FormLabel>Task</FormLabel>
              <ConditionalDropdown condition={condition} setCondition={setCondition} state={'Task'} setState={setValue} getValues={getValues} data={Task} register={register} validation={true} errors={errors}
              />
            </Grid>
            <Grid md={6} item xs={12}>{''}</Grid>
          </Grid>
          <Grid>
            <Button onClick={() => navigate('/staff/task/create')} variant="contained" sx={{ marginTop: '20px' }}>Create New Task</Button>
          </Grid>
          {
              condition === "" ? '' : <> 
                
          <Grid container direction={"row"} rowSpacing={2} mt={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
            <Grid md={6} item xs={12}>
              <FormLabel><>Date  </></FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  onChange={(newValue) => {
                    setValue('orderDate', newValue["$D"] + '/' + newValue["$M"] + '/' + newValue["$M"]);
                    setOrderDate(newValue);
                  }}
                  value={orderDate}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid md={6} item xs={12}>
              <FormLabel>Estimated Hours</FormLabel>
              <TextField
                fullWidth
                type="number"
                name='estimated_hours'
                {...register(`estimated_hours`)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container direction={"row"} rowSpacing={2} mt={0} spacing={{ xs: 0, sm: 0, md: 5 }}>
          <Grid md={6} item xs={12}>
              <FormLabel>Task Hours</FormLabel>
              <TextField
                fullWidth
                type="number"
                name='task_hours'
                {...register(`task_hours`)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            </Grid>        
          <Grid>
            <Typography variant="h6" component="h2" mt={2}>
              DWR Details
            </Typography>
            <Card sx={{ marginBlock: 2 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

                  <Tab label="Labour Costs" {...a11yProps(0)} />
                  <Tab label="Equipment And Materials" {...a11yProps(1)} />
                  <Tab label="Job Details" {...a11yProps(2)} />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <Grid mt={0}>
                  <Grid>
                    <Typography variant='h5'> Labour Cost Line Item</Typography>
                  </Grid>
                  {
                    labourCosts.map((element, index) => (
                      <>
                        <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                          <Grid sx={{ marginRight: '15px' }}>
                            <HighlightOffIcon variant="contained" onClick={() => removeLabourCosts(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                              {""}
                            </HighlightOffIcon>
                          </Grid>
                          <Divider orientation="vertical" variant="middle" flexItem />
                          <Grid sx={{ marginLeft: '20px' }}>
                            <Grid container direction={"row"} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                              <Grid md={6} item xs={12}>
                                <FormLabel>Cost Item</FormLabel>
                                <Dropdown state={`labourCostsItem.${index}.labourCostName`} setValue={setValue} getValues={getValues} data={labourCostName} startIcon={<FmdGoodIcon />}
                                />
                              </Grid>
                              <Grid md={6} item xs={12}>
                                <FormLabel>Employee</FormLabel>
                                <Dropdown state={`labourCostsItem.${index}.employee`}
                                  setValue={setValue} getValues={getValues} data={Employee} startIcon={<FmdGoodIcon />}
                                />
                              </Grid>
                            </Grid>
                            <Grid mt={2}>
                              <Typography variant='h5'> Time Card</Typography>
                            </Grid>
                            <Grid>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                  <Grid container direction={"row"} mt={1} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }} >
                                    <Grid md={6} item xs={12} >
                                      <Grid>
                                        <FormLabel>Start</FormLabel>
                                      </Grid>

                                      <TimePicker
                                        inputFormat="HH:MM"
                                        value={starttimeValue[`${index}`]}
                                        onChange={(newValue) => {
                                          setValue(`labourCostsItem.${index}.start_time`, newValue["$H"] + ':' + newValue["$M"]);
                                          setStartTimeValue({ ...starttimeValue, [index]: newValue });
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                      />

                                    </Grid>
                                    <Grid md={6} item xs={12}>
                                      <Grid>
                                        <FormLabel>End</FormLabel>
                                      </Grid>
                                      <TimePicker
                                        inputFormat="HH:mm"
                                        value={endtimeValue[`${index}`]}
                                        onChange={(newValue) => {
                                          setValue(`labourCostsItem.${index}.end_time`, newValue["$H"] + ':' + newValue["$M"]);
                                          setEndTimeValue({ ...endtimeValue, [index]: newValue });
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                      />
                                    </Grid>
                                    <Grid md={6} item xs={12}>
                                      <FormLabel>Hours</FormLabel>
                                      <TextField
                                        fullWidth
                                        type="number"
                                        name='hours'
                                        {...register(`labourCostsItem.${index}.hours`)}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </Grid>
                                    <Grid md={6} item xs={12}>
                                      <FormLabel>Discription</FormLabel>
                                      <TextField
                                        fullWidth
                                        type="text"
                                        name='description'
                                        {...register(`labourCostsItem.${index}.description`)}
                                        InputLabelProps={{ shrink: true }}
                                      /></Grid>
                                  </Grid>
                                </Stack>
                              </LocalizationProvider>
                            </Grid>

                            <Divider sx={{ marginTop: '15px' }} />
                          </Grid>
                        </Grid>
                      </>
                    ))
                  }
                  <Grid>
                    <Button variant="contained" sx={{ marginTop: '20px' }} onClick={handlelabourCostsButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Labour Cost</Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid>
                  <Grid mt={0}>
                    <Typography variant='h5'> Equipment And Materials</Typography>
                  </Grid>
                  {
                    equipment.map((element, index) => (
                      <>
                        <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                          <Grid sx={{ marginRight: '15px' }}>
                            <HighlightOffIcon variant="contained" onClick={() => removeEquipment(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                              {""}
                            </HighlightOffIcon>
                          </Grid>
                          <Divider orientation="vertical" variant="middle" flexItem />
                          <Grid sx={{ marginLeft: '15px' }}>
                            <Grid container direction={"row"} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                              <Grid md={6} item xs={12}>
                                <FormLabel>Cost Item</FormLabel>
                                <Dropdown state={`equipmentItem.${index}.CostItem`} setState={setValue} getValues={getValues} data={CostItemName} startIcon={<FmdGoodIcon />}
                                />
                              </Grid>
                              <Grid md={6} item xs={12}>
                                <FormLabel>Name</FormLabel>
                                <TextField
                                  fullWidth
                                  type="text"
                                  name='name'
                                  {...register(`equipmentItem.${index}.name`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={6} item xs={12}>
                                <FormLabel>Unit</FormLabel>
                                <TextField
                                  fullWidth
                                  type="text"
                                  name='unit'
                                  {...register(`equipmentItem.${index}.unit`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={6} item xs={12}>
                                <FormLabel>Quantity</FormLabel>
                                <TextField
                                  fullWidth
                                  type="text"
                                  name='quantity'
                                  {...register(`equipmentItem.${index}.quantity`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid md={6} item xs={12}>
                                <FormLabel>Discription</FormLabel>
                                <TextField
                                  fullWidth
                                  type="text"
                                  name='discription'
                                  {...register(`equipmentItem.${index}.discription`)}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            </Grid>
                            <Grid mt={2}>
                              <FormGroup>
                                <FormControlLabel control={
                                  <Checkbox
                                    {...register(`equipmentItem.${index}.include_discription`)}
                                  />} label="Include Discription On DWR" />
                              </FormGroup>
                            </Grid>
                            <Divider sx={{ marginTop: '15px' }} />
                          </Grid>
                        </Grid>
                      </>
                    ))
                  }
                  <Grid>
                    <Button variant="contained" sx={{ marginTop: '20px' }} onClick={handleEquipmentButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Equipment And Materials</Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid mt={0}>
                  <Grid>
                    <Typography variant='h5'> Job Details</Typography>
                  </Grid>
                  <Grid sx={{ marginLeft: '15px' }}>

                    <Grid md={6} item mt={2} sx={{ width: '60vw' }}>
                      <Grid>
                        <Typography variant='h6' > Job Number</Typography>
                        <Typography variant='h7' mt={1}> 001001</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant='h6' mt={2}> Client</Typography>
                        <Typography variant='h7' mt={1}> Gurbaldev Mann</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant='h6' mt={2}> Client Address</Typography>
                        <Typography variant='h7' mt={1}> 165008 95 Avenue Survey,British Columbia</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant='h6' mt={2}> Project Name</Typography>
                        <Typography variant='h7' mt={1}> 001001</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant='h6' mt={2}> Client Project #</Typography>
                        <Typography variant='h7' mt={1}> 001001</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant='h6' mt={2}> SubContract #</Typography>
                        <Typography variant='h7' mt={1}> </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant='h6' mt={2}> DWR Billing Type</Typography>
                        <Typography variant='h7' mt={1}> Time & Materials</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant='h6' mt={2}> Addition Fields</Typography>
                        <Typography variant='h7' mt={1}> 001001</Typography>
                      </Grid>
                    </Grid>

                    <Divider sx={{ marginTop: '15px' }} />
                  </Grid>
                </Grid>
              </TabPanel>
            </Card>
            <Grid container direction={"row"} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
              <Grid md={6} item xs={12}>
                <Grid>
                  <Typography variant="h6" component="h2">
                    Representative Signature
                  </Typography>
                </Grid>
                {
                  signOne !== "" ? <>
                    <Grid container direction={"row"} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                      <Grid md={3} item xs={12} mt={2}>
                        <Button variant="contained" component="label" >
                          <a href={signOne} rel="noreferrer" style={{ textDecoration: "none", color: 'white', }} target={'_blank'}>
                            view image
                          </a>
                        </Button>
                      </Grid>
                      <Grid md={3} item xs={12} mt={2}>
                        <Button variant="contained" component="label" onClick={() => handleDeleteImage()} >
                          Delete
                        </Button>
                      </Grid>
                      <Grid md={6} item xs={12}>{''}</Grid>
                    </Grid></> :
                    <>
                      <Grid container direction={"row"} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <Grid md={5} item xs={12} mt={2}>
                          <Button variant="contained" startIcon={<AddIcon />}>Add My Signature </Button>
                        </Grid>
                        <Grid md={5} item xs={12} mt={2}>
                          <Popup modal closeOnDocumentClick={false} trigger={<Button variant="contained" startIcon={<ChevronRightIcon />}>Collect Signature </Button>}>
                            {
                              close => (
                                <>
                                  <SignaturePad
                                    ref={sigCanvas}
                                    canvasProps={{
                                      className: "signatureCanvas"
                                    }}
                                  />
                                  <Button variant='contained' sx={{ mr: 2 }} onClick={() => (save(close))}>save</Button>
                                  <Button variant='contained' sx={{ mr: 2 }} onClick={close}>close</Button>
                                  <Button variant='contained' sx={{ mr: 2 }} onClick={clear}>clear</Button>
                                </>
                              )
                            }
                          </Popup>
                        </Grid>
                      </Grid>
                    </>
                }
              </Grid>
              <Grid md={6} item xs={12}>
                <Grid>
                  <Typography variant="h6" component="h2">
                    Client Representative Signature
                  </Typography>
                </Grid>

                {
                  signTwo !== "" ?
                    <>
                      <Grid container direction={"row"} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <Grid md={3} item xs={12} mt={2}>
                          <Button variant="contained" component="label" sx={{ marginLeft: '0px' }}>
                            <a href={signTwo} rel="noreferrer" style={{ textDecoration: "none", color: 'white', }} target={'_blank'} label="View Image" >
                              view image
                            </a>
                          </Button>
                        </Grid>
                        <Grid md={3} item xs={12} mt={2}>
                          <Button variant="contained" component="label" onClick={() => handleDeleteImageTwo()} >
                            Delete
                          </Button>
                        </Grid>
                        {/* <Grid md={2} item xs={12}>{''}</Grid> */}
                      </Grid>
                    </>
                    :
                    <>
                      <Grid item mt={2}>
                        <Popup modal closeOnDocumentClick={false} trigger={<Button variant="contained" startIcon={<ChevronRightIcon sx={{ fontSize: '42px' }} />}>Collect Signature </Button>}>
                          {
                            close => (
                              <>
                                <SignaturePad
                                  ref={sigCanvas}
                                  canvasProps={{
                                    className: "signatureCanvas"
                                  }}
                                />
                                <Button variant='contained' sx={{ mr: 2 }} onClick={() => (saveTwo(close))}>save</Button>
                                <Button variant='contained' sx={{ mr: 2 }} onClick={close}>close</Button>
                                <Button variant='contained' sx={{ mr: 2 }} onClick={clear}>clear</Button>
                              </>
                            )
                          }
                        </Popup>
                      </Grid>
                    </>
                }

              </Grid>
            </Grid>
            <Grid container direction={"row"} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
              <Grid md={6} item xs={12}>
                <FormLabel>Representative</FormLabel>
                <Dropdown state={'Representative'} setValue={setValue} getValues={getValues} data={Representative} startIcon={<FmdGoodIcon />}
                />
              </Grid>
              <Grid md={6} item xs={12}>
                <FormLabel>Client's Representative</FormLabel>
                <Dropdown state={'ClientRepresentative'} setValue={setValue} getValues={getValues} data={ClientRepresentative} startIcon={<FmdGoodIcon />}
                />
              </Grid>
            </Grid>

            <Grid mt={2}>
              <Typography variant="h6" component="h2">
                Client-Approved DWR
              </Typography>
            </Grid>
            <Grid mt={2}>
              {
                selectFile === "" ?
                  <>
                    <Button variant="contained" component="label" >
                      select file
                      <input hidden accept="image/*" multiple type="file"
                        onChange={(e) => handleFile(e)} />
                    </Button>
                  </>
                  : <>
                    <Grid container direction={"row"} rowSpacing={5} spacing={{ xs: 0, sm: 0, md: 5 }}>
                      <Grid md={6} item xs={12}>
                        <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          <Grid md={3} item xs={12} mb={2}>
                            <Button variant="contained" component="label"  >
                              <a href={getValues(`image`)} rel="noreferrer" style={{ textDecoration: "none", color: 'white' }} target={'_blank'} label="View Image">
                                view image
                              </a>
                            </Button>
                          </Grid>
                          <Grid md={3} item xs={12}>
                            <Button variant="contained" component="label" onClick={() => setselectFile('')}>
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid md={6} item xs={12}>{''}</Grid>
                      </Grid></Grid>
                  </>
              }
            </Grid>
            <Grid>
              <Grid mt={2}>
                <FormGroup>
                  <FormControlLabel control={
                    <Checkbox
                      {...register(`submitted`)}
                    />} label="Submitted" />
                </FormGroup>
              </Grid>
              <Grid mt={2}>
                <Grid>
                  <Typography variant="h6" component="h2">
                    Submitted Date
                  </Typography>
                  <Typography variant="h8" >
                    22-10-2022
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={"row"} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
            <Grid md={6} item xs={12} mt={2}>
              <FormLabel><>Remarks</></FormLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                onChange={(e, val) => {
                  settextAreaCount(e.target.value.length)
                  setValue("remark", e.target.value)
                }}
                inputProps={{ maxLength: 300 }}
                InputLabelProps={{ shrink: true }}
              />
              <Grid item sx={{ fontSize: "12px", textAlign: "end" }} xs={12}> {textAreaCount} / 300</Grid>
            </Grid>
          </Grid>
               </>
          }
        </Grid>
        <Grid sx={{ display: "flex" }} >
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} style={{ width: 300 }}>
            Submit
          </LoadingButton>
          {params && (
            <Button
              variant="contained"
              size="large"
              color="error"
              style={{ width: 300, marginLeft: "20px" }}
              onClick={() => {
                navigate('/manager/dwr/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form>
  );
}
ManagerDailyWorkReportCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func
};
