import { Button, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Controller } from 'react-hook-form';
// ----------------------------------------------------------------------
export default function ServiceCreateForm({
  register,
  errors,
  isEdit,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control
}) {
  const navigate = useNavigate();

  return (
    <form autoComplete="off" noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3} padding={3}>


        <Typography variant="h6" component="h2">
          Information of Service
        </Typography>
        <TextField
          fullWidth
          type="text"
          label="Service name"
          {...register('name', {
            required: '**Field is required and needs to be a text',

          })}
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
          InputLabelProps={{ shrink:true }}
        />

        <Typography variant="h6" component="h2">
          Note
        </Typography>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          style={{ width: 600, backgroundColor: 'transparent' }}
          label="Remarks"
          {...register('remark')}
          InputLabelProps={{ shrink: true }}
        />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} style={{ width: 300 }}>
          Submit
        </LoadingButton>
        {params && (
          <Button
            variant="contained"
            size="large"
            color="error"
            fullWidth
            onClick={() => {
              navigate('/services/service-list');
            }}
          >
            Cancel
          </Button>
        )}
      </Stack>
    </form>
  );
}

ServiceCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func
};
