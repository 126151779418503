import React from 'react'

const InvoiceView = () => {
  return (
    <div>
        hiiiii
    </div>
  )
}

export default InvoiceView