import React, { useCallback, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Container,
  Card,
  Tabs,
  Tab,
  Grid,
  Divider,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';

const { PRIVATE_ROUTE } = API_ROUTES;

const mapIsReadyCallback = (map) => {};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const TaskView = () => {
  const ref = React.createRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const [valueOne, setValueOne] = React.useState(0);
  const [valueTwo, setValueTwo] = React.useState(0);
  const { authAxios } = useContext(FetchContext);
  const [taskData, setTaskData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [totalSpentHours, setTotalSpentHours] = React.useState(0);

  const handleChangeone = (event, newValue) => {
    setValueOne(newValue);
  };
  const handleChangetwo = (event, newValue) => {
    setValueTwo(newValue);
  };

  const getTaskData = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_TASK_BY_ID}/${id}`);
    setTaskData(data?.data[0]);
    calculateRemainigHours(data?.data[0]);
    setLoading(false);
  }, [authAxios, id]);

  useEffect(() => {
    getTaskData();
  }, []);

  const handleAction = async (event) => {
    event.preventDefault();
    setLoading(false);
    if (confirm('Are you sure want to Complete?') === true) {
      await authAxios.get(`${PRIVATE_ROUTE?.EDIT_TASK}${id}/complete`);
      getTaskData();
      setLoading(true);
    }
  };

  const renderActionButton = (taskData) => {
    let actionButton = '';

    if (!taskData.is_completed) {
      actionButton = (
        <>
          <Grid xs={2} sx={{ marginTop: '10px' }} item>
            <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
              Note :
            </Typography>
          </Grid>
          <Grid sx={{ marginTop: '5px' }} item>
            <Typography variant="body1" color="initial">
              Complete Task button will be displayed when all the DWRs are completed (approved/rejected).
            </Typography>
          </Grid>
        </>
      );
    }

    if (taskData.is_completed) {
      actionButton = (
        <>
          <Grid xs={2} item>
            <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
              Status:
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <Typography variant="body1" color="initial">
              Completed
            </Typography>
          </Grid>
        </>
      );
    }

    let showButton = true;
    if (taskData?.dwrs && taskData?.dwrs.length > 0) {
      for (let i = 0; i < taskData.dwrs.length; i++) {
        if (!taskData.dwrs[i].status || taskData.dwrs[i].status === 0) {
          showButton = false;
          break;
        }
      }

      if (showButton && (!taskData.is_completed || taskData.is_completed !== 1)) {
        actionButton = (
          <>
            <Grid xs={4} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                <Button variant="contained" color="primary" startIcon={<CheckIcon />} onClick={(e) => handleAction(e)}>
                  Complete Task
                </Button>
              </Typography>
            </Grid>
          </>
        );
      }
    }

    return actionButton;
  };

  const calculateRemainigHours = useCallback(
    async (taskdata) => {
      const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_DWR_HOURS_BY_TASK_ID}${taskdata._id}`);

      let total = 0;
      if (data?.data[0]) {
        // total = parseInt(parseInt(taskdata?.estimate_hour) - parseInt(data?.data[0].totalHours));
        total = parseInt(data?.data[0].totalHours);
      }

      setTotalSpentHours(total);
    },
    [authAxios, taskData._id, totalSpentHours]
  );

  const handleEdit = () => {
    navigate(`/manager/task/update/${taskData._id}`);
  };

  const handleDelete = async () => {
    if (confirm('Are you sure want to delete?') === true) {
      await authAxios.post(`${PRIVATE_ROUTE?.EDIT_TASK}${taskData._id}`);
      navigate('/manager/task/list');
    }
  };

  const getStatus = (status) => {
    let statusString = 'Pending';
    if (status === 1) {
      statusString = 'Approved';
    } else if (status === 2) {
      statusString = 'Rejected';
    }
    return statusString;
  };

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" component="h2">
          Task View
        </Typography>
        <Box sx={{ marginTop: '20px' }}>
          <Grid container>
            <Grid md={6} xs={12} item>
              <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Grid xs={12} md={3} sm={3} item mt={2} sx={{ maxWidth: 'auto' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => handleEdit()}
                    disabled={taskData.is_completed}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid xs={12} md={4} sm={4} item>
                  <Button
                    sx={{ width: 'auto', padding: '7px' }}
                    variant="contained"
                    color="primary"
                    startIcon={<FileDownloadIcon />}
                  >
                    Export To PDF
                  </Button>
                </Grid>
                <Grid xs={12} md={3} sm={3} item mt={2} sx={{ maxWidth: 'auto', marginBlock: 'auto' }}>
                  <Button variant="contained" color="primary"
                   startIcon={<DeleteIcon />}
                   disabled={taskData.is_completed} 
                   onClick={() => handleDelete()}
                   >
                    Delete
                  </Button>
                </Grid>
                <Grid xs={12} md={1} sm={1} item mt={2}>
                  <Button variant="contained" color="primary" startIcon={<FileCopyIcon />}>
                    Clone
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={'30px'}>
          <Grid container>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Task No.:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {taskData?.number_str}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Task Name:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {taskData?.name}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Job No.:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {taskData?.job_id?.number_str}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Client No.:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {taskData?.client_id?.number_str}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Client Address:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {taskData?.client_address}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Status:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {taskData?.status_id?.name}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Office:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {taskData?.office_id?.name}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                DWR Billing Type:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {'Time & Materials'}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Taks Category:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {taskData?.task_category_id?.name}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Task Scope:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {taskData?.task_scope_id?.name}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Scope Details:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
              <Typography variant="body1" color="initial">
                {''}
              </Typography>
            </Grid>
          </Grid>
          <Typography mt={'30px'} variant="h6" color="initial">
            Additional Details
          </Typography>
          <Card style={{ marginTop: '30px' }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={valueOne} onChange={handleChangeone} aria-label="basic tabs example">
                  <Tab label="Locations" {...a11yProps(0)} />
                  <Tab label="Contacts" {...a11yProps(1)} />
                  <Tab label="Additional Fields" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={valueOne} index={0}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Locations
                </Typography>
                {taskData?.client_locations?.map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Location Name:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Munciple Address:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.munciple_address}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          State / Province:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.state}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          City:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.city}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Postal Code:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={10} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.postal_code}
                        </Typography>
                      </Grid>
                    </Grid>
                    {taskData.client_locations.length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                  </>
                ))}
              </TabPanel>
              <TabPanel value={valueOne} index={1}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Contacts
                </Typography>
                {taskData?.client_contacts?.map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Full Name:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item?.first_name + ' ' + item?.last_name}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Position:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          <a
                            style={{ color: 'black', textDecoration: 'none' }}
                            href={'https://elevatelandsurveying.com/'}
                          >
                            {item?.position}
                          </a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Depatrment:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item?.department}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Location:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          <a
                            style={{ color: 'black', textDecoration: 'none' }}
                            href={'https://elevatelandsurveying.com/'}
                          >
                            {item?.location}
                          </a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Email:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: 'black', textDecoration: 'none' }} href={'mailto:' + item?.email}>
                            {item?.email}
                          </a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Phone:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: 'black', textDecoration: 'none' }} href={'tel:+14578545214'}>
                            {item?.phone}
                          </a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Cell:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: 'black', textDecoration: 'none' }} href={''}>
                            {item?.cell}
                          </a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Notes:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item?.remark}
                        </Typography>
                      </Grid>
                    </Grid>
                    {[1].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                  </>
                ))}
              </TabPanel>
              {/* <TabPanel value={valueOne} index={0}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Taxes
                </Typography>
                {
                  [1, 2, 3].map((item) => (
                    <>
                      <Grid sx={{ marginLeft: 6 }} container>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Tax Name:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {`Demo Tex ${item}`}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Tax (Percentage):
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {'5%'}
                          </Typography>
                        </Grid>
                      </Grid>
                      {[1, 2].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                    </>
                  ))
                }
              </TabPanel>
              <TabPanel value={valueOne} index={1}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Fees
                </Typography>
                {
                  [1, 2].map((item) => (
                    <>
                      <Grid sx={{ marginLeft: 6 }} container>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Fee Name:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {`Demo Fee ${item}`}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Fee (Percentage):
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {'3%'}
                          </Typography>
                        </Grid>
                      </Grid>
                      {[1, 2].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                    </>
                  ))
                }
              </TabPanel> */}
              <TabPanel value={valueOne} index={2}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Additional Fields
                </Typography>
                {[1].map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: '10px' }} md={2} sm={6} xs={6} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Additional Field Name:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={3} sm={6} xs={6} item>
                        <Typography variant="body1" color="initial">
                          {`Demo Field ${item}`}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={3} sm={6} xs={6} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Additional Field Value (Percentage):
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} sm={6} xs={6} item>
                        <Typography variant="body1" color="initial">
                          {'1%'}
                        </Typography>
                      </Grid>
                    </Grid>
                    {[1].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                  </>
                ))}
              </TabPanel>
            </Box>
          </Card>
          <Typography mt={'30px'} variant="h6" color="initial">
            Billable Line Items
          </Typography>
          <Card style={{ marginTop: '30px' }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={valueTwo} onChange={handleChangetwo} aria-label="basic tabs example">
                  <Tab label="Labour Costs" {...a11yProps(0)} />
                  <Tab label="Equipment and Materials" {...a11yProps(1)} />
                  <Tab label="Fixed Price" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={valueTwo} index={0}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Labour Costs
                </Typography>
                {taskData?.billing_line_items?.labour_item?.labour_cost_items?.map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Cost Item:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.costItem}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Name:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.unit}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit Cost:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.unitCost}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Budget Quantity:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.unitCost}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Hourly Cost:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.hourlyCost}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={6} xs={12} item>
                        <div style={{ display: 'flex' }}>
                          {item.text_exemptLabour ? (
                            <CheckBoxOutlinedIcon sx={{ marginRight: 2 }} />
                          ) : (
                            <CheckBoxOutlineBlankOutlinedIcon sx={{ marginRight: 2 }} />
                          )}
                          <Typography mb={'5px'} variant="h6" color="initial">
                            Tax Exempt
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {taskData?.billing_line_items?.labour_item?.labour_cost_items?.length > 1 && (
                      <Divider sx={{ marginBlock: 3 }} />
                    )}
                  </>
                ))}
              </TabPanel>
              <TabPanel value={valueTwo} index={1}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Other Cost Line Items (Incidentals, Equipment, Materials)
                </Typography>
                {taskData?.billing_line_items?.equipment_item?.equipment_cost_items?.map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Cost Item:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.costItem}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Name:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.unit}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit Cost:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.unitCost}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={6} xs={12} item>
                        <div style={{ display: 'flex' }}>
                          {item.text_exempt ? (
                            <CheckBoxOutlinedIcon sx={{ marginRight: 2 }} />
                          ) : (
                            <CheckBoxOutlineBlankOutlinedIcon sx={{ marginRight: 2 }} />
                          )}
                          <Typography mb={'5px'} variant="h6" color="initial">
                            Tax Exempt
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {taskData?.billing_line_items?.equipment_item?.equipment_cost_items?.length > 1 && (
                      <Divider sx={{ marginBlock: 3 }} />
                    )}
                  </>
                ))}
              </TabPanel>
              <TabPanel value={valueTwo} index={2}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Fixed Price Line Items
                </Typography>
                {taskData?.billing_line_items?.fixed_item?.map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Cost Item:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.costItem}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Name:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.unit}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={2} xs={5} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit Cost:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={4} xs={7} item>
                        <Typography variant="body1" color="initial">
                          {item.unitCost}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} md={6} xs={12} item>
                        <div style={{ display: 'flex' }}>
                          {item.text_exempt_Fix ? (
                            <CheckBoxOutlinedIcon sx={{ marginRight: 2 }} />
                          ) : (
                            <CheckBoxOutlineBlankOutlinedIcon sx={{ marginRight: 2 }} />
                          )}
                          <Typography mb={'5px'} variant="h6" color="initial">
                            Tax Exempt
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {taskData?.billing_line_items?.equipment_item?.equipment_cost_items?.length > 1 && (
                      <Divider sx={{ marginBlock: 3 }} />
                    )}
                  </>
                ))}
              </TabPanel>
            </Box>
          </Card>
          <div style={{ marginTop: '30px', display: 'flex' }}>
            {true ? (
              <CheckBoxOutlinedIcon sx={{ marginInline: 2 }} />
            ) : (
              <CheckBoxOutlineBlankOutlinedIcon sx={{ marginInline: 2 }} />
            )}
            <Typography mb={'5px'} variant="h6" color="initial">
              Active
            </Typography>
          </div>
          <Card style={{ marginTop: '30px' }}>
            <Box sx={{ width: '100%' }}>
              <TabPanel>
                <Grid container>
                  <Grid xs={2} item>
                    <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                      Total Estimated Hours:
                    </Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Typography variant="body1" color="initial">
                      {taskData?.estimate_hour}
                    </Typography>
                  </Grid>
                  <Grid xs={2} item>
                    <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                      Total Spent Hours :
                    </Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Typography variant="body1" color="initial">
                      {totalSpentHours}
                    </Typography>
                  </Grid>

                  {renderActionButton(taskData)}
                </Grid>
              </TabPanel>
            </Box>
          </Card>
          <Typography my={'30px'} variant="h6" color="initial">
            DWRs:
          </Typography>
          <TableContainer sx={{ border: '1px solid #cccccc' }} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>DWR</TableCell>
                  <TableCell align="center">Description</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Task hour</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taskData?.dwrs?.map((dwr) => (
                  <TableRow key={dwr.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <Link to={`/dwr/view/${dwr._id}`}>
                        <b>{dwr.dwr_number}</b>
                      </Link>
                    </TableCell>
                    <TableCell align="center">{dwr.description}</TableCell>
                    <TableCell align="center">{dwr.submit_date}</TableCell>
                    <TableCell align="center">{dwr.task_hour}</TableCell>
                    <TableCell align="center">{getStatus(dwr.status)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {loading && (
                <div>
                  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </>
  );
};

export default TaskView;
