// Title: Extent of metering of water connections
import React, { useCallback, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import StateCreateForm from './StateCreateForm';

const { PRIVATE_ROUTE } = API_ROUTES;

function StateForm() {
  const {id} = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setValue,
    control
  } =  useForm( {
    defaultValues: {
      name: '',
      remark: '',
    }
  });

  const getStateFormById = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_STATE_BY_ID}/${id}`
    );
    setValue('name', data?.data[0].name);
    setValue('remark', data?.data[0].remark);
  }, [authAxios, id, setValue]);

  useEffect(() => {
    if (id) {
      getStateFormById();
    }
  }, [getStateFormById, id]);

  const onSubmit = async (data) => {
    if (id) {
      const response = await authAxios.post(
        `${PRIVATE_ROUTE?.EDIT_STATE}${id}`,
        data
      );
      toast.success(response.data.massage);
      navigate('/master/state/list');
    } else {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_STATE, data);

      if (response.status === 200) {
        toast.success(response.data.massage);
        navigate('/master/state/list');
      }
      else {
        toast.error(response.data.massage);
      }
    }
    setValue('name', '');
    setValue('remark', '');
  };
  return (
    <StateCreateForm
      register={register}
      setValue={setValue}
      getValues={getValues}
      handleSubmit={handleSubmit}
      errors={errors}
      onSubmit={onSubmit}
      params={id}
      loading={isSubmitting}
      isEdit={id?"true":"false"}
      control={control}
    />
  );
}

export default StateForm;
