import React, { useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, Input, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

import { Controller } from 'react-hook-form';
import Dropdown from 'src/components/customecomponent/Dropdown';
import { Label } from '@mui/icons-material';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';
import { get } from 'lodash';
// ----------------------------------------------------------------------
export default function CostItemCreateForm({
  register,
  errors,
  isEdit,
  setValue,
  handleSubmit,
  onSubmit,
  params,
  getValues,
  loading,
  control,
  categorydata,
}) {
  const navigate = useNavigate();
  const [textAreaCount, settextAreaCount] = React.useState(0);
  const [categoryvalue, setcategoryvalue] = React.useState("");
  const [taxexempt, settaxexempt] = React.useState();
  const [active, setactive] = React.useState();

  const { id } = useParams();
  const subcategorydata = categorydata?.filter(item => item.name === categoryvalue)
  return (
    <form autoComplete="off" noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack padding={3}>
      {
          params ? 
          <>
        <Typography variant="h4" gutterBottom>
        Edit Cost Item
        </Typography> </> : 
        <> <Typography variant="h4" gutterBottom>
        Create Cost Item
        </Typography>
        </>
        }
        <Grid container rowSpacing={2} spacing={5}>
          <Grid md={6} xs={12} item>
            <FormLabel ><>Name <span style={{ color: "red" }} >*</span></></FormLabel>
            <TextField
              required
              fullWidth
              type="text"
              name='name'
              {...register('name', {
                required: '*Field is required and needs to be a text',
              })}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength:50 }}
            />
          </Grid>
          <Grid md={6} xs={12} item>
            <FormLabel> <>Unit <span style={{ color: "red" }} >*</span></></FormLabel>
            <TextField
              required
              fullWidth
              type="text"
              name='unit'
              {...register('unit', {
                required: '*Field is required and needs to be a text',
              })}
              error={Boolean(errors.unit)}
              helperText={errors.unit?.message}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength:50 }}
            />
          </Grid>
          <Grid md={6} xs={12} item>
            <FormLabel> <>Unit Cost<span style={{ color: "red" }} >*</span></></FormLabel>
            <TextField
              required
              fullWidth
              type="number"
              name='unitCost'
              onInput={(e) => {
                e.target.value = e.target.value.toString()?.slice(0, 50)
              }}
              min={0}
              {...register('unit_cost', {
                required: '*Field is required and needs to be a text',
                pattern: {
                  value: /^[0-9]/,
                  message: 'invalid input type'
                }
              })}
              error={Boolean(errors.unit_cost)}
              helperText={errors.unit_cost?.message}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength:50 }}
            />
          </Grid>
          <Grid md={6} item xs={12}>
            <FormLabel>Hourly Cost<span style={{ color: "red" }} >*</span></FormLabel>
            <TextField
              fullWidth
              type="number"
              name='hourlyCost'
              onInput={(e) => {
                e.target.value = e.target.value.toString()?.slice(0, 50)
              }}
              min={0}
              {...register('hourly_cost', {
                required: '*Field is required and needs to be a text',
                pattern: {
                  value: /^[0-9]/,
                  message: 'invalid input type'
                }
              })}
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.hourly_cost)}
              helperText={errors.hourly_cost?.message}
              inputProps={{ maxLength:50 }}
            />
          </Grid>
          <Grid md={6} item xs={12}>
            <FormLabel >Gl Code<span style={{ color: "red" }} >*</span></FormLabel>
            <TextField
              fullWidth
              type="text"
              name='GLCode'
              {...register('gl_code', {
                required: '*Field is required and needs to be a text',
              })}
              InputLabelProps={{ shrink: true }}
              helperText={errors.gl_code?.message}
              error={Boolean(errors.gl_code)}
              inputProps={{ maxLength:50 }}
            />
          </Grid>
          <Grid md={6} item xs={12}>
            <FormLabel> <>Category<span style={{ color: "red" }} >*</span></></FormLabel>
            <ChipDropdown fullWidth getValues={getValues} categoryvalue={setcategoryvalue} state={"category"} checked={"checked"} setValue={setValue} data={categorydata?.map((item) => { return { 'label': item.name, 'id': item._id } })} register={register} validation={true} errors={errors} />
          </Grid>
          <Grid md={6} item xs={12}>
            <FormLabel> <>Sub Category<span style={{ color: "red" }} >*</span></></FormLabel>
            <ChipDropdown required getValues={getValues} state={'sub_category'} checked={"checked"} setValue={setValue} data={categoryvalue !== "" ? subcategorydata[0]?.sub_category?.map((item) => { return { 'label': item, 'id': item } }) : [{ label: "No Data Found", id: "" }]} register={register} validation={true} errors={errors} />
          </Grid>
          <Grid xs={12} md={6} item>
            <Grid>
              <FormLabel>Remarks</FormLabel>
            </Grid>
            <TextField
              multiline
              rows={3}
              fullWidth
              {...register('remark')}
              onChange={(e, val) => {
                settextAreaCount(e.target.value.length)
                setValue("remark", e.target.value)
              }}
              inputProps={{ maxLength: 300 }}
              InputLabelProps={{ shrink: true }}
            />
            <Grid item sx={{ fontSize: "12px", textAlign: "end" }} xs={12}> {textAreaCount} / 300</Grid>
          </Grid>
        </Grid>
        <FormGroup>
          <FormControlLabel control={
            <>
            <Checkbox
              defaultChecked={params && getValues('tax_exempt') ? true : false}
              checked={getValues('tax_exempt')?true:false}
              onChange={(e,val) => {
                  setValue('tax_exempt',val);
                  settaxexempt(val);
                }}
                />
                </>
          } label="Tax exempt"  
            />
        </FormGroup>
        <FormGroup>
          <FormControlLabel control={
            <>
            <Checkbox
              defaultChecked={params && getValues('active')?true:false}
              checked={getValues('active')?true:false}
              onChange={(e,val) => {
                  setValue('active',val);
                  setactive(val)
                }}
                />
                </>
          } label="Active"  
            />
        </FormGroup>
       
        <Grid sx={{ display: "flex" }} >
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} style={{ width: 300 }}>
            Submit
          </LoadingButton>
          {params && (
            <Button
              style={{ width: 300, marginLeft: '20px' }}
              variant="contained"
              size="large"
              color="error"
              fullWidth
              onClick={() => {
                navigate('/master/costitem/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form>
  );
}

CostItemCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func
};
