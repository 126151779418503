import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider, createTheme, Box, CircularProgress, Backdrop
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Link, useNavigate } from 'react-router-dom';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import clsx from 'clsx';
import Scrollbar from "src/components/scrollbar";
import Iconify from '../../../iconify';
import Icon from "src/components/color-utils/Icon";
// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function AdminDailyWorkReportlist() {
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dwrs, setDwrs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);

  const getDwrdata = (query) => {
    let isMounted = true;
    const getDailyWorkReports = async () => {
      const { data } = await authAxios.get(`${PRIVATE_ROUTE?.GET_DWR}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}`);
      if (isMounted) {
        
        setDwrs(data?.data?.map((item, index) => {
          const manager_name = item.project_managers.map((project_manager) => project_manager.first_name+' '+project_manager.last_name);
          
          return (
            {
              _id: item._id,
              dwr_number: <Link to={`/dwr/view/${item._id}`}><b>{item?.dwr_number}</b></Link>,
              task_number: item.taskdata.number_str, 
              task_name: item.task_name,
              job_number:item.job_number,
              project_manager: manager_name.join(", "),
              submitted: <Icon color={item?.submit_status === true ? "green" : "red"} name="submit_status" size={30} />,
              task_hour: item?.task_hour,
              end_date: item?.end_date,
              remark: item?.remark,
              status: item.status ?? 0,
              user_name : item?.user_firstname+' '+item?.user_lastname,
            })
        }));
        setCount(data.total);
        setLoading(false);
      }
    };

    getDailyWorkReports().catch((err) => {
      if (!isMounted) return;
      console.error("failed to fetch data", err);
      setLoading(false);
    });
    return () => {
      isMounted = false;
    };
  }

  useEffect(() => {
    getDwrdata("");
  }, [rowsPerPage, page]);
  
  const columns = [
    {
      name: "_id",
      label: "Name",
      options: {
        draggable: true,
        sort: true,
        filter: false,
        display: false
      }
    },
    {
      name: "dwr_number",
      label: "DWR Number",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: "task_number",
      label: "Task Number",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: "task_name",
      label: "Task Name",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: `job_number`,
      label: "Job Number",
      options: {
        draggable: false,
        sort: true,
        filter: true,
        display: true,
      }
    },
    {
      name: `user_name`,
      label: "Employee Name",
      options: {
        draggable: false,
        sort: true,
        filter: true,
        display: true,
      }
    },
    {
      name: "project_manager",
      label: "Project Manager",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      }
    },
    {
      name: "submitted",
      label: "Submitted",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      }
    }, 
    {
      name: "task_hour",
      label: "Task Hour",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const renderSpan = () => {
            let spanContent = '';
            if (!value) {
              spanContent = (
              <span>
                Pending
            </span>);
            }
            if (value === 1) {
              spanContent = (<span>
                Approved
              </span>);
            }
            if (value === 2) {
              spanContent =  (<span>
                Rejected
              </span>);
            }
            return spanContent;
          };

          return (
            <div>
              {renderSpan()}
            </div>
          );
        },
      }
    },
    {
      name: "remark",
      label: "Remark",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: false,
      }
    },
    {
      name: "Action",
      lable: "Action",
      options: {
        sort:false,
        filter: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Link to={`/dwr/view/${tableMeta.rowData[0]}`}>
                View
            </Link>
          );
        },
      },
    },
  ];

  const options = {
    selectToolbarPlacement: 'none',
    tableId: "HelloWorld",
    rowHover: false,
    jumpToPage: true,
    responsive: "standard",
    print: true,
    fixedHeader: true,
    searchOpen: true,
    filter: false,
    count: count,
    selectableRows: 'none',
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',

        },
      };
    },
    setTableProps: () => {
      return {

        border: "1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)",
        size: 'small',
      };
    },
    onSearchChange: (searchQuery) => {
      if (searchQuery !== null && searchQuery.length > 2) {
        getDwrdata(searchQuery);
        setLoading(true);
      }
      if (searchQuery === null) {
        getDwrdata("");
      }
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setPage(tableState.page);
      }
    }
  };
                                                                        
  return (
    <>
      <Grid sx={{ p: 4, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Daily Work Report
          </Typography>
          {/* <IconButton variant="contained" Icon={<CalendarMonth  />} /> */}
        </Stack>
        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
              {loading&&
                  <div>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              }
                <MUIDataTable
                  options={options}
                  columns={columns}
                  data={dwrs}
                />

                {!dwrs && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </>
  );
}
