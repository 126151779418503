

export const setStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getStorage = (key) => localStorage.getItem(key);

export const removeStorage = (key) => localStorage.removeItem(key);

export const isLoginStorage = () => {
  let app = getStorage(process.env.REACT_APP_PROJECT_NAME);
  app = JSON.parse(app);
  if (app?.token && app?.user) {
    return true;
  }
  return false;
};

export const logoutStorage = () => {
  removeStorage(process.env.REACT_APP_PROJECT_NAME);
  
  return true;
};
