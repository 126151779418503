import React, { useEffect, useRef } from 'react';
import './myMap.css';
import { Map, Marker, NavigationControl, Popup, GeolocateControl } from 'maplibre-gl';

const MyMap = ({
  mapIsReadyCallback,
  location
}) => {
  const mapContainer = useRef(null);

  useEffect(() => {
    const myAPIKey = '012735c1a60e41d8995a38e22247da7c';
    const mapStyle =
      'https://maps.geoapify.com/v1/styles/osm-carto/style.json';

    const initialState = {
      lng: location[0],
      lat: location[1],
      zoom: 15,
    };

    const map = new Map({
      container: mapContainer.current,
      style: `${mapStyle}?apiKey=${myAPIKey}`,
      center: [initialState.lng, initialState.lat],
      zoom: initialState.zoom,
    });
    const nav = new NavigationControl();
    map.addControl(nav, 'top-right');
    const marker = new Marker({
      color: "#65C8D0",
    }).setLngLat(location)
      .setPopup(new Popup().setHTML("<h4>6451 184 STREET, Surrey, British Columbia</h4>"))
      .addTo(map);

    mapIsReadyCallback(marker);
  }, [mapContainer.current]);

  return <div style={{ width: "75%", height: "250px" }} className="map-container" ref={mapContainer} />;
};

export default MyMap;