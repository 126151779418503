import { useContext, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';

// components
import { AuthContext } from 'src/context/AuthContext';
import { authService } from 'src/services/auth.service';
import toast from 'react-hot-toast';
import { LOGIN } from 'src/context/constatnts';
import Iconify from '../../../components/iconify';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [usercheck, setusercheck] = useState([]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (formData) => {
      const { statusCode, massage, token, user,loginTime } = await authService.loginApi(formData);
      setusercheck(user?.user?.role)
    
      if (statusCode === 200) {
        toast.success(massage);
        dispatch({
          type: LOGIN,
          payload: {
            token,
            massage,
            statusCode,
            user,
            loginTime
          }
        }); 
        navigate('/dashboard/app', { replace: true });
  
      } else {
        toast.error(massage);
      }
    }
  });
  const { errors, touched, /* values, */ isSubmitting, handleSubmit, getFieldProps } = formik;

  return (

    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} >
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            variant="filled"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            name="password"
            autoComplete="current-password"
            label="Password"
            variant="filled"
            type={showPassword ? 'text' : 'password'}
            {...getFieldProps('password')}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>

          <Link to={`/reset-password`} variant="subtitle2" underline="hover">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>

  );
}
