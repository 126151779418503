import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider, createTheme, Box, CircularProgress, Backdrop
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { json, Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Scrollbar from "src/components/scrollbar";
import Iconify from '../../../iconify';
import { string } from "prop-types";
import Icon from "src/components/color-utils/Icon";

// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function ManagersideQuoteList() {
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);

  const navigate = useNavigate();
  // const handleOpenMenu = (event, id) => {
  //   setOpen(event.currentTarget);
  //   setSelectedRow(id);
  // };
  const handleEditRedirect = (e,id) => {
    setOpen(null);
    setSelectedRow(null);
    navigate(`/manager/quote/update/${id}`);
  }
  const handleRowDelete = async (e,id) => {
    if (confirm("Are you sure want to delete?") === true) {
      setLoading(false)
    await authAxios.post(`${PRIVATE_ROUTE?.DELETE_QUOTE}${id}`);
    getQuoteData("");
    setOpen(null);
    setLoading(true)
    setSelectedRow(null);
    }
  }
  // const handleCloseMenu = () => {
  //   setOpen(null);
  //   setSelectedRow(null);
  // };
  const handlecreateform = () => {
    navigate('/manager/quote/create');
  };
  const getQuoteData = (query) => {
    let isMounted = true;
    const getQuotes = async () => {
      const { data } = await authAxios.get(`${PRIVATE_ROUTE?.GET_QUOTE}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}`);
      if (isMounted) {
        setQuotes(data?.data?.map((item, index) => {
          return (
            {
              id: item?._id,
              quoteid: item?.number.toString().padStart(6, '0'),
              quote: <Link to={`/manager/quote/view/${item?._id}`}><b>{item.quote_number}</b></Link>,
              client: item.client_name,
              ratesheet: item.ratesheet_name,
              name: item.name,
              estimatedhour: item.total_estimated_hour,
              materialItem: item.billable_line_items?.materialItem === undefined || item.billable_line_items.materialItem.length === 0 ? 'No' : 'Yes',
              fixItem: item.billable_line_items?.fixItem === undefined || item.billable_line_items.fixItem.length === 0 ? 'No' : 'Yes',
              active: <Icon color={item.active === true ? "green" : "red"} name="people" size={30} />,
              isConverted : item?.is_converted ? item?.is_converted : 0,
            })
        }))
        setCount(data.total);
        setLoading(false);
      }
    };
    getQuotes().catch((err) => {
      if (!isMounted) return;
      console.error("failed to fetch data", err);
      setLoading(false);
    });
    getQuotes()
    return () => {
      isMounted = false;
    };
  }
  useEffect(() => {
    getQuoteData("");
  }, [rowsPerPage, page]);


  const columns = [

    {
      name: "id",
      label: "_id",
      options: {
        draggable: true,
        sort: true,
        filter: false,
        display: false
      }
    },
    {

      name: "quoteid",
      label: "id",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: "quote",
      label: "Quote Number",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: "client",
      label: "Client",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: "ratesheet",
      label: "Ratesheet",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    }, {
      name: "name",
      label: "Name",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: "estimatedhour",
      label: "Total Estimated Hour",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: "isConverted",
      label: "Status Converted",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const renderSpan = () => {
            let spanContent = '';
            if (!value) {
              spanContent = (
              <span>
                Not Converted
            </span>);
            }
            if (value === 1) {
              spanContent = (<span>
                Converted
              </span>);
            }
            return spanContent;
          };

          return (
            <div>
              {renderSpan()}
            </div>
          );
        },
      }
    },
    {
      name: "active",
      label: "Active",
      options: {
        draggable: false,
        sort: true,
        filter: true,
        display: true,
      }
    },
    {
      name: "",
      lable: "Action",
      options: {
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // <IconButton color="inherit" onClick={(e) => handleOpenMenu(e, tableMeta.rowData[0])}>
            //   <Iconify width={15} icon={'eva:more-vertical-fill'} />
            // </IconButton>
            <div style={{ display: 'flex', gap: '5px' }}>
            <EditIcon onClick={(e) => handleEditRedirect(e, tableMeta.rowData[0])} />
            <DeleteIcon onClick={(e) => handleRowDelete(e, tableMeta.rowData[0])} />
          </div>
          );
        },
      },
    },
  ];

  const options = {
    selectToolbarPlacement: 'none',
    tableId: "HelloWorld",
    rowHover: false,
    jumpToPage: true,
    responsive: "standerd",
    print: true,
    filter: false,
    fixedHeader: true,
    searchOpen: true,
    count: count,
    selectableRows: false,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15) 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',
        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: "1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15) 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)",
        size: 'small',
      };
    },
    onSearchChange: (searchQuery) => {
      if (searchQuery !== null && searchQuery.length > 2) {
        getQuoteData(searchQuery);
        setLoading(true);
      }
      if (searchQuery === null) {
        getQuoteData("");
      }
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setPage(tableState.page);
      }
    }
  };

  return (
    <>
      <Grid sx={{ p: 4, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
          Quote
          </Typography>
          <Button variant="contained" onClick={handlecreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Quote
          </Button>
        </Stack>
        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MUIDataTable
                  options={options}
                  columns={columns}
                  data={quotes}
                />
                {/* <Popover
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                      },
                    },
                  }}
                >
                  <MenuItem onClick={handleEditRedirect}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                  </MenuItem>

                  <MenuItem onClick={handleRowDelete} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                  </MenuItem>
                </Popover> */}
                {loading && (
                  <div>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                   
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
                )}

                {!quotes && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>


    </>
  );
}
