import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation, useRoutes } from 'react-router-dom';
// @mui
import StarBorder from '@mui/icons-material/StarBorder';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Collapse, Grid, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], openmenu, ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} openmenu={openmenu} />
        ))}
      </List>
    </Box>
  );
}


NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item ,openmenu }) {
  const location = useLocation();
  const { title, path, icon, info } = item;
  const [open, setOpen] = useState(false);
 
  const handleClick = () => {
    if(!item?.child){
      setOpen((prev) => prev);
    }
    setOpen(!open)
  };
useEffect(() => {
  if(location.pathname.split('/')[1] === "master" ){
    setOpen(true)
  }
}, [])
  return (
    <>
      <StyledNavItem
        onClick={item.child && handleClick}
        component={RouterLink}
        to={item.path}
        sx={item.child ? null : {
          '&.active': {
            color: '#65C8D0',
            bgcolor: 'rgba(101, 200, 208,0.15)',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

        <ListItemText disableTypography primary={!openmenu ? title :""} />
        {!openmenu ?
        <div style={{ marginRight: 15 }}>
          {item.child ? open ? <ExpandLess /> : <ExpandMore /> : null}
        </div>
       : ""}
        {info && info}
      </StyledNavItem>
      {
        item.child ?

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List sx={{ marginLeft: 2 }} component="div" disablePadding>

              {
                item?.child?.map((subitem) => (
                  <StyledNavItem
                    component={RouterLink}
                    to={subitem.path}
                    sx={{
                      '&.active': {
                        color: '#65C8D0',
                        bgcolor: 'rgba(101, 200, 208,0.15)',
                        fontWeight: 'fontWeightBold',
                      },
                    }}
                  >
                    <StyledNavItemIcon >{subitem.icon &&  subitem.icon}</StyledNavItemIcon>

                    <ListItemText disableTypography >{!openmenu ? subitem.title :""}</ListItemText>

                    {info && info}
                  </StyledNavItem>  
                ))
              }
            </List>
          </Collapse>
          : ""}

    </>
  );
}
