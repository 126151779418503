import { Button, FormLabel, Grid, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';

import { Controller } from 'react-hook-form';
import React from 'react';
import Dropdown from 'src/components/customecomponent/Dropdown';
// ----------------------------------------------------------------------
export default function CityCreateForm({
  register,
  errors,
  isEdit,
  handleSubmit,
  getValues,
  setValue,
  onSubmit,
  params,
  stateData,
  loading,
  control
}) {
  const navigate = useNavigate();
  let [textAreaCount, settextAreaCount] = React.useState(0);
  

  return (
    <form autoComplete="off" noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3} padding={3}>
        {
          params ? <>
          <Typography variant="h4" gutterBottom>
          Edit  City
        </Typography> </> : <> <Typography variant="h4" gutterBottom>
          Create  City
        </Typography></>
        }
        <Grid>
        <Grid container spacing={2}>
            <Grid xs={12} md={6} item>
             <Grid> 
            <FormLabel><>Name <span style={{ color: "red" }} >*</span></></FormLabel>  
            </Grid>
            <TextField
            fullWidth
            type="text"
            {...register('name', {
              required: '* Field is required and needs to be a text',
            })}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength:50 }}
          />
          </Grid>
          <Grid xs={12} md={6}  item>{''}</Grid>
          <Grid xs={12} md={6} item>
          <FormLabel>
            <>State <span style={{ color: "red" }} >*</span></>
            </FormLabel>  
            <ChipDropdown  sx = {{ width :'50%' }} getValues={getValues} state={'state'} secondstate={"state_id"} setValue={setValue} data={stateData?.map((item)=>{
              return{'label':item.name,'id':item._id}})} register={register} validation={true} errors={errors} checked={"checked"} />
            </Grid>
            <Grid xs={12} md={6} item>{''}</Grid>
        <Grid item xs={12} md={6}>
          <Grid>
          <FormLabel>
            Remarks
            </FormLabel> 
            </Grid>
            <TextField
              multiline
              fullWidth
              rows={3}
            {...register("remark")}
              onChange={(e, val) => {
                settextAreaCount(e.target.value.length)
                setValue("remark", e.target.value)
              }}
              inputProps={{ maxLength: 300 }}
              InputLabelProps={{ shrink: true }}
              />
          <Grid item sx={{ fontSize: "12px", textAlign: "end" }}  xs={12} > {textAreaCount} / 300</Grid>
        </Grid>
        <Grid item xs={12} md={6}>{''}</Grid>
              </Grid>
              </Grid>
        <Grid sx={{ display: "flex" }} >
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} style={{ width: 300 }}>
            Submit
          </LoadingButton>
          {params && (
            <Button
              style={{ width: 300, marginLeft: "20px" }}
              variant="contained"
              size="large"
              color="error"
              fullWidth
              onClick={() => {
                navigate('/master/city/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form>
  );
}

CityCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func
};
