import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider, createTheme, Box, CircularProgress
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Scrollbar from "src/components/scrollbar";
import Iconify from '../../../iconify';
// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function ManagerDailyWorkReportlist() {
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);

  const navigate = useNavigate();
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedRow(id);
  };
  const handleEditRedirect = () => {
    setOpen(null);
    setSelectedRow(null);
    navigate(`/manager/dwr/update/${selectedRow}`);
  }
  const handleRowDelete = async () => {
    await authAxios.post(`${PRIVATE_ROUTE?.DELETE_JOB}${selectedRow}`);
    getUserdata()
    setOpen(null);
    setSelectedRow(null);
  }
  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedRow(null);
  };

  const handlejobcreateform = () => {
    navigate('/manager/dwr/create');
  }

  const getUserdata = () => {
    let isMounted = true;
    const getUsers = async () => {
      const { data } = await authAxios.get(`${PRIVATE_ROUTE?.GET_JOB}?page=${page + 1}&per_page=${rowsPerPage}`);
      if (isMounted) {
        
        setUsers(data?.data.map((item,index) => {
          return (
            {
              _id:item._id,
              job_number: (287 + index).toString().padStart(4,'0'),
              client: (141 + index).toString().padStart(5,'0'),
              job_discription: item.job_discription,
              company_name: item.company_name,
              address: `${item.site_address_line_1}, ${item.site_address_line_2}, ${item.zip_code}- ${item.city}, ${item.state}`,
              geolocation: <a rel="noreferrer" target='_blank' href={`https://www.openstreetmap.org/?mlat=${item.latitude}&mlon=${item.logitude}#map=14/${item.latitude}/${item.logitude}`}>{item.latitude},{item.logitude}</a>,
              start_date: item.start_date,
              end_date: item.end_date,
              remark: item.remark,
            })
        }));
        setCount(data.total);
        setLoading(false);
      }
    };

    getUsers().catch((err) => {
      if (!isMounted) return;
      console.error("failed to fetch data", err);
      setLoading(false);
    });
    return () => {
      isMounted = false;
    };
  }
  useEffect(() => {
    getUserdata()
  }, [rowsPerPage, page]);

  const columns = [
    {
      name: "_id",
      label: "Name",
      options: {
        draggable: true,
        sort: true,
        filter: false,
        display: false
      }
    },
    {
      name: "job_number",
      label: "Job Number",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: "client",
      label: "Client",
      options: {
        draggable: false,
        sort:true,
        filter: true,
        display: true,
      }
    },
    {
      name: "job_discription",
      label: "Job Discription",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: `address`,
      label: "Address",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      }
    },
    {
      name: "geolocation",
      label: "Geolocation",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      }
    },
    {
      name: "start_date",
      label: "Start Date",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      }
    }, {
      name: "end_date",
      label: "End Date",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      }
    },
    {
      name: "remark",
      label: "Remark",
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: false,
      }
    },
    {
      name: "",
      lable: "Action",
      options: {
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton color="inherit" onClick={(e) => handleOpenMenu(e, tableMeta.rowData[0])}>
              <Iconify width={15} icon={'eva:more-vertical-fill'} />
            </IconButton>
          );
        },
      },
    },
  ];


  const options = {
    selectToolbarPlacement: 'none',
    tableId: "HelloWorld",
    rowHover: false,
    jumpToPage: true,
    responsive: "standerd",
    filters: false,
    print: false,
    fixedHeader: true,
    count: count,
    selectableRows: false,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',

        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: "1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)",
        size: 'small',
      };
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setPage(tableState.page);
      }
    }
  };

  return (
    <>


      <Grid sx={{ p: 4, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
          Daily Work Report
          </Typography>
          <Button variant="contained" onClick={handlejobcreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Daily Work Report
          </Button>
        </Stack>

        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MUIDataTable
                  options={options}
                  columns={columns}
                  data={users}
                />
                <Popover
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                      },
                    },
                  }}
                >
                  <MenuItem onClick={handleEditRedirect}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                  </MenuItem>

                  <MenuItem onClick={handleRowDelete} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                  </MenuItem>
                </Popover>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {!users && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>


    </>
  );
}
