import * as React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Box,
  useAutocomplete,
  Card,
  TextareaAutosize,
  FormLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Chip,
} from '@mui/material';
import { LoadingButton, LocalizationProvider, TabPanel } from '@mui/lab';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useFieldArray } from 'react-hook-form';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';
import Iconify from 'src/components/iconify';
import CancelIcon from '@mui/icons-material/Cancel';
import { createTheme, ThemeProvider } from '@mui/material';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { FormElement } from '@progress/kendo-react-form';
import ClientaddressDropdown from 'src/components/customecomponent/ClientaddressDropdown';
// import { Error } from '@progress/kendo-react-labels';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'red' },
      },
    },
  },
});

export default function ClientCreateForm({
  register,
  setValue,
  getValues,
  errors,
  isEdit,
  handleSubmit,
  stateData,
  onSubmit,
  params,
  loading,
  control,
  clientData,
  ratesheetData,
  paymentData,
  editdata,
  multivalue,
  setmultivalue,
}) {
  const [tabvalue, setTabValue] = React.useState(0);
  const [checkbox, setcheckbox] = React.useState();
  const [isValidate, setIsValidate] = React.useState(true);
  let [textAreaCount, settextAreaCount] = React.useState(0);
  const [categoryvalue, setcategoryvalue] = React.useState('');
  const [attachmentsData, setattachmentsData] = React.useState({});

  const {
    fields: locations,
    remove: removeLocation,
    insert: insertLocation,
  } = useFieldArray({
    control,
    name: 'locations',
  });
  const {
    fields: attachments,
    remove: removeAttachment,
    insert: insertAttachment,
  } = useFieldArray({
    control,
    name: 'attachments',
  });
  const {
    fields: contacts,
    remove: removeContact,
    insert: insertContact,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const navigate = useNavigate();

  const clienttypedata = clientData?.map((item) => {
    return { label: item?.name, id: item?._id };
  });

  const filterstatedata = stateData?.filter((item) => item?.name === categoryvalue);
console.log("filterstatedata",filterstatedata);

  function handleviewimage(base64Url) {
    const win = window.open();
    win?.document.write(`<img src="${base64Url}" ></img>`);
  }

  React.useEffect(() => {
    if (params && editdata?.contacts?.length !== 0) {
      editdata?.contacts?.map((element, i) => {
        handlecreateform();
        handleDelete(contacts?.length);
      });
    }
    if (params && editdata?.attachments?.length !== 0) {
      editdata?.attachments?.map((element, i) => {
        setattachmentsData({
          ...attachmentsData,
          [`${i}`]: `${process.env.REACT_APP_ASSET_URL}/client/attachments/${element.file_name}`,
        });
        handleAttachmentsform();
        handleAttachmentDelete(attachments?.length);
      });
    }
    if (params && editdata?.locations?.length !== 0) {
      editdata?.locations?.map((element, i) => {
        handleLocationform();
        handleLocationDelete(locations?.length);
      });
    }
  }, [editdata]);

  const positiondata = [
    { label: 'Realtor', value: 'Realtor' },
    { label: 'Designer', value: 'Designer' },
    { label: 'Engineer', value: 'Engineer' },
    { label: 'Architect ', value: 'Architect' },
  
  ];

  const handlecreateform = () => {
    insertContact(contacts?.length, {
      firstName: '',
      lastName: '',
      position: '',
      department: '',
      location: '',
      email: '',
      phone: '',
      cell: '',
      remark: '',
    });
  };
  const handleAttachmentsform = () => {
    insertAttachment(attachments?.length, { description: '', image: '' });
    setValue(`attachments.${attachments?.length}.image`, '');
  };

  const handleLocationform = () => {
    insertLocation(locations?.length, {
      locationName: '',
      muncipleAddress: '',
      stateprovince: '',
      stateprovince_id:'',
      postalCode: '',
      city: '',
      city_id:'',
    });
  };

  const multiselect = (event) => {
    console.log(isValidate);
    if (event.target.value.length === 0) {
      setIsValidate(false);
    } else {
      setIsValidate(true);
    }
    setmultivalue([...event.value]);
    setValue('clientType', [...event.value]);
  };

  const handleAttachmentDelete = (index) => {
    removeAttachment(index, 1);
  };
  const handleDelete = (i) => {
    removeContact(i, 1);
  };
  const handleLocationDelete = (i) => {
    removeLocation(i, 1);
  };
  const handleDeleteImage = (i) => {
    setattachmentsData({ ...attachmentsData, [`${i}`]: '' });
    setValue(`attachments.${i}.image`, '');

    handleAttachmentsform(attachments?.length);
    handleAttachmentDelete(attachments?.length);
  };

  const handleFile = (e, i) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setValue(`attachments.${i}.image`, reader.result);
      setattachmentsData({ ...attachmentsData, [`${i}`]: reader.result });
      handleAttachmentsform(attachments?.length);
      handleAttachmentDelete(attachments?.length);
    };
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <>
      <form autoComplete="off" className="k-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack padding={3}>
          {params ? (
            <>
              <Typography variant="h4" gutterBottom component="h2" sx={{ marginBottom: '20px' }}>
                Edit Client
              </Typography>{' '}
            </>
          ) : (
            <>
              <Typography variant="h4" gutterBottom component="h2" sx={{ marginBottom: '20px' }}>
                Create Client
              </Typography>{' '}
            </>
          )}
          <Grid container rowSpacing={2} spacing={5}>
            <Grid xs={12} item md={6}>
              <FormLabel>
                <>
                  Company Name <span style={{ color: 'red' }}>*</span>
                </>
              </FormLabel>
              <TextField
                fullWidth
                type="text"
                name="companyName"
                {...register('companyName', {
                  required: '*Field is required and needs to be a text',
                })}
                onChange={(e) => { !params ?  setIsValidate(false): setIsValidate(true)} }
                error={Boolean(errors.companyName)}
                helperText={errors.companyName?.message}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid xs={12} item md={6}>
              <FormLabel>
                <>
                  Company Email (Will be used for quickbook integration) <span style={{ color: 'red' }}>*</span>
                </>
              </FormLabel>
              <TextField
                fullWidth
                type="email"
                name="companyEmail"
                {...register('companyEmail', {
                  required: '*Field is required and needs to be a text',
                })}
                onChange={(e) => { !params ?  setIsValidate(false): setIsValidate(true)} }
                error={Boolean(errors.companyEmail)}
                helperText={errors.companyEmail?.message}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid xs={12} item md={6}>
              <FormLabel>Client Type</FormLabel>
              <Grid xs={12} item md={6}>
                <div className="col-12 col-md-6 example-col">
                  <FormElement>
                    <MultiSelect
                      className="sanket-patel"
                      data={clienttypedata?.map((item) => item?.label)}
                      onChange={(e) =>{multiselect(e)}}
                      value={multivalue}        
                      valid={isValidate ? true : false}
                      required={true}
                      style={{ 
                        width: '200%',
                        height: '55px',
                        color: 'transparent',
                      }}
                    />
                    {isValidate ? null : (
                      <p className="k-Invalid-error MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained">
                        *Field is required and  needs to be a select
                      </p>
                    )}
                  </FormElement>
                </div>
              </Grid>
            </Grid>
            {/* <Grid xs={12} item md={6}>
              <InputLabel>Rate Sheet</InputLabel>
              <ChipDropdown
                sx={{ width: '50%' }}
                getValues={getValues}
                state={'ratesheet'}
                secondstate={'ratesheet_id'}
                setValue={setValue}
                data={ratesheetData?.map((item) => {
                  return { label: item.name, id: item._id };
                })}
                register={register}
                validation={false}
                errors={errors}
               
              />
            </Grid> */}
            <Grid xs={12} item md={6}>
              <FormLabel>Payment Terms</FormLabel>
              <ChipDropdown
                sx={{ width: '50%' }}
                getValues={getValues}
                state={'payment'}
                setValue={setValue}
                data={paymentData?.map((item) => {
                  return { label: item.name, value: item._id };
                })}
                register={register} validation={true} errors={errors} checked={"checked"}
              />
            </Grid>
            <Grid xs={12} item md={6}>
              <FormLabel>Remarks</FormLabel>
              <TextField
                fullWidth
                multiline
                rows={3}
                {...register(`remark`)}
                onChange={(e, val) => {
                  settextAreaCount(e.target.value.length);
                }}
                inputProps={{ maxLength: 300 }}
                InputLabelProps={{ shrink: true }}
              />
              <Grid item sx={{ fontSize: '12px', textAlign: 'end' }} xs={12}>
                {' '}
                {textAreaCount} / 300
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{ width: '100%' }} mt={2}>
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabvalue} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Contacts" {...a11yProps(0)} />
                  <Tab label="Billing Address" {...a11yProps(1)} />
                  <Tab label="Attachments" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={tabvalue} index={0}>
                <Typography variant="h6" component="h2">
                  Contact
                </Typography>
                {contacts?.map((ele, index) => (
                  <>
                    <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                      <Grid sx={{ marginRight: '15px' }}>
                        <CancelIcon
                          variant="contained"
                          onClick={() => handleDelete(index)}
                          sx={{ fontSize: '42px', color: 'red' }}
                          startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                          {''}
                        </CancelIcon>
                      </Grid>
                      <Divider orientation="vertical" variant="middle" flexItem />
                      <Grid sx={{ marginLeft: '20px' }}>
                        <Grid container direction={'row'} spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <Grid xs={12} item mt={2} sx={{ width: '60vw' }} md={6}>
                            <FormLabel>
                              <>
                                First Name <span style={{ color: 'red' }}>*</span>
                              </>
                            </FormLabel>
                            <TextField
                              fullWidth
                              type="text"
                              name="firstName"
                              {...register(`contacts.${index}.firstName`, {
                                required: '*Field is required and needs to be a text',
                              })}
                              InputLabelProps={{ shrink: true }}
                              error={Boolean(errors.contacts ? errors.contacts[index]?.firstName : false)}
                              helperText={
                                Boolean(errors.contacts ? errors.contacts[index]?.firstName : false)
                                  ? '*Field is required and needs to be a text'
                                  : null
                              }
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                          <Grid xs={12} item mt={2} md={6}>
                            <FormLabel>
                              <>
                                Last Name <span style={{ color: 'red' }}>*</span>
                              </>
                            </FormLabel>
                            <TextField
                              fullWidth
                              type="text"
                              name="lastName"
                              {...register(`contacts.${index}.lastName`, {
                                required: '*Field is required and needs to be a text',
                              })}
                              InputLabelProps={{ shrink: true }}
                              error={Boolean(errors.contacts ? errors.contacts[index]?.lastName : false)}
                              helperText={
                                Boolean(errors.contacts ? errors.contacts[index]?.lastName : false)
                                  ? '*Field is required and needs to be a text'
                                  : null
                              }
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={'row'} spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <Grid xs={12} item mt={2} md={6}>
                            <FormLabel>Position</FormLabel>
                            <ChipDropdown
                              getValues={getValues}
                              state={`contacts.${index}.position`}
                              setValue={setValue}
                              data={positiondata}
                              // register={register}
                              validation={false}
                              errors={null}
                            />
                          </Grid>
                          <Grid xs={12} item mt={2} md={6}>
                            <FormLabel>Department</FormLabel>
                            <TextField
                              fullWidth
                              type="text"
                              name="department"
                              {...register(`contacts.${index}.department`)}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={'row'} spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <Grid xs={12} item mt={2} md={6}>
                            <FormLabel>Location</FormLabel>
                            <TextField
                              fullWidth
                              type="text"
                              name="location"
                              {...register(`contacts.${index}.location`)}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                          <Grid xs={12} item mt={2} md={6}>
                            <FormLabel>Email</FormLabel>
                            <TextField
                              fullWidth
                              type="text"
                              name="email"
                              {...register(`contacts.${index}.email`)}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={'row'} spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <Grid xs={12} item mt={2} md={6}>
                            <FormLabel>Phone</FormLabel>
                            <TextField
                              fullWidth
                              type="number"
                              name="phone"
                              onInput={(e) => {
                                e.target.value = e.target.value.toString()?.slice(0, 10);
                              }}
                              min={0}
                              {...register(`contacts.${index}.phone`, {
                                pattern: {
                                  value: /^[0-9]/,
                                  message: 'invalid input type',
                                },
                              })}
                              error={Boolean(errors?.contacts ? errors?.contacts[index]?.phone : null)}
                              helperText={errors?.contacts ? errors?.contacts[index]?.phone?.message : null}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid xs={12} item mt={2} md={6}>
                            <FormLabel>Cell</FormLabel>
                            <TextField
                              fullWidth
                              type="number"
                              onInput={(e) => {
                                e.target.value = e.target.value.toString()?.slice(0, 10);
                              }}
                              min={0}
                              name="cell"
                              {...register(`contacts.${index}.cell`, {
                                pattern: {
                                  value: /^[0-9]/,
                                  message: 'invalid input type',
                                },
                              })}
                              error={Boolean(errors.contacts ? errors?.contacts[index]?.cell : null)}
                              helperText={errors.contacts ? errors?.contacts[index]?.cell?.message : null}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={'row'} spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <Grid xs={12} mt={2} item md={6}>
                            <FormLabel>Notes</FormLabel>
                            <TextField
                              fullWidth
                              type="text"
                              name="remark"
                              {...register(`contacts.${index}.remark`)}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                          <Grid xs={12} mt={2} item md={6}>
                            {''}
                          </Grid>
                        </Grid>
                        <Divider sx={{ marginTop: '20px' }} />
                      </Grid>
                    </Grid>
                  </>
                ))}
                <Grid mt={2}>
                  <Button
                    variant="contained"
                    sx={{ marginTop: '20px' }}
                    onClick={() => handlecreateform(contacts.length)}
                    startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                  >
                    Add Contact
                  </Button>
                </Grid>
              </TabPanel>
              <TabPanel value={tabvalue} index={1}>
                <Typography variant="h6" component="h2">
                  Billing Address
                </Typography>
                {locations?.map((ele, i) => (
                  <>
                    <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                      <Grid sx={{ marginRight: '15px' }}>
                        <CancelIcon
                          key={i}
                          onClick={() => handleLocationDelete(i)}
                          sx={{ fontSize: '42px', color: 'red' }}
                          startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                          {''}
                        </CancelIcon>
                      </Grid>
                      <Divider orientation="vertical" variant="middle" flexItem />
                      <Grid sx={{ marginLeft: '20px' }}>
                        <Grid container spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <Grid xs={12} md={6} item mt={2} sx={{ width: '60vw' }}>
                            <FormLabel>
                              <>
                                Location Name <span style={{ color: 'red' }}>*</span>
                              </>
                            </FormLabel>
                            <TextField
                              fullWidth
                              type="text"
                              name="locationName"
                              {...register(`locations.${i}.locationName`, {
                                required: '*Field is required and needs to be a text',
                              })}
                              InputLabelProps={{ shrink: true }}
                              error={Boolean(errors.locations ? errors.locations[i]?.locationName : false)}
                              helperText={
                                Boolean(errors.locations ? errors.locations[i]?.locationName : false)
                                  ? '*Field is required and needs to be a text'
                                  : null
                              }
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                          <Grid xs={12} md={6} item mt={2}>
                            <FormLabel>Munciple Address </FormLabel>
                            <TextField
                              fullWidth
                              type="text"
                              name="muncipleAddress"
                              {...register(`locations.${i}.muncipleAddress`)}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={'row'} spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <ClientaddressDropdown
                          index ={i}
                           categoryvalue={setcategoryvalue}
                           getValues={getValues}
                           state={`locations.${i}.stateprovince`}
                           secondstate={`locations.${i}.stateprovince_id`}
                           city={`locations.${i}.city`}
                           secondcity={`locations.${i}.city_id`}
                           setValue={setValue}
                           data={stateData}
                           validation={false}
                           errors={null}
                          />
                        </Grid>
                        <Grid container direction={'row'} spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <Grid xs={12} md={6} mt={2} item>
                            <FormLabel>Postal Code </FormLabel>
                            <TextField
                              fullWidth
                              type="text"
                              onInput={(e) => {
                                e.target.value = e.target.value.toString()?.slice(0, 50);
                              }}
                              min={0}
                              {...register(`locations.${i}.postalCode`, {})}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                        </Grid>
                        <Divider sx={{ marginTop: '15px' }} />
                      </Grid>
                    </Grid>
                  </>
                ))}
                <Grid mt={2}>
                  <Button
                    variant="contained"
                    sx={{ marginTop: '20px' }}
                    onClick={(e) => handleLocationform(locations.length)}
                    startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                  >
                    Add Billing Address
                  </Button>
                </Grid>
              </TabPanel>
              <TabPanel value={tabvalue} index={2}>
                <Typography variant="h6" component="h2">
                  Attachments
                </Typography>
                {attachments?.map((ele, i) => (
                  <>
                    <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                      <Grid sx={{ marginRight: '15px' }}>
                        <CancelIcon
                          key={i}
                          onClick={() => handleAttachmentDelete(i)}
                          sx={{ fontSize: '42px', color: 'red' }}
                          startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                          {''}
                        </CancelIcon>
                      </Grid>
                      <Divider orientation="vertical" variant="middle" flexItem />
                      <Grid sx={{ marginLeft: '20px' }}>
                        <Grid container spacing={5}>
                          <Grid item mt={2} sx={{ width: '60vw' }}>
                            <Grid>
                              <Typography variant="h6" component="h2">
                                Attachment
                              </Typography>
                            </Grid>
                            <Grid sx={{ marginTop: '10px' }} md={6} xs={12}>
                              {getValues(`attachments.${i}.image`) !== '' ? (
                                <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                  <Grid item md={4} xs={12}>
                                    <Button
                                      variant="contained"
                                      component="label"
                                      onClick={() => handleviewimage(attachmentsData[`${i}`])}
                                      key={i}
                                    >
                                      view image
                                    </Button>
                                  </Grid>
                                  <Grid item md={4} xs={12}>
                                    <Button variant="contained" component="label" key={i}>
                                      <a
                                        href={attachmentsData[`${i}`]}
                                        download
                                        rel="noreferrer"
                                        target={'_blank'}
                                        style={{ textDecoration: 'none', color: 'white' }}
                                        label="download"
                                      >
                                        {'download'}
                                      </a>
                                    </Button>
                                  </Grid>
                                  <Grid item md={4} xs={12}>
                                    <Button
                                      variant="contained"
                                      component="label"
                                      onClick={() => handleDeleteImage(i)}
                                      key={i}
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                </Grid>
                              ) : (
                                <>
                                  <Button sx={{ padding: '6px 40px' }} variant="contained" component="label">
                                    select file
                                    <input
                                      hidden
                                      accept="image/*"
                                      multiple
                                      type="file"
                                      onChange={(e) => handleFile(e, i)}
                                    />
                                  </Button>
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <Grid xs={12} item mt={1} md={6}>
                            <FormLabel>Discription </FormLabel>
                            <TextField
                              fullWidth
                              type="text"
                              name="Discription"
                              {...register(`attachments.${i}.description`)}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Grid>
                        </Grid>
                        <Divider sx={{ marginTop: '15px' }} />
                      </Grid>
                    </Grid>
                  </>
                ))}
                <Grid mt={2}>
                  <Button
                    variant="contained"
                    sx={{ marginTop: '20px' }}
                    onClick={(e) => handleAttachmentsform(attachments.length)}
                    startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                  >
                    Add Attachment
                  </Button>
                </Grid>
              </TabPanel>
            </Card>
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <>
                  <Checkbox
                    defaultChecked={params && getValues('active') ? true : false}
                    checked={getValues('active') ? true : false}
                    onChange={(e, val) => {
                      setValue('active', val);
                      setcheckbox(val);
                    }}
                  />
                </>
              }
              label="Active"
            />
          </FormGroup>
          <Grid sx={{ display: 'flex' }} mt={2}>
            <LoadingButton
              fullWidth
              disabled={isValidate ? false : true}
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              style={{ width: 300 }}
            >
              Submit
            </LoadingButton>

            {params && (
              <Button
                style={{ width: 300, marginLeft: '10px' }}
                variant="contained"
                size="large"
                color="error"
                fullWidth
                onClick={() => {
                  navigate('/client/list');
                }}
              >
                Cancel
              </Button>
            )}
          </Grid>
        </Stack>
      </form>
    </>
  );
}
ClientCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func,
};
