// Title: Extent of metering of water connections

import React, { useCallback, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import CityCreateForm from './CityCreateForm';
import { Backdrop, CircularProgress } from '@mui/material';
import { useState } from 'react';

const { PRIVATE_ROUTE } = API_ROUTES;

function CityForm() {
  const {id} = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const [stateData , setstateData]  = React.useState([])
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit, getValues, formState: { errors, isSubmitting }, setValue, control
  } = useForm(!id ? {
      defaultValues: {
        name: '',
        state:"",
        remark: '',
      }
    } :
      {
        defaultValues: {
          name: '',
          state:'',
          remark: '',
        }
      });
    
  const getCityFormById = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_CITY_BY_ID}/${id}`
    );

    setValue('name', data?.data[0].name);
    setValue('state', data?.data[0].state);  
    setValue('remark', data?.data[0].remark);
    setLoading(false)
  }, [authAxios, id, setValue]);

  const getstatedata = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE?.GET_STATEALL}`
    );
    setstateData(data.data)
  },[authAxios,id]);

  useEffect(() => {
    getstatedata()
    if (id) {
      getCityFormById();
    }
  }, [getCityFormById, id]);

  const onSubmit = async (data) => {
    const newdata = { ... data, state_id: data?.state_id?.trim()}
    if (id) {
      const response = await authAxios.post(
        `${PRIVATE_ROUTE?.EDIT_CITY}${id}`,
        newdata
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/master/city/list');
        }
        else {
          toast.error(response.data.massage);
          setLoading(false);
        }
    
    } else {
    
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_CITY, newdata);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/master/city/list');
      }
      else {
        toast.error(response.data.massage);
      }
    }
    setValue('name', '');
    setValue('state','')
    setValue('remark', '');
  };
  return (
    <>
    { id ? 
      loading && (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}

        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  
:
""
}
    <CityCreateForm
      register={register}
      setValue={setValue}
      stateData={stateData}
      getValues={getValues}
      handleSubmit={handleSubmit}
      errors={errors}
      onSubmit={onSubmit}
      params={id}
      loading={isSubmitting}
      isEdit={id?"true":"false"}
      control={control}
    />
    </>
  );
  }

export default CityForm;
