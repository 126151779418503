
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import ManagersideTaskCreateForm from './ManagersideTaskCreateForm';
import { Backdrop, CircularProgress } from '@mui/material';
import { AuthContext } from "src/context/AuthContext";

const { PRIVATE_ROUTE } = API_ROUTES;

function ManagersideTaskForm() {
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const [multivaluePM, setmultivaluePM] = React.useState([]);
  const [multivalueSM, setmultivalueSM] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState({});
  const [editData, setEditData] = useState({});
  const [attachments, setAttachments] = React.useState('');
  const [quotealldata, setquotealldata] = React.useState([]);
  const loggedInUserData = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    control,
  } = useForm(
    !id
      ? {
        selectClient: '',
        selectClient_id: '',
        selectQuote_id: '',
        selectJob_id: '',
        selectRatesheet_id: '',
        clientLocation_id: '',
        // staffMembers: '',
        Status_id: '',
        Office_id: '',
        selectQuote: '',
        selectJob: '',
        selectRatesheet: '',
        clientLocation: '',
        Status: '',
        Office: '',
        remark: '',
        labourItem: [],
        additionalLabourItem: [],
        materialItem: [],
        additionalEquipmentItem: [],
        fixItem: [],
      }
      : {
        selectClient: '',
        selectClient_id: '',
        // staffMembers: '',
        selectQuote_id: '',
        selectJob_id: '',
        selectRatesheet_id: '',
        clientLocation_id: '',
        Status_id: '',
        Office_id: '',
        selectQuote: '',
        selectJob: '',
        selectRatesheet: '',
        clientLocation: '',
        Status: '',
        Office: '',
        remark: '',
        labourItem: [],
        additionalLabourItem: [],
        materialItem: [],
        additionalEquipmentItem: [],
        fixItem: [],
      }
  );


  const getJobFormById = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_TASK_BY_ID}/${id}`);

    setEditData(data.data[0]);
    setValue('selectClient', data?.data[0]?.client_id?.company_name);
    setValue('selectClient_id', data?.data[0]?.client_id?._id);
      setValue('clientLocation', data?.data[0]?.client_locations[0]?.name);
      setValue('clientLocation_id', data?.data[0]?.client_locations[0]?._id);
    setValue('companyName', data?.data[0]?.company_name);
    setValue('selectJob', data?.data[0]?.job_id?.number_str);
    setValue('selectJob_id', data?.data[0]?.job_id._id);
    setValue('selectQuote', data?.data[0]?.quote_id?.name);
    setValue('selectQuote_id', data?.data[0]?.quote_id?._id);
    // setValue('staffMembers', data?.data[0]?.staff_members);
    setValue('ProjectManager', data?.data[0]?.project_manager);
    setValue('selectRatesheet', data?.data[0].ratesheet_id.name);
    setValue('selectRatesheet_id', data?.data[0].ratesheet_id._id);
    setValue('TaskCategory', data?.data[0].task_category_id?.name);
    setValue('TaskCategory_id', data?.data[0].task_category_id?._id);
    setValue('TaskScope', data?.data[0].task_scope_id?.name);
    setValue('TaskScope_id', data?.data[0].task_scope_id?._id);
    setValue('Office', data?.data[0].office_id?.name);
    setValue('Office_id', data?.data[0].office_id?._id);
    setValue('total_cost_hour', data?.data[0].total_cost_hour);
    setValue('total_estimated_hour', data?.data[0]?.total_estimated_hour);
    setValue('glCode', data?.data[0].gl_code_prefix);
    setValue('name', data?.data[0].name);
    setValue('description', data?.data[0].description);
    setValue('estimateHour', data?.data[0].estimate_hour);
    setValue('Status', data?.data[0].status_id?.name);
    setValue('Status_id', data?.data[0].statu_id?._id);
    data?.data[0].billing_line_items.labour_item.labour_cost_items.map((item, i) => {
      setValue(`labourItem.${i}.costItem`, item.costItem)
      setValue(`labourItem.${i}.name`, item.name)
      setValue(`labourItem.${i}.unit`, item.unit)
      setValue(`labourItem.${i}.unitCost`, item.unitCost)
      setValue(`labourItem.${i}.hourlyCost`, item.hourlyCost)
      setValue(`labourItem.${i}.estimated_hour`, item.estimated_hour)
      setValue(`labourItem.${i}.text_exemptLabour`, item.text_exemptLabour)
    })
    data?.data[0].billing_line_items.labour_item.additional_fields.map((item, i) => {
      setValue(`additionalLabourItem.${i}.field_name_labour`, item.field_name_labour)
      setValue(`additionalLabourItem.${i}.field_value_labour`, item.field_value_labour)
    })
    data?.data[0].billing_line_items.equipment_item.equipment_cost_items.map((item, i) => {
      setValue(`materialItem.${i}.costItem`, item.costItem)
      setValue(`materialItem.${i}.name`, item.name)
      setValue(`materialItem.${i}.unit`, item.unit)
      setValue(`materialItem.${i}.unitCost`, item.unitCost)

      setValue(`materialItem.${i}.text_exemptLabour`, item.text_exemptLabour)
    })
    data?.data[0].billing_line_items.equipment_item.additional_fields.map((item, i) => {
      setValue(`additionalEquipmentItem.${i}.field_name_equipment`, item.field_name_equipment)
      setValue(`additionalEquipmentItem.${i}.field_value_equipment`, item.field_value_equipment)
    })

    data?.data[0].billing_line_items.fixed_item.map((item, i) => {
      setValue(`fixItem.${i}.costItem`, item.costItem)
      setValue(`fixItem.${i}.name`, item.name)
      setValue(`fixItem.${i}.unit`, item.unit)
      setValue(`fixItem.${i}.unitCost`, item.unitCost)

      setValue(`fixItem.${i}.text_exemptLabour`, item.text_exemptLabour)
    })
    setValue('active', data?.data[0].active);
    setValue('remark', data?.data[0].remark);
    setValue('attachments', data?.data[0].attachments);
    
    setmultivalueSM(data?.data[0]?.staff_members);
    setAttachments(data?.data[0]?.attachments);
  }, [authAxios, id]);


  const getReleventData = useCallback(async () => {
    const taskInitialData = await authAxios.get(`${PRIVATE_ROUTE.GET_TASK_INITAL_DATA}`);

    setInitialData({
      client: taskInitialData?.data?.data?.client?.filter((item) => item?.active === true).map((iteam) => ({ label: iteam.company_name, id: iteam._id })),
      quote: taskInitialData.data.data.quote.map((item) => ({ label: item.name, id: item._id })),
      filterquote: taskInitialData.data.data.quote.filter((item) => item?.is_converted === 0).map((item) => ({ label: item.name, id: item._id })),
      ratesheet: taskInitialData.data.data.ratesheet.map((item) => ({ label: item.name, id: item._id })),
      job: taskInitialData?.data?.data?.job,
      manager: taskInitialData.data.data.manager.map((item) => `${item.first_name} ${item.last_name}`),
      staff: taskInitialData.data.data.staff.map((item) => ({ label: `${item.first_name} ${item.last_name}`, id: item._id })),
      scope: taskInitialData.data.data.scope.map((item) => ({ label: item.name, id: item._id })),
      category: taskInitialData.data.data.category.map((item) => ({ label: item.name, id: item._id })),
      office: taskInitialData.data.data.office.map((item) => ({ label: item.name, id: item._id })),
      status: taskInitialData.data.data.status.map((item) => ({ label: item.name, id: item._id })),
      labourCostItem: taskInitialData.data.data.labourCostItem.map((item) => ({ label: item.name, id: item._id })),
      materialCostItem: taskInitialData.data.data.materialCostItem.map((item) => ({ label: item.name, id: item._id })),
      fixedCostItem: taskInitialData.data.data.fixedCostItem.map((item) => ({ label: item.name, id: item._id })),
      jobinitial: taskInitialData?.data?.data?.job,
      quoteinitial: taskInitialData?.data?.data?.quote
    });
    setquotealldata(taskInitialData?.data?.data?.quote)
    if (id) {
      getJobFormById();
    }
    setLoading(false);
  }, [authAxios]);

  useEffect(() => {
    getReleventData();
  }, [getReleventData, id]);

  const onSubmit = async (data) => {
    if (id) {
      const response = await authAxios.post(`${PRIVATE_ROUTE?.EDIT_TASK}${id}`, data);
      toast.success(response.data.massage);
      navigate('/manager/task/list');
    } else {
      data.ProjectManager = [
        loggedInUserData?.state?.user?._id
      ];
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_TASK, data);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/manager/task/list');
      } else {
        toast.error(response.data.massage);
      }
    }
    setValue('selectClient', '');
    setValue('selectJob', '');
    setValue('selectQuote', '');
    setValue('selectRatesheet', '');
    setValue('Status', '');
    setValue('glCode', '');
    setValue('estimateHour', '');
    setValue('TaskCategory', '');
    setValue('TaskScope', '');
    setValue('remark', '');
    setValue('name', '');
    setValue('description', '');
    // setValue('staffMebers', '');
    setValue('clientLocation', '');
    setValue('materialItem', '');
    setValue('Office', '');


    data?.labourItem?.map((element, i) => {
      setValue(`labourItem.${i}.costItem`, '');
      setValue(`labourItem.${i}.tax_percentage`, '');
      setValue(`labourItem.${i}.costItem`, "")
      setValue(`labourItem.${i}.name`, "")
      setValue(`labourItem.${i}.unit`, "")
      setValue(`labourItem.${i}.unitCost`, "")
      setValue(`labourItem.${i}.hourlyCost`, "")
      setValue(`labourItem.${i}.estimated_hour`, "")
      setValue(`labourItem.${i}.text_exemptLabour`, "")
    });

  };
  return (
    <>
      {loading ? (
        <div>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        ''
      )}
      <ManagersideTaskCreateForm

        initialData={initialData}
        getValues={getValues}
        watch={watch}
        setValue={setValue}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        params={id}
        loading={isSubmitting}
        isEdit={id ? 'true' : 'false'}
        control={control}
        editData={editData}
        multivaluePM={multivaluePM}
        setmultivaluePM={setmultivaluePM}
        multivalueSM={multivalueSM}
        setmultivalueSM={setmultivalueSM}
        attachments={attachments}
        setAttachments={setAttachments}
        quotealldata={quotealldata}
      />
    </>
  );
}

export default ManagersideTaskForm;
