import React, { useContext, useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import { useNavigate } from 'react-router-dom';
import { logoutStorage } from 'src/utils/isAuthenticated';
import { LOGOUT } from 'src/context/constatnts';
import toast from 'react-hot-toast';
import { AuthContext } from 'src/context/AuthContext';
import account from '../../../components/_mock/account';


export default function AccountPopover() {
  const { state, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(null);
  const [items, setItems] = React.useState([]);
  const navigate = useNavigate()

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('LAND-FRONTED'));
    if (items) {
     setItems(items);
    }
  }, []);
  const handleClose = () => {
    setOpen(null);
  };
  const logout = () => {
    logoutStorage();
    dispatch({
      type: LOGOUT,
    });
    toast.success('Logged out successfully');
    navigate('/login', { replace: true });
    setOpen(null);
  };
  const profile = () => {
    navigate('/profile/edit', { replace: true });
    setOpen(null);
  };
  const settings = () => {
    navigate('/settings/edit', { replace: true });
    setOpen(null);
  };
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {state?.user?.first_name}  {state?.user?.last_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {state?.user?.email}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={profile} sx={{ m: 1 }}>
          Profile
        </MenuItem>
        <MenuItem onClick={settings} sx={{ m: 1 }}>
          Settings
        </MenuItem>
        <MenuItem onClick={logout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
