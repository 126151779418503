// Title: Extent of metering of water connections

import React, { useCallback, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import UserCreateForm from './UserCreateForm';
import { Backdrop, CircularProgress } from '@mui/material';
import { useState } from 'react';

const { PRIVATE_ROUTE } = API_ROUTES;
function UserForm() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate(); 
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    control
  } = useForm( !id ? {
    defaultValues: {
      role :'',
      role_id:""
    }
  } :
    {
      defaultValues: {
        role: "",
        role_id:""
      }
    });
  const getUserFormById = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_USER_BY_ID}${id}`
    );

    setValue('first_name', data?.data.first_name);
    setValue('last_name', data?.data.last_name);
    setValue('email', data?.data.email);
    setValue('role', data?.data?.role_id);
    setValue('role_id',data?.data?.role.charAt(0).toUpperCase() + data?.data?.role.slice(1));
    setValue('remark', data?.data.remark);
    setLoading(false)
  }, [authAxios, id, setValue]);


  useEffect(() => {
    if (id) {
      getUserFormById();
    }
  }, [getUserFormById, id]);


  const onSubmit = async (data) => {
    let newdata = { ... data, role: data?.role?.trim()}

    if (id) {
      const response = await authAxios.post(`${PRIVATE_ROUTE?.EDIT_USER}${id}`,newdata);
        toast.success(response.data.massage);
        navigate('/user/list');
      } else {
    
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_USER, newdata); 
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/user/list');
      }
      else {
        toast.error(response.data.massage);
      }
    }
    setValue('first_name', '');
    setValue('last_name', '');
    setValue('email', '');
    setValue('role_id', '');
    setValue('remark', '');
  };
  return (
    <>
    { id ? 
      loading && (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}

        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  
:
""
}

    <UserCreateForm
      register={register}
      setValue={setValue}
      getValues={getValues}
      handleSubmit={handleSubmit}
      errors={errors}
      onSubmit={onSubmit}
      params={id}
      loading={isSubmitting}
      isEdit={id ? "true" : "false"}
      control={control}
    />
    </>
  );
}
export default UserForm;
