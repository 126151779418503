// Title: Extent of metering of water connections

import React, { useCallback, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import OfficeCreateForm from './OfficeCreateForm';

const { PRIVATE_ROUTE } = API_ROUTES;

function OfficeForm() {
  const {id} = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setValue,
    control
  } = useForm({
    defaultValues: {
      name: '',
      remark: '',
    }});

  const getOfficeFormById = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_OFFICE_BY_ID}/${id}`
    );
    setValue('name', data?.data.name);
    setValue('remark', data?.data.remark);
  }, [authAxios, id, setValue]);

  useEffect(() => {
    if (id) {
      getOfficeFormById();
    }
  }, [getOfficeFormById, id]);

  const onSubmit = async (data) => {
    if (id) {
      const response = await authAxios.post(
        `${PRIVATE_ROUTE?.EDIT_OFFICE}${id}`,
        data
      );
      toast.success(response.data.massage);
      navigate('/master/office/list');
    } else {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_OFFICE, data);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/master/office/list');
      }
      else {
        toast.error(response.data.massage);
      }
    }
    setValue('name', '');
    setValue('remark', '');
  };
  return (
    <OfficeCreateForm
      register={register}
      setValue={setValue}
      getValues={getValues}
      handleSubmit={handleSubmit}
      errors={errors}
      onSubmit={onSubmit}
      params={id}
      loading={isSubmitting}
      isEdit={id?"true":"false"}
      control={control}
    />
  );
}
export default OfficeForm;
