import React, { useState, useEffect } from 'react';
import { Button, FormLabel, Grid, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';
// ----------------------------------------------------------------------
export default function UserCreateForm({
  register,
  setValue,
  getValues,
  errors,
  isEdit,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control,
}) {

  const navigate = useNavigate();
  const optionData = [
    {
      label: 'Manager',
      id: 'manager',
    },
    {
      label: 'Employee',
      id: 'employee',
    },
  ];

  const [textAreaCount, settextAreaCount] = React.useState(0);
 


  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} padding={3}>
        {params ? (
          <>
            <Typography variant="h4" gutterBottom mb={2}>
              Edit User
            </Typography>{' '}
          </>
        ) : (
          <>
            <Typography variant="h4" gutterBottom mb={2}>
              Create User
            </Typography>{' '}
          </>
        )}
        <Grid>
          <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
            <Grid item xs={12} md={6}>
     
                <FormLabel><>First Name <span style={{ color: "red" }} >*</span></></FormLabel>  
              <TextField
                fullWidth
                type="text"
                {...register('first_name', {
                  required: '**Field is required and needs to be a text',
                })}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name?.message}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel>
                <>Last Name </>
              </FormLabel>
              <TextField
                fullWidth
                type="text"
                {...register('last_name')}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name?.message}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid xs={12} item md={6}>
            <FormLabel><>Email <span style={{ color: "red" }} >*</span></></FormLabel> 
              <TextField
                fullWidth
                type="text"
                {...register('email', {
                  required: '**Field is required and needs to be a text',
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid xs={12} item md={6}>
              <FormLabel>
                <>Role </>
              </FormLabel>
              <ChipDropdown
                sx={{ width: '50%' }}
                getValues={getValues}
                state={'role_id'}
                secondstate={'role'}
                setValue={setValue}
                data={optionData?.map((item) => { return { 'label': item.label, 'id': item.id } })}
                validation={false}
                errors={null}
              />
            </Grid>
            <Grid xs={12} item md={6}>
              <FormLabel>
                <>Remarks </>
              </FormLabel>
              <TextField
                multiline
                rows={3}
                {...register('remark')}
                fullWidth
                onChange={(e, val) => {
                  settextAreaCount(e.target.value.length);
                  setValue('remark', e.target.value);
                }}
                inputProps={{ maxLength: 300 }}
                InputLabelProps={{ shrink: true }}
              />
              <Grid mt={1} sx={{ fontSize: '12px', textAlign: 'end' }} xs={12}>
                {' '}
                {textAreaCount} / 300
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: 'flex' }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            style={{ width: 300 }}
          >
            Submit
          </LoadingButton>
          {params && (
            <Button
              style={{ width: 300, marginLeft: '20px' }}
              variant="contained"
              size="large"
              color="error"
              fullWidth
              onClick={() => {
                navigate('/user/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form>
  );
}

UserCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func,
};
