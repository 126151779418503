import React, { useEffect, useRef } from 'react'
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Grid, Typography, Box, TextField, Stack, Button, Divider, FormGroup, FormControlLabel, Checkbox, ToggleButtonGroup, ToggleButton, Tabs, Tab, Card, FormLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add'
import PropTypes from 'prop-types';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useNavigate } from 'react-router-dom/dist';
import { Controller, useFieldArray } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import { useState } from 'react';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';


export default function JobCreateForm({
  register,
  errors,
  getValues,
  setValue,
  isEdit,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control,
  taxData,
  feeData,
  jobstatusData,
  editdata,
  initialData
}) {
  const navigate = useNavigate()
  const [dueDate, setDueDate] = useState();
  const [orderDate, setOrderDate] = useState();
  const [selected, setSelected] = React.useState("Detailed");
  const [taxes, settaxes] = React.useState("TicketInvoice");
  const [value, setValueTab] = React.useState(0);
  const [checkbox, setcheckbox] = useState();
  let [textAreaCount, settextAreaCount] = React.useState(0);


  const { fields: taxItem, remove: removeTax, insert: insertTax } = useFieldArray({
    control,
    name: "taxItem",
  });
  const { fields: feesItem, remove: removeFees, insert: insertFees } = useFieldArray({
    control,
    name: "feesItem",
  });

  const taxdata = taxData?.map((item) => { return { 'label': item.name, 'id': item._id } })
  const feedata = feeData?.map((item) => { return { 'label': item.name, 'id': item._id } })
  const editorderdate = getValues('orderDate')
  const editduedate = getValues('dueDate')
  const editinvoiceline = getValues('invoiceLineItemType')
  const edittaxesname = getValues('taxesName')
  const editcheckbox = getValues('active')

  useEffect(() => {
    if (params) {
      setOrderDate(editorderdate || null);
      setDueDate(editduedate || null);
      setSelected(editinvoiceline || null);
      settaxes(edittaxesname || null);
      setcheckbox(editcheckbox || null);
    }
  }, [editorderdate, editduedate, editinvoiceline, edittaxesname, editcheckbox]);


  React.useEffect(() => {
    if (params && editdata?.tax?.length !== 0) {
      editdata?.tax?.map((element, i) => {
        handleTaxesButton();
        handleTaxesDelete(taxItem?.length);
      });
    }
    if (params && editdata?.fee?.length !== 0) {
      editdata?.fee?.map((element, i) => {
        handleFeesButton();
        handleFeesDelete(feesItem?.length);
      });
    }
  }, [editdata]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleFeesDelete = (index) => {
    removeFees(index, 1);
  }
  const handleTaxesDelete = (index) => {
    removeTax(index, 1);
  }
  const handleTaxesButton = () => {
    insertTax(taxItem.length, {
      tax_percentage: "",
      taxesName: ""
    })
  }
  const handleFeesButton = () => {
    insertFees(feesItem.length, {
      fees_percentage: "",
      FeesName: ""
    })
  }
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)} >

      <Stack spacing={3} padding={3}>
        {
          params ?
            <> <Typography variant="h4" gutterBottom component="h2">
              Edit job
            </Typography> </> :
            <> <Typography variant="h4" gutterBottom component="h2">
              Create job
            </Typography> </>
        }
        <Grid>
          <>
            <Grid container direction={"row"} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
              <Grid md={6} item xs={12}>
                <FormLabel><>Project<span style={{ color: "red" }} >*</span></></FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  name='client_project'
                  {...register('client_project', {
                    required: '*Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.client_project)}
                  helperText={errors.client_project?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid md={6} item xs={12}>
                <FormLabel><>PO<span style={{ color: "red" }} >*</span></></FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  name='PO'

                  min={0}
                  {...register('PO', {
                    required: '*Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.PO)}
                  helperText={errors.PO?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
              <Grid md={6} item xs={12}>
                <FormLabel><>PO Amount<span style={{ color: "red" }} >*</span></></FormLabel>
                <TextField
                  fullWidth
                  step="0.01"
                  type="number"
                  name='po_amount'
                  min={0}
                  {...register('po_amount', {
                    required: '*Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.po_amount)}
                  helperText={errors.po_amount?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 40 }}
                />
             
              
              </Grid>
              <Grid md={6} item xs={12}>
                <FormLabel><>Job Status<span style={{ color: "red" }} >*</span></></FormLabel>
                <ChipDropdown 
                fullWidth 
                state={'Status'} 
                secondstate={'Status_id'}
                setValue={setValue} 
                getValues={getValues} 
                checked={"checked"} 
                data={jobstatusData?.map((item) => { return { label: item?.name, id: item._id } })} 
                register={register} 
                validation={true} 
                errors={errors}
                />
              </Grid>
              <Grid md={6} item xs={12}>
                <FormLabel><>Select Client<span style={{ color: "red" }} >*</span></></FormLabel>
                <ChipDropdown
                  getValues={getValues}
                  state={'selectClient'}
                  secondstate={'selectClient_id'}
                  setValue={setValue}
                  data={initialData?.client}
                  register={register}
                  validation={true}
                  errors={errors}
                  checked={'checked'}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormLabel><>Sub Contract<span style={{ color: "red" }} >*</span></></FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  name='subcontract'
                  {...register('subcontract', {
                    required: '*Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.subcontract)}
                  helperText={errors.subcontract?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid md={6} item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormLabel><>Order Date</></FormLabel>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => {
                      setValue('orderDate', newValue);
                      setOrderDate(newValue)

                    }}
                    value={orderDate}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid md={6} item xs={12}>

                <Grid>
                  <FormLabel><>Remarks</></FormLabel>
                </Grid>
                <TextField
                  multiline
                  rows={3}
                  fullWidth
                  {...register(`remark`)}
                  onChange={(e, val) => {
                    settextAreaCount(e.target.value.length)
                  }}
                  inputProps={{ maxLength: 300 }}
                  InputLabelProps={{ shrink: true }}
                />
                <Grid item sx={{ fontSize: "12px", textAlign: "end" }} xs={12}> {textAreaCount} / 300</Grid>

              </Grid>

              <Grid md={6} item xs={12}  >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormLabel><>Due Date</></FormLabel>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => {
                      setValue('dueDate', newValue);
                      setDueDate(newValue)
                    }}
                    value={dueDate}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid md={6} item xs={12} >
                <Typography variant='h5' component='h6' sx={{ margintop: '0px' }}>
                  Invoice Line Item Type<span style={{ color: "red" }} >*</span>
                </Typography>
                <Grid sx={{ display: 'flex' }}>
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={selected}
                    aria-label="Platform"
                    // {...register("invoiceLineItemType", selected)}
                    onChange={(e, val) => {
                      setValue('invoiceLineItemType', e.target.value)
                      setSelected(e.target.value);
                    }}
                  >
                    <ToggleButton value="Detailed">Detailed</ToggleButton>
                    <ToggleButton value="Semmerized">Semmerized</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid md={6} item xs={12} >
                <Typography variant='h5' component='h6'>Taxes Name <span style={{ color: "red" }} >*</span></Typography>

                <Grid sx={{ display: 'flex' }}>
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={taxes}
                    aria-label="Platform"
                    // {...register("taxesName", taxes)}
                    onChange={(e, val) => {
                      setValue('taxesName', e.target.value)
                      settaxes(e.target.value)
                    }}

                  >
                    <ToggleButton value="TicketInvoice">Ticket / Invoice</ToggleButton>
                    <ToggleButton value="Invoice">Invoice</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid >
              <Card sx={{ marginBlock: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Taxes" {...a11yProps(0)} />
                    <Tab label="Fees" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <Grid mt={0}>
                    <Grid>
                      <Typography variant='h5'> Taxes</Typography>
                    </Grid>
                    {
                      taxItem.map((element, index) => (
                        <>
                          <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                            <Grid sx={{ marginRight: '15px' }}>
                              <HighlightOffIcon variant="contained" onClick={() => handleTaxesDelete(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                {""}
                              </HighlightOffIcon>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid sx={{ marginLeft: '15px' }}>
                              <Grid container direction={"row"} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                <Grid md={6} item xs={12} sx={{ width: '60vw' }}>
                                  <FormLabel>
                                    <>taxes Name<span style={{ color: "red" }} >*</span></>
                                  </FormLabel>
                                  <ChipDropdown state={`taxItem.${index}.taxesName`} setValue={setValue} getValues={getValues} data={taxdata} startIcon={<FmdGoodIcon />}
                                    register={register} validation={true} errors={errors} />
                                </Grid>
                                <Grid md={6} item xs={12}>
                                  <FormLabel><>Tax (Percentage)<span style={{ color: "red" }} >*</span></></FormLabel>
                                  <TextField

                                    fullWidth
                                    onInput={(e) => {
                                      e.target.value = e.target.value <= 100 ? e.target.value.toString()?.slice(0, 3) : ''
                                    }}
                                    min={0}
                                    type="number"
                                    name='tax_percentage'
                                    {...register(`taxItem.${index}.tax_percentage`, {
                                      required: '*Field is required and needs to be a text',
                                    })}
                                    error={Boolean(errors.taxItem ? errors.taxItem[index].tax_percentage : null)}
                                    helperText={Boolean(errors.taxItem ? errors.taxItem[index].tax_percentage : false) ? '*Field is required and needs to be a text' : null}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                              </Grid>
                              <Divider sx={{ marginTop: '15px' }} />
                            </Grid>
                          </Grid>

                        </>
                      ))
                    }
                    <Grid>
                      <Button variant="contained" sx={{ marginTop: '20px' }} onClick={handleTaxesButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Tax</Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid>
                    <Grid mt={0}>
                      <Typography variant='h5'> Fees</Typography>
                    </Grid>
                    {
                      feesItem.map((element, index) => (
                        <>
                          <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                            <Grid sx={{ marginRight: '15px' }}>
                              <HighlightOffIcon variant="contained" onClick={() => handleFeesDelete(index)} sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }} startIcon={<Iconify icon="eva:plus-fill" />}>
                                {""}
                              </HighlightOffIcon>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid sx={{ marginLeft: '15px' }}>
                              <Grid container rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                <Grid md={6} item xs={12} sx={{ width: '60vw' }}>
                                  <Grid>
                                    <FormLabel><>Fees Name<span style={{ color: "red" }} >*</span></></FormLabel>
                                  </Grid>
                                  <ChipDropdown state={`feesItem.${index}.FeesName`} setValue={setValue} getValues={getValues} data={feedata} startIcon={<FmdGoodIcon />}
                                    register={register} validation={true} errors={errors} />
                                </Grid>
                                <Grid md={6} item xs={12} >
                                  <Grid>
                                    <FormLabel><>Fees (Percentage)<span style={{ color: "red" }} >*</span></></FormLabel>
                                  </Grid>
                                  <TextField
                                    fullWidth
                                    onInput={(e) => {
                                      e.target.value = e.target.value <= 100 ? e.target.value.toString()?.slice(0, 3) : ''
                                    }}
                                    min={0}
                                    type="number"
                                    name='fees_percentage'
                                    {...register(`feesItem.${index}.fees_percentage`, {
                                      required: '*Field is required and needs to be a text',
                                    })}
                                    InputLabelProps={{ shrink: true }}
                                    error={Boolean(errors.feesItem ? errors.feesItem[index].fees_percentage : null)}
                                    helperText={Boolean(errors.feesItem ? errors.feesItem[index].fees_percentage : false) ? '*Field is required and needs to be a text' : null}
                                  />
                                </Grid>
                              </Grid>
                              <Divider sx={{ marginTop: '15px' }} />
                            </Grid>

                          </Grid>

                        </>
                      ))
                    }
                    <Grid>
                      <Button variant="contained" sx={{ marginTop: '20px' }} onClick={handleFeesButton} startIcon={<AddIcon sx={{ fontSize: '42px' }} />}>Add Fees</Button>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Card>
            </Grid>
            <Grid mt={1}>
              <FormGroup>
                <FormControlLabel control={
                  <Checkbox
                    defaultChecked={params && getValues('active') ? true : false}
                    checked={getValues('active') ? true : false}
                    onChange={(e, val) => {
                      setValue('active', val);
                      setcheckbox(val);
                    }}
                  />}
                  label="Active" />
              </FormGroup>
            </Grid>
          </>
        </Grid>
        <Grid sx={{ display: "flex" }} >
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} style={{ width: 300 }}>
            Submit
          </LoadingButton>
          {params && (
            <Button
              style={{ width: 300, marginLeft: "20px" }}
              variant="contained"
              size="large"
              color="error"
              fullWidth
              onClick={() => {
                navigate('/job/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form >

  );
}

JobCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func
};
