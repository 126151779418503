import React from 'react'
import { Box, Button, Stack, Typography, Container, Card, Tabs, Tab, Grid, Divider, Table, TableHead, TableCell, TableBody, TableRow, TableContainer, Paper } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import MyMap from 'src/components/map/myMap';


const mapIsReadyCallback = (map) => {
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const StaffTaskView = () => {
  const ref = React.createRef();
  const { id } = useParams();
  const [valueOne, setValueOne] = React.useState(0);
  const [valueTwo, setValueTwo] = React.useState(0);

  const handleChangeone = (event, newValue) => {
    setValueOne(newValue);
  };
  const handleChangetwo = (event, newValue) => {
    setValueTwo(newValue);
  };
  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" component="h2">
          Task View
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <Button sx={{ width: "10vw", marginRight: "20px" }} variant="contained" color="primary" startIcon={<EditIcon />}>
            Edit
          </Button>
          <Button sx={{ width: "10vw", marginRight: "20px" }} variant="contained" color="primary" startIcon={<FileDownloadIcon />}>
            Export To PDF
          </Button>
          <Button sx={{ width: "10vw", marginRight: "20px" }} variant="contained" color="primary" startIcon={<DeleteIcon />}>
            Delete
          </Button>
          <Button sx={{ width: "10vw", marginRight: "20px" }} variant="contained" color="primary" startIcon={<FileCopyIcon />}>
            Clone
          </Button>
        </Box>
        <Box marginTop={'30px'}>
          <Grid container>
            <Grid sx={{ marginTop: "10px" }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Task No.:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={4} item>
              <Typography variant="body1" color="initial">
                {id}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Job No.:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={4} item>
              <Typography variant="body1" color="initial">
                {'000287'}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Client No.:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={4} item>
              <Typography variant="body1" color="initial">
                {'001411'}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Client Address:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={4} item>
              <Typography variant="body1" color="initial">
                {'9311 Mary Street,Chilliwack, British Columbia'}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Status:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={4} item>
              <Typography variant="body1" color="initial">
                {'Job Setup'}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Office:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={4} item>
              <Typography variant="body1" color="initial">
                {'Vancouver'}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                DWR Billing Type:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={4} item>
              <Typography variant="body1" color="initial">
                {'Time & Materials'}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Taks Category:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={4} item>
              <Typography variant="body1" color="initial">
                {'Energy'}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Task Scope:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={4} item>
              <Typography variant="body1" color="initial">
                {''}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Scope Details:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} xs={4} item>
              <Typography variant="body1" color="initial">
                {''}
              </Typography>
            </Grid>
          </Grid>
          <Typography mt={'30px'} variant="h6" color="initial">
            Additional Details
          </Typography>
          <Card style={{ marginTop: '30px' }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={valueOne} onChange={handleChangeone} aria-label="basic tabs example">
                  <Tab label="Locations" {...a11yProps(0)} />
                  <Tab label="Contacts" {...a11yProps(1)} />
                  <Tab label="Additional Fields" {...a11yProps(2)} />
                  {/* <Tab label="Taxes" {...a11yProps(0)} />
                  <Tab label="Fees" {...a11yProps(1)} /> */}
                </Tabs>
              </Box>
              <TabPanel value={valueOne} index={0}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Locations
                </Typography>
                {
                  [1, 2].map((item) => (
                    <>
                      <Grid sx={{ marginLeft: 6 }} container>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Location Name:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {'0839764 BC Ltd'}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Munciple Address:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {'6451 184 STREET,'}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            State / Province:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {'British Columbia'}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            City:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {'Surrey'}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Postal Code:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={10} item>
                          <Typography variant="body1" color="initial">
                            {'4FE7S55'}
                          </Typography>
                        </Grid>
                        {
                          item === 1 ?
                            <>
                              <Grid sx={{ marginTop: "10px" }} xs={2} item>
                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                  Map View:
                                </Typography>
                              </Grid>
                              <Grid sx={{ marginTop: "10px" }} xs={10} item>
                                <div style={{ marginRight: "50px" }}>
                                  <MyMap mapIsReadyCallback={mapIsReadyCallback} location={[-122.82802, 49.12608]} />
                                </div>
                              </Grid>
                            </>
                            :
                            null
                        }
                      </Grid>
                      {[1, 2].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                    </>
                  ))
                }
              </TabPanel>
              <TabPanel value={valueOne} index={1}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Contacts
                </Typography>
                {[1].map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Full Name:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'John Doe'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Position:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: "black", textDecoration: 'none' }} href={'https://elevatelandsurveying.com/'}>
                            {'Developer'}
                          </a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Depatrment:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'Developement'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Location:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: "black", textDecoration: 'none' }} href={'https://elevatelandsurveying.com/'}>
                            {'0839764 BC Ltd'}
                          </a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Email:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: "black", textDecoration: 'none' }} href={'mailto:developer@elevatelandsurveying.com'}>
                            {'developer@elevatelandsurveying.com'}
                          </a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Phone:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: "black", textDecoration: 'none' }} href={'tel:+14578545214'}>
                            {'+14578545214'}
                          </a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Cell:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: "black", textDecoration: 'none' }} href={''}>
                            {''}
                          </a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Notes:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {''}
                        </Typography>
                      </Grid>
                    </Grid>
                    {[1].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                  </>
                ))}
              </TabPanel>
              {/* <TabPanel value={valueOne} index={0}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Taxes
                </Typography>
                {
                  [1, 2, 3].map((item) => (
                    <>
                      <Grid sx={{ marginLeft: 6 }} container>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Tax Name:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {`Demo Tex ${item}`}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Tax (Percentage):
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {'5%'}
                          </Typography>
                        </Grid>
                      </Grid>
                      {[1, 2].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                    </>
                  ))
                }
              </TabPanel>
              <TabPanel value={valueOne} index={1}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Fees
                </Typography>
                {
                  [1, 2].map((item) => (
                    <>
                      <Grid sx={{ marginLeft: 6 }} container>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Fee Name:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {`Demo Fee ${item}`}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Fee (Percentage):
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {'3%'}
                          </Typography>
                        </Grid>
                      </Grid>
                      {[1, 2].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                    </>
                  ))
                }
              </TabPanel> */}
              <TabPanel value={valueOne} index={2}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Additional Fields
                </Typography>
                {
                  [1].map((item) => (
                    <>
                      <Grid sx={{ marginLeft: 6 }} container>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Additional Field Name:
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {`Demo Field ${item}`}
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={2} item>
                          <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                            Additional Field Value (Percentage):
                          </Typography>
                        </Grid>
                        <Grid sx={{ marginTop: "10px" }} xs={4} item>
                          <Typography variant="body1" color="initial">
                            {'1%'}
                          </Typography>
                        </Grid>
                      </Grid>
                      {[1].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                    </>
                  ))
                }
              </TabPanel>
            </Box>
          </Card>
          <Typography mt={'30px'} variant="h6" color="initial">
            Billable Line Items
          </Typography>
          <Card style={{ marginTop: '30px' }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={valueTwo} onChange={handleChangetwo} aria-label="basic tabs example">
                  <Tab label="Labour Costs" {...a11yProps(0)} />
                  <Tab label="Equipment and Materials" {...a11yProps(1)} />
                  <Tab label="Fixed Price" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={valueTwo} index={0}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Labour Costs
                </Typography>
                {[1, 2].map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Cost Item:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'1 Man Survey Crew'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Name:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'1 Man Survey Crew'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'HR'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit Cost:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'$15'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Budget Quantity:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'5'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Hourly Cost:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'$15'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={6} item>
                        <div style={{ display: 'flex' }}>
                          {true ? <CheckBoxOutlinedIcon sx={{ marginRight: 2 }} /> : <CheckBoxOutlineBlankOutlinedIcon sx={{ marginRight: 2 }} />}
                          <Typography mb={'5px'} variant="h6" color="initial">
                            Tax Exempt
                          </Typography>
                        </div>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={6} item>
                        <div style={{ display: 'flex' }}>
                          {false ? <CheckBoxOutlinedIcon sx={{ marginRight: 2 }} /> : <CheckBoxOutlineBlankOutlinedIcon sx={{ marginRight: 2 }} />}
                          <Typography mb={'5px'} variant="h6" color="initial">
                            Archived
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {[1, 2].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                  </>
                ))}
              </TabPanel>
              <TabPanel value={valueTwo} index={1}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Other Cost Line Items (Incidentals, Equipment, Materials)
                </Typography>
                {[1, 2].map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Cost Item:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'3D Laser Scanner'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Name:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'3D Laser Scanner'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'PER'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit Cost:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'$15'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Budget Quantity:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {'5'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={6} item>{''}
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={6} item>
                        <div style={{ display: 'flex' }}>
                          {true ? <CheckBoxOutlinedIcon sx={{ marginRight: 2 }} /> : <CheckBoxOutlineBlankOutlinedIcon sx={{ marginRight: 2 }} />}
                          <Typography mb={'5px'} variant="h6" color="initial">
                            Tax Exempt
                          </Typography>
                        </div>
                      </Grid>
                      <Grid sx={{ marginTop: "10px" }} xs={6} item>
                        <div style={{ display: 'flex' }}>
                          {false ? <CheckBoxOutlinedIcon sx={{ marginRight: 2 }} /> : <CheckBoxOutlineBlankOutlinedIcon sx={{ marginRight: 2 }} />}
                          <Typography mb={'5px'} variant="h6" color="initial">
                            Archived
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {[1, 2].length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                  </>
                ))}
              </TabPanel>
              <TabPanel value={valueTwo} index={2}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Fixed Price Line Items
                </Typography>
                <Grid container>
                  <Grid sx={{ marginTop: "10px" }} xs={2} item>
                    <Typography variant="subtitle1" color="initial">
                      No Item Selected
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Card>
          <div style={{ marginTop: "30px", display: 'flex' }}>
            {true ? <CheckBoxOutlinedIcon sx={{ marginInline: 2 }} /> : <CheckBoxOutlineBlankOutlinedIcon sx={{ marginInline: 2 }} />}
            <Typography mb={'5px'} variant="h6" color="initial">
              Active
            </Typography>
          </div>
        </Box>
      </Container>
    </>
  )
}

export default StaffTaskView;